import axios from "axios";
import api from "./api";

// const DEV_BASE_URL = "http://localhost:4000"
// const PROD_BASE_URL = "https://hrms-staging.ccdms.in/"

// let baseUrl;

// if (process.env.NODE_ENV === 'development') {
//   baseUrl = DEV_BASE_URL;
// } else {
//   baseUrl = PROD_BASE_URL;
// }

// const baseUrl = "https://hrms-staging.ccdms.in"
// const baseUrl = "http://localhost:4000"

export const loginFunction = (data) => {
  return api.post(`/login`, data);
};

export const signupFunction = (data) => {
  return api.post(`/register`, data);
};

export const applyFunction = (data) => {
  return api.post(`/leave/apply_leave`, data);
};
export const punchInFunction = (data) => {
  return api.post(`/attendance/check_in`, data);
};
export const punchOutFunction = (data) => {
  return api.put(`/attendance/check_out`, data);
};

export const attendanceInfoFunction = (queryString = "") => {
  return api.get(`/user/attendance${queryString}`);
};

export const leaveFunction = () => {
  return api.get(`/leave/list`);
};
export const balanceFunction = (body) => {
  return api.post(`/user/leave_balance`, body);
};
export const leaveBalanceFunction = (queryString = "") => {
  return api.get(`/user/leave_balance${queryString}`);
};
export const leaveHistoryFunction = (queryString = "") => {
  return api.get(`/leave/user${queryString}`);
};
export const userListFunction = (params) => {
  return api.get(`/user/list`, { params });
};

export const userforDepartmentHeadFunction = (params) => {
  return api.get(`/user/list_for_deparment_head`, { params });
};
export const getTodayUserAttendance = () => {
  return api.get(`/attendance/today`);
};

export const userUpdateFunction = (queryString = "", data) => {
  return api.put(`/user/${queryString}`, data);
};
export const createDepartmentFunction = (name) => {
  return api.post(`/department`, { name: name });
};
export const getDepartmentFuntion = () => {
  return api.get(`/department`);
};
export const deleteDepartmentFunction = (queryString = "") => {
  return api.delete(`/department/${queryString}`);
};
export const updateDepartmentFunction = (queryString = "", name) => {
  return api.put(`/department/${queryString}`, { name: name });
};
export const getDepartmentByIdFunction = (queryString = "") => {
  return api.get(`/department/${queryString}`);
};
export const createDesignationFunction = (data) => {
  return api.post(`/designation`, data);
};
export const getDesignationFunction = () => {
  return api.get(`/designation`);
};
export const updateDesignationFunction = (queryString = "", data) => {
  return api.put(`/designation/${queryString}`, data);
};
export const deleteDesignationFunction = (queryString = "") => {
  return api.delete(`/designation/${queryString}`);
};
export const getDesignationByIdFunction = (queryString = "") => {
  return api.get(`/designation/${queryString}`);
};
export const getDesignationByDepartmentFunction = (departmentId = "") => {
  return api.get(`/designation/by-department/${departmentId}`);
};
export const getPermissionsFunction = (is_list = false) => {
  let finalUrl = `/app-permission`;
  if (is_list) {
    finalUrl += "?is_list=true";
  }
  return api.get(finalUrl);
};
export const getNetworkFunction = () => {
  return api.get(`/organisation-network`);
};
export const createNetworkFunction = (data) => {
  return api.post(`/organisation-network`, data);
};
export const getNetworkStatusFunction = (queryString = "") => {
  return api.get(`/organisation-network/${queryString}`);
};
export const createNetworkStatusFunction = (data) => {
  return api.post(`/organisation-network/update`, data);
};
export const deleteNetworkFunction = (queryString = "") => {
  return api.delete(`/organisation-network/${queryString}`);
};
export const logOut = () => {
  const userData = JSON.parse(localStorage.user_data);
  const token = userData.activeToken;
  return api.post(`/logOut`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const forgetResendPassword = (data) => {
  return api.post(`/forgetPassword`, data);
};
export const setNewPasswordd = (data) => {
  return api.put(`/resetPassword`, data);
};
export const csvUpload = (data) => {
  return api.post(`/file/csv-file`, data);
};
export const getLastAttendance = () => {
  return api.get(`/attendance/getLastAttendance`);
};
export const emailVerify = (data) => {
  return api.post(`/organization/verify_otp `, data);
};
export const sendOtp = (data) => {
  return api.post(`/organization/send_otp `, data);
};
export const newOrganisation = (data) => {
  return api.post(`/organization/new_org `, data);
};
export const allOrganisation = (data) => {
  return api.get(`/organization_list/view_list`, data);
};
export const updateStatus = (data) => {
  return api.patch(`/organization_list/update_status`, data);
};

export const updatePasswordFunction = async (data) => {
  try {
    const response = await api.post(`/update-password`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getReportingTo = () => {
  return api.get(`/user/managers`);
};
export const fetchReporteefromManager = (data) => {
  return api.post(`/user/allReporteeManager`, data);
};
export const removeManagerId = (data) => {
  return api.post(`/user/nulluserManagerId`, data);
};
export const getHomeData = () => {
  return api.get(`/home/data`);
};
export const getAssignedManager = () => {
  return api.get(`/user/user_manager_list`);
};
export const getUserAttendance = (userId) => {
  let url = `/attendance/get_user_attendance`;
  if (userId) {
    url += "?userid=" + userId;
  }
  return api.get(url);
};

export const getUserAttendanceRegularize = () => {
  return api.get(`/attendance/list_to_apply_regularization`);
};
export const getUserAttendanceSummary = (userId) => {
  let url = `/attendance/get_attendance_summary`;
  if (userId) {
    url += "?userid=" + userId;
  }
  return api.get(url);
};

export const getUserAttendancePerformance = (userId) => {
  let url = `/attendance/get_performance_summary`;
  if (userId) {
    url += "?userid=" + userId;
  }
  return api.get(url);
};

export const getDepartments = () => {
  return api.get(`/department`);
};
export const getManagers = (params) => {
  return api.get(`/user/eligible_managers`, { params });
};
export const postManagers = (data) => {
  return api.post(`/user/change_manager`, data);
};

export const postAttendance = (data) => {
  return api.post(`/attendance/check_in`, data);
};

export const putAttendance = (data) => {
  return api.put(`/attendance/check_out`, data);
};
export const getReportees = (type = "current") => {
  return api.get(
    `/user/get_reportees?type=${type === "current" ? "active" : type}`
  );
};

export const getPendingRegularizationFunction = (params) => {
  return api.get(`/regularization/list_of_pending_regularization`, { params });
};

export const getRegularizationReasons = () => {
  return api.get(`/regularization/reason_list`);
};

export const postApprovalRegularizationFunction = (data) => {
  return api.post(`/regularization/approve`, data);
};
export const postRejectRegularizationFunction = (data) => {
  return api.post(`/regularization/reject`, data);
};

export const submitRegularization = (data) => {
  return api.post(`/regularization/apply`, data);
};
export const bulkApprove = (params) => {
  return api.post(`/regularization/bulk_approve`, {params});
};
export const loginSendOtp = (phoneNumber) => {
  return api.post(`/send_otp`, {phoneNumber: phoneNumber});
};
export const loginVerifyOtp = (data) => {
  return api.post(`/verify_otp`, data);
};
