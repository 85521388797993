import React from "react";
import { useNavigate } from "react-router-dom";

function ActionComp({ navigateTo, attText, atttextSub, icon, iconPerson }) {
  const navigate = useNavigate();
  return (
    <div
      className="att_cont_card"
      type="button"
      onClick={() => navigate(`/${navigateTo}`)}
    >
      <div className="AttCardContainer">
        <div className="AttCardContainerSub">
          <div className="AttLogText">{attText}</div>
          <div className="AttLogTextSub">{atttextSub}</div>
          <button
            className="AttActionBtn"
            onClick={() => navigate(`/${navigateTo}`)}
          >
            <img src={icon} />
          </button>
        </div>
        <div className="IconImageContt">
          <img src={iconPerson} className="AttPerson" />
        </div>
      </div>
    </div>
  );
}

export default ActionComp;
