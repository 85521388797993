import React, { useContext, useEffect } from 'react'
import PageHeader from '../PageHeader/PageHeader'
import SortByDropDown from "./SortByDropDown"
import ManagerFlowCards from './ManagerFlowCards'
import {ReactComponent as ReporteeCardImg} from "../../../assets/img/Ellipse 260.svg"
import  "./MangerAttendanceFlow/MangerAttendanceFlow.css"
import AttendanceDetail from '../../Pages/Attendance/shared/AttendanceDetail'
import AttendanceSummary from '../../Pages/Attendance/Attendancemobile/AttendancePieChart/AttendanceSummary'
import AttendanceReport from '../../Pages/Attendance/Attendancemobile/AttendancePieChart/AttendanceReport'
import MangerAttendanceFlow from "./MangerAttendanceFlow/MangerAttendanceFlow";
import { useParams } from 'react-router-dom'
import { getUserAttendance } from '../../Services/CommonServices'
import { AppContext } from '../../../AppContext'
import ManagerParticularUserDetail from './ManagerParticularUserDetail'


function ReporteeDetails() {



  return (
    <>
                  <PageHeader
        pageTitle="Manager"
        breadCrumbs={[
          {
            name: 'Home',
            path: "/"
          },
          {
            name: "Manager",
            path: "/user-details"
          },
          
        ]}
        btn={
            <div className="attendance-container">
              {/* <div className="attendance-btn" onClick={() => handleTabChange("History")}>Current Reportees</div> */}
              {/* <div className="attendance-btn" onClick={() => handleTabChange("User-History")}>Past Reportees</div> */}
              {/* <div className="attendance-btn" onClick={() => handleTabChange("Today-User-History")}>Regularisation</div> */}
            </div>
          }
       
      />
        {/* <MangerAttendanceFlow /> */}
        <ManagerParticularUserDetail/>
    </>

  )
}

export default ReporteeDetails
