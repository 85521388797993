import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import "./Designation.css";
import { ReactComponent as EditIcon } from "../../../assets/img/edit-icon.svg";
import { AppContext } from "../../../AppContext";

const DesignationTable = ({ designation, setUpdateData }) => {
  const { isDrawerOpen, setIsDrawerOpen } = useContext(AppContext);
  return (
    <div className="m-4">
      <div className="table-component-div">
        <Table className="align-items-center ">
          <thead className="thead-light">
            <tr>
              <th>S. no</th>
              <th>Designation Name</th>
              <th>Associated Users</th>
              <th>Department Name</th>
              <th>Department Head</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {designation.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.name}</td>
                <td></td>
                <td>{item?.department?.name}</td>
                <td></td>
                <td>{item?.name}</td>
                <td>
                <EditIcon
                      onClick={() => {
                        setUpdateData(item)
                        setIsDrawerOpen(true)
                      }}
                    />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DesignationTable;
