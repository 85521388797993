import Axios from 'axios';
import { useEffect } from 'react';

const axios = Axios.create({
    baseURL: "http://localhost:4000"
});

function ErrorHandler(props) {

    useEffect(() => {
        axios.interceptors.request.use(function (request) {
            // can do the work of adding the headers before making the request.
            return request;
        });

        axios.interceptors.response.use(function (response) {
            if (!response || ![200,201].includes(response.status)) {
                return console.log("error in response");
            }
            return response;
        })
    }, []);

    return props.children;
}

export default ErrorHandler;