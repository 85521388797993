import React from 'react'
import SortByDropDown from "./SortByDropDown"
import ManagerReporteeCard from './MangerReporteeCard'

function PastReportee() {
    return (
        <div>
            <SortByDropDown heading={"13 June, 2023 - 10 Jan, 2024"} />
            <div>
                <ManagerReporteeCard />
                <ManagerReporteeCard />
                <ManagerReporteeCard />
            </div>
        </div>
    )
}

export default PastReportee