import React, { useState } from 'react';
import { DatePicker } from 'antd';
import {ReactComponent as DateCalender } from '../../../../assets/img/CardImg/dateCalender.svg';
// import 'antd/dist/antd.css';
// import './styles.css';
import "./DatePicker.css"

const { RangePicker } = DatePicker;

const DatePickerAntd = () => {
  const [dates, setDates] = useState([]);

  return (
    <div  style={{ display: 'flex', alignItems: 'center', border: '1px solid #7B7B7B', borderRadius: '10px' }}>
      <RangePicker
        format="DD/MM/YY"
        value={dates}
        onChange={(dates) => setDates(dates)}
        placeholder={['DD/MM/YY', 'DD/MM/YY']}
      />
      <div>

      <DateCalender style={{ margin: '10px', color: '#9B5DE5' }} />
      </div>
    </div>
  );
};

export default DatePickerAntd;
