import React from "react"
import "./Feeds.css"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import EventCard from "./EventCard";
import { Container } from "reactstrap";


function Feeds() {
    return (
        <Container fluid >
            <Tabs
                defaultActiveKey="AllFeeds"
                id="fill-tab-example"
                className="mb-3"
            >
                <Tab eventKey="AllFeeds" title="All Feeds"  >
                    <DropdownButton id="dropdown-basic-button" title="Sort By" >
                        <Dropdown.Item href="#/action-1">Newest posts</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Most recent interacted</Dropdown.Item>
                    </DropdownButton>
                    <br />
                    <EventCard />
                </Tab>
                <Tab eventKey="Events" title="Events">
                    <DropdownButton id="dropdown-basic-button" title="Sort By" >
                        <Dropdown.Item href="#/action-1">Newest posts</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Most recent interacted</Dropdown.Item>
                    </DropdownButton>
                </Tab>
                <Tab eventKey="Everyone" title="Everyone">
                    <DropdownButton id="dropdown-basic-button" title="Sort By" >
                        <Dropdown.Item href="#/action-1">Newest posts</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Most recent interacted</Dropdown.Item>
                    </DropdownButton>
                </Tab>
            </Tabs>
        </Container>
    )
}

export default Feeds;