// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.network-label{
	cursor: pointer;
	text-indent: -9999px;
	width: 60px;
	height: 30px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.network-label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

/* input:checked + label {
	background: #bada55; */
/* } */

input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label:active:after {
	width: 130px;
}


`, "",{"version":3,"sources":["webpack://./src/components/Pages/Networks/Network.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,QAAQ;CACR,kBAAkB;AACnB;;AAEA;CACC,eAAe;CACf,oBAAoB;CACpB,WAAW;CACX,YAAY;CACZ,gBAAgB;CAChB,cAAc;CACd,oBAAoB;CACpB,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,QAAQ;CACR,SAAS;CACT,WAAW;CACX,YAAY;CACZ,gBAAgB;CAChB,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;uBACuB;AACvB,MAAM;;AAEN;CACC,sBAAsB;CACtB,4BAA4B;AAC7B;;AAEA;CACC,YAAY;AACb","sourcesContent":[".network-input[type=checkbox]{\n\theight: 0;\n\twidth: 0;\n\tvisibility: hidden;\n}\n\n.network-label{\n\tcursor: pointer;\n\ttext-indent: -9999px;\n\twidth: 60px;\n\theight: 30px;\n\tbackground: grey;\n\tdisplay: block;\n\tborder-radius: 100px;\n\tposition: relative;\n}\n\n.network-label:after {\n\tcontent: '';\n\tposition: absolute;\n\ttop: 5px;\n\tleft: 5px;\n\twidth: 20px;\n\theight: 20px;\n\tbackground: #fff;\n\tborder-radius: 90px;\n\ttransition: 0.3s;\n}\n\n/* input:checked + label {\n\tbackground: #bada55; */\n/* } */\n\ninput:checked + label:after {\n\tleft: calc(100% - 5px);\n\ttransform: translateX(-100%);\n}\n\nlabel:active:after {\n\twidth: 130px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
