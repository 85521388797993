import React, { useContext, useEffect, useState } from 'react'
import { allOrganisation,updateStatus } from "../../Services/CommonServices";
import { AppContext } from '../../../AppContext';

function ApprovedOrganisation() {
  const { organisations ,setUpdateTrigger} = useContext(AppContext);


  return (
<table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Organisation Name</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Status</th>
          
          {/* <th scope="col">Contact</th> */}
        </tr>
      </thead>
      <tbody>
        {organisations?.filter(organisation=>organisation?.status==="approved")?. map((organisation, index) => (
          <tr key={organisation.id}>
            <th scope="row">{index + 1}</th>
            <td>{organisation.org_name}</td>
            <td>{organisation.user_details?.name}</td>
            <td>{organisation?.user_details?.email}</td>
            <td>{organisation?.status}</td>
            {/* <td>
              <button className='btn btn-success' disabled={organisation?.status ==="approved"} onClick={()=>approveHandler(organisation?.org_id)} >Approve</button>
              <button className='btn btn-danger' disabled={organisation?.status ==="rejected"} onClick={()=>rejectHandlerHandler(organisation?.org_id)}>Reject</button>
            </td> */}
            
          </tr>
        ))}
      </tbody>
    </table>  )
}

export default ApprovedOrganisation