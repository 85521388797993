import React, { useEffect, useState } from 'react'
import SortByDropDown from "../../Shared/MangerFLow/SortByDropDown"
import ManagerFlowDetailsCard from './ManagerFlowDetailsCard'
import { useParams } from 'react-router-dom';
import { getUserAttendance, getUserAttendancePerformance, getUserAttendanceSummary } from '../../Services/CommonServices';
import AttendanceDetail from '../../Pages/Attendance/shared/AttendanceDetail';
import AttendanceSummary from '../../Pages/Attendance/Attendancemobile/AttendancePieChart/AttendanceSummary';
import AttendanceReport from '../../Pages/Attendance/Attendancemobile/AttendancePieChart/AttendanceReport';
import ManagerFlowCards from './ManagerFlowCards';

function ManagerParticularUserDetail() {
    const [attendaceList, setAttendanceList] = useState([])
    const [attendaceSummary, setAttendaceSummary] = useState(null);
    const [attendacePerformance, setAttendacePerformance] = useState(null);

    const params = useParams();

    useEffect(() => {
        if (params?.id) {
            getData(params.id);
        } else {
            getData();
        }
    }, [params]);
    const getData = (userId = null) => {
        getUserAttendance(userId).then(res => {
            setAttendanceList(res.data.data);
        }).catch(err => {
            console.log("error in fetching attendance", err);
        });
        getUserAttendanceSummary(userId).then(res => {
            setAttendaceSummary(res.data.data);
        }).catch(err => {
            console.log("error in fetching attendance summary", err);
        });

        getUserAttendancePerformance(userId).then(res => {
            setAttendacePerformance(res.data.data);
        }).catch(err => {
            console.log("error in fetching attendance summary", err);
        });

    }
    return (
        <div>
            <SortByDropDown heading={`Log from ${attendaceList?.user?.
logs_start_date} - ${attendaceList?.user?.logs_end_date}`} />
            {attendaceList?.user?.user_details && <ManagerFlowDetailsCard userDetails={true} item={attendaceList?.user?.user_details} />}

                <div className='row' style={{padding:"20px"}}>
                    <div className='col-md-4'>
                        {attendaceList?.user?.attendance?.map(attendanceData => {
                            return <AttendanceDetail attendanceData={attendanceData} />
                        })}
                    </div>
                    <div className='col-md-8'>
                    <ManagerFlowCards attendaceSummary={attendaceSummary} userData={attendaceList?.user} />
                        <div className='d-flex'>

                    <div className='col-md-6'>
                        {attendaceSummary && <AttendanceSummary attendaceSummary={attendaceSummary} />}
                    </div>
                    <div className='col-md-6'>
                        {attendacePerformance && <AttendanceReport attendacePerformance={attendacePerformance} />}
                    </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default ManagerParticularUserDetail