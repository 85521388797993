import React, {useEffect, useState} from 'react'
import { HOME_CARD_DATA } from "../CardData/CardData"
import ReporteCard from '../ReporteCard/ReporteCard';
import { ReactComponent as RightArrow } from "../../../assets/img/CardImg/ArrowRight.svg"
import {getReportees, getReportingTo} from "../../Services/CommonServices";
import {useNavigate} from "react-router-dom";


function HomeBigCard({admin}) {

    const[reportees,setReportees] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!admin) {
            getReportees().then(res => {
                setReportees(res?.data?.data);
            }).catch(err => {
                console.log("error while getting reportees");
            })
        }
    }, [admin]);

    const handleNavigate = () => {
        if (!admin) {
            navigate("/user-details");
        }
    }

  return (
    <div className="col-md-6 " style={{padding:"0px 18px"}}>
      <div className='h-100 bg-white card-radius '>
     <div className='d-flex justify-content-between align-items-center ' style={{paddingRight:"26px"}}>
     <p className="review card-padding">{admin ? 'Things To Do' : 'Reportees'}</p>
      <RightArrow onClick={() => handleNavigate()} style={{cursor: "pointer"}} />
     </div>
      <div className='card-padding overflow-container'>
          {reportees.map(item => {
              return <ReporteCard data={item} />
          })}
      </div>
      </div>
    </div>)
}

export default HomeBigCard;
