import React from 'react'
import { Container } from 'reactstrap';

function LeavePending() {
  return (
    <Container fluid>
      Leave Pending
    </Container>
  )
}

export default LeavePending;
