// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar.sticky-top.bg-body-tertiary{
    background-color: white;
}
.Create-post-btn{
    background-color: #5473E3;
    color:white;
    border:none;
    border-radius: 4px;
    padding:4px 14px;
    font-size: 14px;
}
.Create-post-btn:hover{
    background-color: #3c5bd5;
}
#dropdown-basic-button{
    background-color: transparent;
    color:black;
    font-size: 14px;
    border:none;
    margin-left: auto;
    margin-right: auto;
}
#fill-tab-example-tab-Everyone{
    color:white;
}
#fill-tab-example-tab-Everyone:focus{
    color:#E98E6E;
}
#fill-tab-example-tab-Events{
    color:white;
}
#fill-tab-example-tab-Events:focus{
    color:#E98E6E;
}
#fill-tab-example-tab-AllFeeds{
    color:white;
}
#fill-tab-example-tab-AllFeeds:focus{
    color:#E98E6E;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Feeds/Feeds.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,6BAA6B;IAC7B,WAAW;IACX,eAAe;IACf,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB","sourcesContent":[".navbar.sticky-top.bg-body-tertiary{\n    background-color: white;\n}\n.Create-post-btn{\n    background-color: #5473E3;\n    color:white;\n    border:none;\n    border-radius: 4px;\n    padding:4px 14px;\n    font-size: 14px;\n}\n.Create-post-btn:hover{\n    background-color: #3c5bd5;\n}\n#dropdown-basic-button{\n    background-color: transparent;\n    color:black;\n    font-size: 14px;\n    border:none;\n    margin-left: auto;\n    margin-right: auto;\n}\n#fill-tab-example-tab-Everyone{\n    color:white;\n}\n#fill-tab-example-tab-Everyone:focus{\n    color:#E98E6E;\n}\n#fill-tab-example-tab-Events{\n    color:white;\n}\n#fill-tab-example-tab-Events:focus{\n    color:#E98E6E;\n}\n#fill-tab-example-tab-AllFeeds{\n    color:white;\n}\n#fill-tab-example-tab-AllFeeds:focus{\n    color:#E98E6E;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
