// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-Card-container{
border-bottom: 1px solid rgba(172, 175, 181, 0.1);

}
.review-Card-container-p1{
    font-family: "Kumbh Sans";
    font-size: 20px;
    font-weight: 600;
    line-height: 19.84px;
    text-align: left;
    color: #212121;
    
}
.review-Card-container-p2{
    font-family: "Kumbh Sans";
font-size: 14px;
font-weight: 400;
line-height: 17.4px;
text-align: left;
color: #2f2f2f;

}
.review-Card-container-p3{
    font-family:" Kumbh Sans";
font-size: 12px;
font-weight: 500;
line-height: 14.91px;
text-align: left;
color: #7647EB;

}
.review-Card-container-p3:hover{
    text-decoration: underline;
}
.overflow-container{
    overflow: scroll;
max-height: 192px;
-ms-overflow-style: none;
margin-bottom: 4px;
}

.overflow-container::-webkit-scrollbar {
 display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/ReviewCard/ReviewCard.css"],"names":[],"mappings":"AAAA;AACA,iDAAiD;;AAEjD;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;;AAElB;AACA;IACI,yBAAyB;AAC7B,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,gBAAgB;AAChB,cAAc;;AAEd;AACA;IACI,yBAAyB;AAC7B,eAAe;AACf,gBAAgB;AAChB,oBAAoB;AACpB,gBAAgB;AAChB,cAAc;;AAEd;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,gBAAgB;AACpB,iBAAiB;AACjB,wBAAwB;AACxB,kBAAkB;AAClB;;AAEA;CACC,aAAa;AACd","sourcesContent":[".review-Card-container{\nborder-bottom: 1px solid rgba(172, 175, 181, 0.1);\n\n}\n.review-Card-container-p1{\n    font-family: \"Kumbh Sans\";\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 19.84px;\n    text-align: left;\n    color: #212121;\n    \n}\n.review-Card-container-p2{\n    font-family: \"Kumbh Sans\";\nfont-size: 14px;\nfont-weight: 400;\nline-height: 17.4px;\ntext-align: left;\ncolor: #2f2f2f;\n\n}\n.review-Card-container-p3{\n    font-family:\" Kumbh Sans\";\nfont-size: 12px;\nfont-weight: 500;\nline-height: 14.91px;\ntext-align: left;\ncolor: #7647EB;\n\n}\n.review-Card-container-p3:hover{\n    text-decoration: underline;\n}\n.overflow-container{\n    overflow: scroll;\nmax-height: 192px;\n-ms-overflow-style: none;\nmargin-bottom: 4px;\n}\n\n.overflow-container::-webkit-scrollbar {\n display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
