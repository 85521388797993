import React, { useContext, useEffect, useState } from 'react';
import { allOrganisation, updateStatus } from "../../Services/CommonServices";
import { AppContext } from "../../../AppContext";
import OrganisationModal from './OrganisationModal';
import { toastError, toastSuccess } from '../../Shared/ToastMsgs';

function AllOrganisation() {
  const { organisations, setUpdateTrigger, setOrganisations, updateTrigger } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [action, setAction] = useState("");

  const handleOpen = (id, actionType) => {
    setOrgId(id);
    setAction(actionType);
    setOpen(true);
  };

  useEffect(() => {
    allOrganisation({})
      .then(response => {
        if (response) {
          setOrganisations(response.data);
        } else {
          console.log('Failed to fetch organisations:', response.message);
        }
      })
      .catch(error => {

        console.error('Error fetching organisations:', error);
      });
  }, [updateTrigger]);

  const approveHandler = (id) => {
    const approveData = { org_id: id, status: "approved" };
    updateStatus(approveData)
      .then((response) => {
        setUpdateTrigger(prev => !prev);
        toastSuccess("Organisation Approved Successfully");
      })
      .catch((err) => {
        toastError(err.message);
      });
  };
  

  const rejectHandler = (id) => {
    const rejectData = { org_id: id, status: "rejected" };
    updateStatus(rejectData)
      .then((response) => {
        setUpdateTrigger(prev => !prev);
        toastSuccess("Organisation Rejected Successfully");
      })
      .catch((err) => {
        toastError(err?.message);
      });
  };
  

  return (
    <>
      <OrganisationModal
        setOpen={setOpen}
        open={open}
        orgId={orgId}
        action={action}
        approveHandler={approveHandler}
        rejectHandler={rejectHandler}
      />
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Organisation Name</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {organisations?.map((organisation, index) => (
            <tr key={organisation.id}>
              <th scope="row">{index + 1}</th>
              <td>{organisation.org_name}</td>
              <td>{organisation.user_details?.name}</td>
              <td>{organisation?.user_details?.email}</td>
              <td>{organisation?.status}</td>
              <td>
                <button
                  className='btn btn-success'
                  disabled={organisation?.status === "approved"}
                  onClick={() => handleOpen(organisation?.org_id, 'approve')}
                >
                  {organisation?.status === "approved" ? "Approved" : "Approve"}
                </button>
                <button
                  className={organisation?.status === "approved" ? "d-none" : 'btn btn-danger'}
                  disabled={organisation?.status === "approved" || organisation?.status === "rejected"}
                  onClick={() => handleOpen(organisation?.org_id, 'reject')}
                >
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default AllOrganisation;
