import React, { useEffect } from "react";
import AttendanceIcon from "../../../assets/img/new_a.png";
import PunchIn from "../Attendance/PunchIn/PunchIn";
import {  Container } from "reactstrap";
import HomeBigCard from "../../Shared/HomeCards/HomeBigCard";
import HolidayCard from "../../Shared/HomeCards/HolidayCard";
import HomeSmallCard from "../../Shared/HomeCards/HomeSmallCard";
import { HOME_CARD_DATA } from "../../Shared/CardData/CardData";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import { ReactComponent as CardImg } from "../../../assets/img/CardImg/Circle.svg"
import { useConvertUTCtoISTTime } from "../../hooks/convertUTCtoISTConverter";

import "./Home.css"
import { getPendingList } from "../../Services/CommonServices";
import { getUserSubtitle } from "../../Services/UtilServices";

function HomeDesktop({homeData, manager, admin,employee,getGreeting}) {

    const {user,designation,attendance,holidays}=homeData


      const pageTitle = getGreeting();
  return (
    <div>
         <PageHeader
         pageTitle={pageTitle}
         breadCrumbs={[]}
     />
     <Container fluid className="home-container">
         <div className="d-flex flex-column  " style={{ rowGap: "40px" }}>
             <div className="row row-card-gap" >
                 <div className="  col-md-3  card-radius h-100 " style={{ padding: "0px 12px 0px 12px", }}>
                     <div className="bg-white card-radius h-100" style={{ padding: " 58px 35px" }} >
                         <CardImg className="card-img-style"  style={{height:"100px"}}/>
                         <div className="d-flex flex-column justify-content-center">
                             <p className="admin-text" >{user?.name}</p>
                             <p className="admin-sub-text">{getUserSubtitle(homeData)}</p>
                         </div>
                         <div className="d-flex signIn-container">
                             <div>
                                 <p className="signIn" >Sign In</p>
                                 <p className="signIn-time">{attendance?.check_in_time}</p>
                             </div>
                             <div className="vr-line"></div>
                             <div>
                                 <div>
                                     <p className="signIn">Sign Out</p>
                                     <p className="signIn-time">{attendance?.check_out_time}</p>
                                 </div>
                             </div>
                         </div>
                         {/* <div className="d-flex justify-content-center" style={{ marginTop: "10px" }}>
                         <button className="signIn-btn">
                             <FingerPrint />
                             <p className="m-0 text-white fingerprint-text" onClick={signHandler}>  Sign In </p>
                         </button>
                     </div> */}

                         <div>

                         </div>
                     </div>
                 </div>

                
                 {HOME_CARD_DATA.map((item, index) => {
                     if (manager && index === 3) {
                         return null;
                     }
                     if(employee && index==3 ){
                            return null;
                     }
                 
                     if ((admin && index === 1) || (manager && index == 0)|| (employee && index==4)) {
                         return <>
                             {!employee && <HomeBigCard admin={(admin && index === 1)} />}
                             <HomeSmallCard item={item} index={index} homeData={homeData} />
                            {!admin && <HolidayCard holidays={holidays} index={index}/>}
                         </>
                     }
                     return <>
                     <HomeSmallCard item={item} index={index} homeData={homeData} />
                     </>;
                 })}
             </div>
         </div>


     </Container>
     </div>
  );
}

export default HomeDesktop;
