import React, { useContext } from 'react'
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../assets/img/CardImg/Circle.svg"
import NavLogo from "../../../assets/img/brand/navLogo.svg"
import "./Login.css";
import { AppContext } from '../../../AppContext';

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

function LogoNavbar({logoutHandler}) {
  const items = [
    {
      label: <a>Settings</a>,
      key: '0',
    },
    {
      label: <a  onClick={logoutHandler}>LogOut</a>,
      key: '1',
    },
    
  ];
  
  const { value, isMobView } = useContext(AppContext);
  const UserAuth=value?.activeToken;
  return (
    <Navbar expand="lg bg-white" className="login-nav">
    <div className="d-flex logo-text">
      <img src={NavLogo} alt="logo" />
      <p className="attendEase">AttendEase</p>
    </div>
    <Navbar.Brand >
     {UserAuth &&
     <Dropdown
    menu={{
      items,
    }}
    trigger={['click']}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
      <img src={logo} className='cursor-pointer' style={{ height: '52px' }}/>
      </Space>
    </a>
  </Dropdown>
      
      }
    </Navbar.Brand>
  </Navbar>
  )
}

export default LogoNavbar