// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-card{
    margin-left:auto;
    margin-right:auto;
}
.event-card-logo{
    width:40px;
    height:40px;
}
.card-title-bar{
    display:flex;
    flex:1 1;
    justify-content: space-between;
}
.EventCardImg{
    width:120px;
    height:120px;
    margin-right:20px;
}
.greeting-box{
    display:flex;
}
.like-comment{
    margin-top: 15px;
}
.event-card-icon{
    color:#394657;
    margin-left: 4px;
    margin-right:4px;
}
.comment-box{
    padding:5px;
    border:1px solid darkblue;
    border-radius: 4px;
    width:60%;

}
@media(max-width:768px){
    .greeting-box{
        display:block;
    }
}
@media(max-width:425px){
    .greeting-box{
        display:block;
    }
    .card-title-bar{
        display:block;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Feeds/EventCard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,YAAY;IACZ,QAAM;IACN,8BAA8B;AAClC;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,SAAS;;AAEb;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".event-card{\n    margin-left:auto;\n    margin-right:auto;\n}\n.event-card-logo{\n    width:40px;\n    height:40px;\n}\n.card-title-bar{\n    display:flex;\n    flex:1;\n    justify-content: space-between;\n}\n.EventCardImg{\n    width:120px;\n    height:120px;\n    margin-right:20px;\n}\n.greeting-box{\n    display:flex;\n}\n.like-comment{\n    margin-top: 15px;\n}\n.event-card-icon{\n    color:#394657;\n    margin-left: 4px;\n    margin-right:4px;\n}\n.comment-box{\n    padding:5px;\n    border:1px solid darkblue;\n    border-radius: 4px;\n    width:60%;\n\n}\n@media(max-width:768px){\n    .greeting-box{\n        display:block;\n    }\n}\n@media(max-width:425px){\n    .greeting-box{\n        display:block;\n    }\n    .card-title-bar{\n        display:block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
