import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { balanceFunction } from "../../../Services/CommonServices"
import { leaveFunction } from "../../../Services/CommonServices"


function UserManage({ showLeaveDetails, setShowLeaveDetails, userID }) {
  const user = JSON.parse((localStorage.getItem("user_data")|| "{}") == 'undefined' ? '{}' : (localStorage.getItem("user_data")|| "{}"));
  const [leaveType, setLeaveType] = useState([]);
  const [inputValue, setInputValue] = useState({});
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    leaveFunction()?.then(res => {
      setLeaveType(res?.data?.data);
    }).catch(err => {
      console.log(err)
    })
  }, []);
 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = event.target.value;
    setInputValue((prevData) => {
      return { ...prevData, [name]: value };
    });
    setIsButtonEnabled(newValue !== "");
  };

  const handleButtonClick = (id, index) => {

    balanceFunction({
      leaveId: leaveType[index]?._id,
      leaveCount: inputValue[id],
      userId: user
    }).then(reply => {
      alert("Successfully updated")
    }).catch(err => {
     
    })
  };
  return (
    <>
      <Modal
        size="lg"
        show={showLeaveDetails}
        onHide={()=>setShowLeaveDetails(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header >
          <Modal.Title id="example-modal-sizes-title-lg">
            Manage User Leaves
          </Modal.Title>
          <button type="button" className="ni ni-fat-remove  close-Btn" onClick={()=>{setShowLeaveDetails(false)}} ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="card-container">
            {leaveType.map((data, index) => {
              const key = data.name.replace(/ /g, "_").toLowerCase();
              return (
                <Card
                  className="card-full"
                  style={{ width: "12rem", height: "12rem" }}
                  key={index}
                >
                  <Card.Body>
                    <div className="card-head">
                      <h6>{data.name}</h6>
                    </div>
                    <div className="card-main">
                      <InputGroup size="sm" className="mb-3">
                        <Form.Control
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"
                          name={key}
                          value={data.id}
                          onChange={handleInputChange}
                        />
                      </InputGroup>

                      <Button
                        variant="outline-primary"
                        onClick={() => handleButtonClick(key, index)}
                        disabled={!isButtonEnabled}
                      >
                        Update
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserManage;
