import Department from "./components/Pages/Department/Department";
import Designation from "./components/Pages/Designation/Designation";
import Leave from "./components/Pages/Leave/Leave/Leave";
import Networks from "./components/Pages/Networks/Network";
import Organisation from "./components/Pages/Organisation/Organisation"
import Permissions from "./components/Pages/Permissions/Permissions";
import UserDetails from "./components/Pages/UserDetails/UserDetails";
import Shift from "./components/Pages/Shift/Shift";



export const ROUTES_DATA = [
  
  {
    path: "/home",
    name: "Home",
    icon: "ni ni-tv-2 text-purple",
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles: ["employee", "Manager", "admin", "super admin"]
  },
  {
    path: "/attendance",
    name: "Attendance",
    icon: "ni ni-collection text-orange",
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles: ["employee", "Manager", "admin", "super admin"]

  },
  {
    path: "/leave",
    name: "Leave",
    icon: "ni ni-calendar-grid-58 text-yellow",
    component: <Leave />,
    dropdownArrow:"",
    hasDropdown:false,
    allowedRoles: ["employee", "Manager", "admin", "super admin"]

  },
  {
    path: "/organisation",
    name: "Organization",
    icon: "fas fa-building text-purple",
    component: <Organisation/>,
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles:["admin", "super admin"]
  },
  {
    path: "user-details",
    name: "Manage User",
    icon: "ni ni-single-02 text-red",
    component: <UserDetails />,
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles: ["employee", "Manager", "admin", "super admin"]

  },
  {
    path: "/department",
    name: "Departments",
    icon: "ni ni-building text-info",
    component: <Department />,
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles:["admin", "super admin"]
  },
  {
    path: "/designation",
    name: "Designation",
    icon: "ni ni-badge text-pink",
    component: <Designation />,
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles:["admin", "super admin"]
  },
  {
    path: "/shift",
    name: "Shift",
    icon: "ni ni-badge text-pink",
    component: <Shift />,
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles:["admin", "super admin"]
  },
  {
    path: "/permissions",
    name: "Permissions",
    icon: "ni ni-paper-diploma text-orange",
    component: <Permissions />,
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles:["admin", "super admin"]
  },
  {
    path: "/network",
    name: "Network",
    icon: "ni ni-paper-diploma text-orange",
    component: <Networks />,
    dropdownArrow:"",
    hasDropdown:false,
    submenu: [{}],
    allowedRoles:["admin", "super admin"]
  },

];



export default ROUTES_DATA;
