import React from 'react'
import { Container } from 'reactstrap'

function LeaveHistory() {
  return (
    <Container fluid>
      Leave History
    </Container>
  )
}

export default LeaveHistory
