import React, {useEffect} from 'react';
import {ReactComponent as ReporteeCardImg } from "../../../assets/img/CardImg/Circle.svg"
import {useParams} from "react-router-dom";
import {getUserSubtitle} from "../../Services/UtilServices";
function ManagerFlowCards({attendaceSummary, userData}) {

    const params = useParams();

  return (
    <div className='row  ' style={{columnGap: "1%", padding:"0.6rem 1.5rem 1.5rem 1.5rem ",rowGap:"5px"}}>
    
        {attendaceSummary && Object.keys(attendaceSummary).map((item, index) => {
            const { label, value } = attendaceSummary[item];
            return (
                <div key={index} className='bg-white col d-flex flex-column card-radius'>
                    <p className='text-day'>{label}</p>
                    <p className='text-no m-0'>{value}</p>
                </div>
            );
        })}

              {/* <div className='bg-white col-md-2 d-flex flex-column  card-radius'>
            <p className='text-day '>Total</p>
            <p className='text-no m-0'>-</p>
        </div> */}
        
    </div>
  )
}

export default ManagerFlowCards
