import React, { useContext, useEffect, useState } from "react";
import { Map, Marker } from "pigeon-maps";
import "./AttendancePunch.css";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../assets/img/back_arrow.svg";
import Cached from "../../../assets/img/cached.svg";
import LocationIcon from "../../../assets/img/location_icon.svg";
import { postAttendance, putAttendance } from "../../Services/CommonServices";
import { AppConfigContext } from "antd/es/app/context";
import { AppContext } from "../../../AppContext";
import { toast } from "react-toastify";
import { formatDate, formatTime } from "../../Services/UtilServices";
import { setDefaults, fromLatLng } from "react-geocode";

function AttendancePunch() {
  const { isPunch, openModal, setOpenModal, setSuccessText } =
    useContext(AppContext);
  const { office } = JSON.parse(localStorage.getItem("user_data"));
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [loaded, setLoaded] = useState(false);
  const [locationType, setLocationType] = useState(null);
  const [distance, setDistance] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setDistance(
      calculateDistance(location?.lat, location?.lng, office?.lat, office?.long)
    );
  }, [location]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoaded(true);
        },
        (error) => {
          console.error("Error fetching user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    setDefaults({
      key: "AIzaSyBYAAkJ_L_JnJXeD2m8gaM-wulIq2iEAkk",
      language: "en",
      region: "es",
    });

    fromLatLng(location?.lat, location?.lng)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        setCurrentLocation(results[1]?.address_components?.[0]?.long_name);
      })
      .catch(console.error);
  }, [location]);

  const calculateDistance = (
    lattitude1,
    longittude1,
    lattitude2,
    longittude2
  ) => {
    const toRadian = (n) => (n * Math.PI) / 180;
    let lat2 = lattitude2;
    let lon2 = longittude2;
    let lat1 = lattitude1;
    let lon1 = longittude1;
    let R = 6371;
    let x1 = lat2 - lat1;
    let dLat = toRadian(x1);
    let x2 = lon2 - lon1;
    let dLon = toRadian(x2);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadian(lat1)) *
        Math.cos(toRadian(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d;
  };

  const handleButtonClick = () => {
    setIsAnimating(true);
    calculateDistance(location?.lat, location?.lng, office?.lat, office?.long);
    setTimeout(() => setIsAnimating(false), 500);
  };

  const handleAttChange = (e) => {
    let { value } = e.target;
    if (typeof value === "string") value = parseInt(value);
    setLocationType(value);
  };

  const punchoutFn = () => {
    const data = {
      attendanceTypeId: locationType,
      latitude: location?.lat,
      longitude: location?.lng,
    };
    putAttendance(data)
      .then((res) => {
        setSuccessText(res?.data?.message);
        setOpenModal(true);
        setTimeout(() => {
          setSuccessText(res?.data?.message);
          setOpenModal(false);
          navigate("/home");
        }, 2000);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const punchinFn = () => {
    const data = {
      attendanceTypeId: locationType,
      latitude: location?.lat,
      longitude: location?.lng,
    };
    postAttendance(data)
      .then((res) => {
        console.log("res of success text", res?.data?.message);
        setSuccessText(res?.data?.message);
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          navigate("/home");
        }, 2000);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message || "Error while punch in");
      });
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="punch_container">
      <Map defaultCenter={[location.lat, location.lng]} zoom={16}>
        <Marker color="Red" width={50} anchor={[location.lat, location.lng]} />
      </Map>
      <div className="btn_container">
        <button onClick={() => navigate("/home")} className="back_btn">
          <img src={BackArrow} />
        </button>
        <button
          className="get_help_btn"
          onClick={() =>
            (window.location = "mailto:neeraj.kumar@jarvis.consulting")
          }
        >
          Get Help
        </button>
      </div>
      <div className="punch_sub_container">
        <div className="psc_one">
          <div
            type="button"
            className={isAnimating ? "rotate-animation" : ""}
            onClick={handleButtonClick}
          >
            <img src={Cached} />
          </div>

          <span className="ApTextOne">
            Current Location Accuracy: {Math.floor(distance)}m
          </span>
        </div>
        <div className="ApMarginContt">
          <div className="OfficeAddressContt">{office?.full_address}</div>
          <span style={{ fontSize: "12px", color: "#828282" }}>
            This is the alloted office Address for you.
          </span>
        </div>
        <div className="ApDashedDiv"></div>
        <div style={{}}>
          <div style={{ display: "flex", gap: "10px", paddingLeft: "15px" }}>
            <img src={LocationIcon} />
            <div
              style={{ fontSize: "14px", fontWeight: "600", color: "#7647EB" }}
            >
              {currentLocation}
            </div>
          </div>
          <span
            style={{ fontSize: "12px", marginLeft: "23px", color: "#828282" }}
          >
            This is your current Location
          </span>
        </div>
        <div className="ApDashedDiv"></div>
        <div className="ApDateTimeContt">
          <div>
            <div className="ApDateTime">Date</div>
            <div className="OfficeAddressContt">{formatDate(new Date())}</div>
          </div>
          <div>
            <div className="ApDateTime">Time</div>
            <div className="OfficeAddressContt">{formatTime(new Date())}</div>
          </div>
        </div>
        <div className="ApDashedDiv"></div>
        <div className="dropdown-container">
          <label>Sign In Location</label>
          <select
            id="location-select"
            className="custom-select"
            onChange={handleAttChange}
          >
            <option value="" selected>
              Select
            </option>
            <option value={1}>Office</option>
            <option value={2}>Work From Home</option>
            <option value={3}>On Duty</option>
          </select>
        </div>
        <div className="sign_in_btn_div">
          <button
            className="sign_in_btn"
            onClick={isPunch ? punchinFn : punchoutFn}
            disabled={locationType === null}
          >
            {isPunch ? "Sign in" : "Sign out"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AttendancePunch;
