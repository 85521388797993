import React, { useState, useEffect, useContext } from "react";
import PageComponentHeader from "../PageComponentHeader/PageComponentHeader";
import "./PageComponent.css";
import { Button } from "react-bootstrap";
import { AppContext } from "../../../AppContext";

const PageComponent = React.memo(({
  headerTitle,
  buttonText,
  tableComponent: TableComponent,
  formComponent: FormComponent,
  tableProps,
  formProps,
}) => {
  const { isDrawerOpen, setIsDrawerOpen } = useContext(AppContext);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div className={`component-container ${isDrawerOpen ? "shifted" : ""}`}>
      <div className="w-100">
        <PageComponentHeader
        headerTitle={headerTitle}
        buttonText={buttonText}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          toggleDrawer={toggleDrawer}
        />
        {TableComponent && <TableComponent {...tableProps} />}
      </div>
      <div className={`drawer ${isDrawerOpen ? "open" : ""}`}>
        {FormComponent && <FormComponent {...formProps} />}

        <div className="drawer-footer">
          <button
            onClick={() => {
              setIsDrawerOpen(false);
            }}
            style={{background:"transparent", color:"#7647EB"}}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
});

export default PageComponent;
