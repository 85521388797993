import React, { useEffect, useState } from "react";
import ArrowLeft from "../../../../assets/img/si_Arrow_left.svg";
import Calendar from "../../../../assets/img/login.svg";
import Dropdown from "react-bootstrap/Dropdown";
import "./Attendance.css";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import ArrowIcon from "../../../../assets/img/arrow_right.svg";

import AttendanceSummary from "./AttendancePieChart/AttendanceSummary";
import AttendanceReport from "./AttendancePieChart/AttendanceReport";
import AttendanceDetail from "../shared/AttendanceDetail";
import {
  getUserAttendance,
  getUserAttendancePerformance,
  getUserAttendanceSummary,
} from "../../../Services/CommonServices";
function AttedanceLog() {
  const [attendaceList, setAttendanceList] = useState([]);
  const [attendaceSummary, setAttendaceSummary] = useState(null);
  const [attendacePerformance, setAttendacePerformance] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getUserAttendance()
      .then((res) => {
        setAttendanceList(res.data.data);
      })
      .catch((err) => {
        console.log("error in fetching attendance", err);
      });
    getUserAttendanceSummary()
      .then((res) => {
        setAttendaceSummary(res.data.data);
      })
      .catch((err) => {
        console.log("error in fetching attendance summary", err);
      });
    getUserAttendancePerformance()
      .then((res) => {
        setAttendacePerformance(res.data.data);
      })
      .catch((err) => {
        console.log("error in fetching attendance summary", err);
      });
  }, []);
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ backgroundColor: "#f6f8fa" }}>
      <div className="al-log">
        <div
          style={{
            width: "51%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Link to={"/home"}>
            <img src={ArrowLeft} />
          </Link>

          <span style={{ fontSize: "18px", fontWeight: "600", color: "black" }}>
            Attendance Logs
          </span>
        </div>
      </div>
      {value === 0 && (
        <div
          type="button"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
            height: "52px",
            backgroundColor: "white",
            borderRadius: "12px",
            margin: "14px",
          }}
          onClick={() => navigate("/regularization")}
        >
          <span
            style={{ fontWeight: "500", fontSize: "16px", color: "#212121" }}
          >
            Apply Regularisation
          </span>
          <Link style={{ marginRight: "0" }}>
            {" "}
            <img src={ArrowIcon} />
          </Link>
        </div>
      )}

      <div className="attendance-tab">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Daily Records" {...a11yProps(0)} />
              <Tab label="Report" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {attendaceList?.user?.attendance?.map((attendanceData) => {
              return <AttendanceDetail attendanceData={attendanceData} />;
            })}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AttendanceSummary attendaceSummary={attendaceSummary} />
            <AttendanceReport attendacePerformance={attendacePerformance} />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
}

export default AttedanceLog;
