import { useState, useEffect } from "react";
import "./Clock.css"


const Mark = ({ angle, type }) => {
    return (
        <div
            className={`clock__face-mark clock__face-mark--${type}`}
            style={{ transform: `rotate(${angle}deg)` }}
        >
            <div
                style={{
                    width: "2px",
                    height: type === "hour" ? "12px" : "3px",
                    backgroundColor: type === "hour" ? "red" : "black"
                }}
            />
        </div>
    );
};

const Hand = ({ type, angle }) => {
    return (
        <div className="clock__hand" style={{ transform: `rotate(${angle}deg)` }}>
            <div className={`clock__hand-body clock__hand-body--${type}`} />
        </div>
    );
};
export default function Clock() {
    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(new Date()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    const renderFaceMarks = () => {
        const marks = [];
        for (let i = 1; i <= 60; i++) {
            marks.push(<Mark angle={i * 6} type={i % 5 === 0 ? "hour" : "minute"} key={i} />);
        }
        return marks;
    };
    return (
        <div className="container">
            <div className="clock">
                <div className="clock__face">{renderFaceMarks()}</div>
                <Hand type="hour" angle={30 * currentTime?.getHours()} />
                <Hand type="minutes" angle={6 * currentTime?.getMinutes()} />
                <Hand type="seconds" angle={6 * currentTime?.getSeconds()} />
            </div>
        </div>
    );
}
