import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { punchInFunction, punchOutFunction } from "../../../Services/CommonServices";
import { toastError, toastSuccess } from '../../../Shared/ToastMsgs';
import "./PunchIn.css";
import { getLastAttendance } from '../../../Services/CommonServices';

function PunchInModal({ showModal, setShowModal, checkIn, setCheckIn, checkOut, setCheckOut, userAttendance , refreshAttendanceData }) {
    const date = new Date();
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let day = weekday[date.getDay()];
    const punchDate = date.toLocaleDateString();
    const punchTime = date.toLocaleTimeString();
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const [accuracy, setAccuracy] = useState(null);
    const [distance, setDistance] = useState(null);
    const [isWithinRadius, setIsWithinRadius] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState("office");
    const [comment, setComment] = useState("");
    const [checkInTime, setCheckInTime] = useState(null);
    const [checkOutTime, setCheckOutTime] = useState(null);

    useEffect(() => {
        if (navigator.geolocation && selectedLocation === "office") {
            fetchLocation();
            const intervalId = setInterval(fetchLocation, 20000);
            return () => clearInterval(intervalId);
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (userAttendance) {
            setCheckInTime(userAttendance?.data?.data?.checkInTime);
            setCheckOutTime(userAttendance?.data?.data?.checkOutTime);
        }
    }, [userAttendance]);

    const fetchLocation = () => {
        navigator.geolocation.getCurrentPosition(success, error);
    };

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLat(latitude);
        setLong(longitude);

        const calculatedDistance = calculateDistance(latitude, longitude, targetLat, targetLong);
        const currentAccuracy = position.coords.accuracy;
        setIsWithinRadius(calculatedDistance <= 5000);
        setDistance(calculatedDistance);
        setAccuracy(currentAccuracy);
    }

    function error() {
        toastError("Unable to retrieve your location. Please allow location access.");
    }

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3;
        const φ1 = lat1 * Math.PI / 180;
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c;
        return distance;
    };

    const handleCheckInSubmit = () => {
        const distance = calculateDistance(lat, long, targetLat, targetLong);
        if (distance <= 5000 || selectedLocation !== "office") {
            punchInFunction({
                latitude: lat,
                longitude: long,
                comment: selectedLocation === "On-Duty" ? comment : ""
            }).then(reply => {
                if (reply?.status === 200) {
                    refreshAttendanceData();
                    setCheckIn(reply.data.data);
                    toastSuccess("Successfully punched in.");
                } else {
                    toastError("Failed to punch in. Please try again later.");
                }
            }).catch(() => {
                toastError("Failed to punch in. Please try again later.");
            });
            setShowModal(false);
            setCheckOut(null);
        } else {
            toastError("You are not within 500 meters of the designated location.");
        }
    };

    const handleCheckOutSubmit = () => {
        const distance = calculateDistance(lat, long, targetLat, targetLong);
        if (distance <= 5000 || selectedLocation !== "office") {
            punchOutFunction({
                latitude: lat,
                longitude: long,
                comment: selectedLocation === "On-Duty" ? comment : ""
            }).then(reply => {
                if (reply?.status === 200) {
                    refreshAttendanceData();
                    setCheckOut(reply.data.data);
                    setCheckIn(null);
                    toastSuccess("Successfully punched out.");
                } else {
                    toastError("Failed to punch out. Please try again later.");
                }
            }).catch(() => {
                toastError("Failed to punch out. Please try again later.");
            });
            setShowModal(false);
        } else {
            toastError("You are not within 500 meters of the designated location.");
        }
    };


    const handleRefreshLocation = () => {
        fetchLocation(); 
        const locationInfoElement = document.querySelector('.location-info');
        locationInfoElement.classList.add('clicked');
        setTimeout(() => {
            locationInfoElement.classList.remove('clicked');
        }, 1000);
    };


    const targetLat = 28.679008;
    const targetLong = 77.2046736;


    return (
        <>
            <Modal show={showModal}>
                <Modal.Header>
                    <Modal.Title>Punch In Details</Modal.Title>
                    <button type="button" className="ni ni-fat-remove close-Btn" style={{ color: "black" }} onClick={() => { setShowModal(false) }}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='punch-in-details'>
                        {selectedLocation === "office" && (
                            isWithinRadius ? (
                                <p style={{ color: 'green' }}><i className="fas fa-map-marker-alt"></i> You are currently within 500m of the designated location.</p>
                            ) : (
                                <p style={{ color: 'red' }}><i className="fas fa-map-marker-alt"></i> You are not within 500 meters of the designated location. The GPS accuracy exceeds {accuracy} meters. Please wait for a while to mark your attendance.</p>
                            )
                        )}

                        <p className="location-info">
                            Lat, Long: {lat}, {long}
                            <p><i className="fas fa-sync-alt" onClick={handleRefreshLocation} style={{ cursor: 'pointer' }}></i></p>
                        </p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="punch-date">
                            <label>Date</label>
                            <h6>{punchDate}</h6>
                        </div>
                        <div className="punch-time">
                            <label>Time</label>
                            <h6>{punchTime}</h6>
                        </div>
                    </div>
                    <div className="attendance-scheme">
                        <label>Attendance Schema</label>
                        <h6>{`${day} | Flexible Shift`}</h6>
                    </div>
                    <label>Please select your location</label>
                    <select onChange={(e) => setSelectedLocation(e.target.value)}>
                        <option value="office">Office</option>
                        <option value="Work from Home">Work from Home</option>
                        <option value="On-Duty">On-Duty</option>
                    </select>
                    {selectedLocation === "On-Duty" && (
                        <div>
                            <label>Comment</label>
                            <textarea value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={
                            !checkInTime && !checkOutTime
                                ? handleCheckInSubmit
                                : checkInTime && !checkOutTime
                                    ? handleCheckOutSubmit
                                    : handleCheckInSubmit
                        }
                    >
                        {!checkInTime && !checkOutTime ? "Punch In" : checkInTime && !checkOutTime ? "Punch Out" : "Punch In"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PunchInModal;
