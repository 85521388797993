import React from "react";
import { Button, Modal, Box, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";

import "./SuccessScreen.css";

const SuccessScreen = ({ openModal, setOpenModal, successText }) => {
  return (
    <Modal
      open={openModal}
      onClose={setOpenModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 380,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "14px",
          p: 4,
        }}
      >
        <div className="modal-web-border  successScreen-container">
          <VerifiedIcon
            className="blinking-icon"
            sx={{ fontSize: 48, width: "7em", height: "3em", color: "#7C76E7" }}
          />
          <p className="modal-text-mob">{successText}</p>

          {/* <div className="d-flex justify-content-center">
            <button className="successScreen-btn">Go Back</button>
          </div> */}
        </div>
      </Box>
    </Modal>
  );
};

export default SuccessScreen;
