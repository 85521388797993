import Leave from "../../../assets/img/CardImg/Leave.webp"
import Report from "../../../assets/img/CardImg/report.svg"
import Employee from "../../../assets/img/CardImg/employee.svg"
import RightArrow from "../../../assets/img/CardImg/ArrowRight.svg"
import Review from "../../../assets/img/CardImg/Review.svg"
import AttnedanceImg from "../../../assets/img/CardImg/attendance.webp"
import Birthday from "../../../assets/img/CardImg/birthday.png"


export const HOME_CARD_DATA = [
    {
        emptyImg: <img src={Review} style={{width: "45%"}} />,
        type: "empty",
        heading: "Review",
        emptyMsg: "Hurrah ! You’ve nothing to review.",

    },
    {
        img: <img src={Leave} style={{width: "45%"}} />,
        type: "small",
        heading: "Leave",
        desc: "Simplify the leave approval process and gain instant visibility into leave balances and employee absence patterns.",
        navigate: "/home",
        navigateImg: <img src={RightArrow} />

    },
    {
        img: <img src={Employee} style={{width: "45%"}} />,
        type: "small",
        heading: "Employees",
        desc: "Easily manage and update employee records with real-time data, ensuring accurate and up-to-date information at your fingertips.",
        navigate: "/home",
        navigateImg: <img src={RightArrow} />
    
    },
    {
        img: <img src={Report} style={{width: "45%"}} />,
        type: "small",
        heading: "Reports",
        desc: "Generate detailed reports and visualizations to analyze attendance trends, identify patterns, and make informed decisions.",
        navigate: "/home",
        navigateImg: <img src={RightArrow} />

    },
    {
        img: <img src={AttnedanceImg} style={{width: "45%"}} />,
        type: "small",
        heading: "Attendance",
        desc: "View your daily sign-ins, sign-outs, and total hours worked to stay on top of your attendance.",
        navigate: "/attendance",
        navigateImg: <img src={RightArrow} />        
    },
    // {
    //     heading:"Holiday Calender",
    //     navigateImg: <RightArrow />,
    //     holidayName:"Independence Day",
    //     holidayDay:"Thursday",
    //     type:"small"
    // },

    // {
    //     img: <img src={Birthday} style={{width: "45%"}} />,
    //     type: "small",
    //     heading: "Birthday",
    //     desc: "Keep track of upcoming birthdays and celebrate special moments, fostering a positive workplace culture.",
    //     navigate: "/home",
    //     navigateImg: <img src={RightArrow} />
    // }
]
