import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Leave.css";
import LeaveApply from "../LeaveApply/LeaveApply";
import LeavePending from "../LeavePending/LeavePending";
import LeaveHistory from "../LeaveHistory/LeaveHistory";
import {Nav,Item} from "react-bootstrap"
import { Container } from "reactstrap";

function Leave() {
  return (
    <Container fluid>
      <Tabs defaultActiveKey="Apply" id="fill-tab-example" className="mb-3">
        <Tab eventKey="Apply" title="Apply">
          <LeaveApply />
        </Tab>
        <Tab eventKey="Pending" title="Pending">
          <LeavePending />
        </Tab>
        <Tab eventKey="History" title="History">
          <LeaveHistory />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Leave;
