import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function OrganisationModal({ open, setOpen, orgId, action, approveHandler, rejectHandler }) {
  const handleClose = () => setOpen(false);

  const handleAction = () => {
    if (action === 'approve') {
      approveHandler(orgId);
    } else {
      rejectHandler(orgId);
    }
    handleClose();
  };

  const actionText = action === 'approve' ? 'Approve' : 'Reject';
  const modalTitle = `${actionText} Organisation`;
  const modalDescription = `Are you sure you want to ${actionText.toLowerCase()} this organisation?`;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {modalTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {modalDescription}
        </Typography>
        <div className='d-flex justify-content-end' style={{ columnGap: "17px" }}>
          <Button onClick={handleAction} variant="contained" color={action === 'approve' ? 'success' : 'error'} sx={{ mt: 2 }}>
            {actionText}
          </Button>
          <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default OrganisationModal;
