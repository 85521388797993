import React from "react";
import Avatar from "@mui/material/Avatar";
import { Link, useNavigate } from "react-router-dom";
import ProfileFrame from "../../../assets/img/profile_frame.svg";
import VectorRight from "../../../assets/img/Vector_right.svg";

function HomeFooter({
  selectedTab,
  setSelectedTab,
  HomeBlack,
  HomeWhite,
  ProfileBlack,
  ProfileWhite,
  homeData,
  logoutHandler,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="mob-footer">
        <div className="tabs">
          <div className="MobTabsContt">
            <div
              className={`tab ${selectedTab === 1 ? "selected" : ""}`}
              onClick={() => setSelectedTab(1)}
            >
              <img src={selectedTab === 1 ? HomeWhite : HomeBlack} />
            </div>
            <div>Home</div>
          </div>
          <div className="TabWrapper">
            <div
              className={`tab ${selectedTab === 2 ? "selected" : ""}`}
              onClick={() => setSelectedTab(2)}
            >
              <img src={selectedTab === 2 ? ProfileWhite : ProfileBlack} />
            </div>
            <div>Profile</div>
          </div>
        </div>
      </div>
      {selectedTab === 2 && (
        <div>
          <div className="profil_bg_div">
            <div className="profile_header">
              <Avatar className="ProfileAvtarStyle">
                {homeData?.user?.name?.charAt(0)}
              </Avatar>
              <div className="FooterTextOne">
                {homeData?.user?.name?.split(" ")[0]}
              </div>
              <div className="profileEmpId">{homeData?.user?.empId}</div>
            </div>
          </div>
          <div
            className="proframe"
            type="button"
            onClick={() => navigate("/profile-details")}
          >
            <div className="proframe_sub">
              <img src={ProfileFrame} />
              <span style={{ color: "black" }}>Profile Details</span>
            </div>
            <div>
              <Link to="/profile-details">
                <img src={VectorRight} />
              </Link>
            </div>
          </div>
          <div className="btn-div-logout">
            <button onClick={logoutHandler} className="LogOutBtn">
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeFooter;
