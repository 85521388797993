import React, { useEffect, useState } from "react";
import { Card, Container, Row } from "reactstrap";
import Button from 'react-bootstrap/Button';
import { userListFunction } from "../../../Services/CommonServices";
import { Table, Modal } from "reactstrap"
import AttendanceHistoryModal from "./AttendanceHistoryModal";
import Pagination from '@mui/material/Pagination';

export default function UserAttendanceInfo() {
    const [user, setUser] = useState([])
    const [showUserAttendance, setShowUserAttendance] = useState(false)
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 7;
    const [id, setId] = useState(null)
    useEffect(() => {
        getUser(page);
    }, [page])
    const getUser = () => {
        userListFunction({ page, limit: itemsPerPage }).then(reply => {

            setUser(reply.data.data)
            setTotalItems(reply.data.total);
        }).catch(error => {
            console.log(error)

        })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return (
        <>
            <Container fluid >
                {showUserAttendance && <AttendanceHistoryModal setShowUserAttendance={setShowUserAttendance} showUserAttendance={showUserAttendance} userId={id.id} />}
                <Row>
                    <Card className="col-11 card table-responsive">
                        <Table className="align-items-center">
                            <thead className="thead-light">
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Employee Id</th>
                                    <th>Department</th>
                                    <th>Designation</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {user.map((items, index) => (
                                    <tr key={index}>
                                        <td>{items.name}</td>
                                        <td>{items._id}</td>
                                        <td>{items?.designation?.department?.name}</td>
                                        <td>{items?.designation?.name}</td>
                                        <td><Button variant="outline-primary" onClick={() => {
                                            setShowUserAttendance(true);
                                            setId({
                                                id: items._id,
                                            });
                                        }}>Show History</Button>{' '}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handlePageChange}
                                variant="outlined"
                                color="primary"
                            />
                        </div>
                    </Card>
                </Row>
            </Container>
        </>
    )
}