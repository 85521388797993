// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-component-header-btn{
    background: #7849EC;
    color: white;
    border-radius: 100px;
    padding: 10px 35px 10px 35px;   
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/PageComponentHeader/PageComponentHeader.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,oBAAoB;IACpB,4BAA4B;AAChC","sourcesContent":[".page-component-header-btn{\n    background: #7849EC;\n    color: white;\n    border-radius: 100px;\n    padding: 10px 35px 10px 35px;   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
