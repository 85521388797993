import React, { useEffect, useState } from 'react';
import './NewPasswordPage.css'; 
import { setNewPasswordd} from '../../Services/CommonServices';
import axios from 'axios';
import { toast } from 'react-toastify';
import BackImg from '../../../assets/img/back123.jpg'
import { useNavigate } from 'react-router-dom';


const NewPasswordPage = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [tokenFromUrl, setTokenFromUrl] = useState('');
    const navigate =  useNavigate()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const resetToken = searchParams.get('token');

    setTokenFromUrl(resetToken);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword === confirmPassword) {
      try {
        const response = await setNewPasswordd({
            newPassword: newPassword,
            resetToken: tokenFromUrl
        });
        console.log("Password reset successful:", response.data);
        if (response.status === 200){
            toast.success(response.data.data.message)
            
            setTimeout(() => {
                navigate("/");
            }, 5000); 
        }
  
    } catch (error) {
        console.error("Error resetting password:", error);
        if (error.response && error.response.status === 500) {
          setError(error.response.data.message || 'Failed to reset password');
        } else {
          setError('Failed to reset password');
        }
      }
    } else {
      setError("Passwords do not match");
    }
    setNewPassword('')
    setConfirmPassword('')

  };

  return (
    <>

<div className="gmail-img"><img src={BackImg} alt="Email" className="email-icon" /></div>
    <div className='parent-content'>
    <div className="new-password-page">
      <h1>Reset Password</h1>
      <div>Please enter and confirm your new password below.</div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="new-password">New Password</label>
        <input
          type="password"
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <label htmlFor="confirm-password">Confirm Password</label>
        <input
          type="password"
          id="confirm-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        {error && <p className="error-message">{error}</p>}
        <button type="submit">Reset Password</button>
      </form>
      
    </div>
    </div>

    </>
  );
};

export default NewPasswordPage;
