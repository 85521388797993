import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import "./Department.css";
import { ReactComponent as EditIcon } from "../../../assets/img/edit-icon.svg";
import { AppContext } from "../../../AppContext";

const DepartmentTable = ({ department,setUpdateData }) => {
    const {isDrawerOpen,setIsDrawerOpen}=useContext(AppContext);
  return (
    <div className="m-4">
      <div className="table-component-div">
        <Table className="align-items-center m-0">
          <thead className="thead-light">
            <tr>
              <th>S. no</th>
              <th>Department Name</th>
              <th>Associated Users</th>
              <th>Email</th>
              <th>Department Head</th>
              <th>Shift Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ background: "white" }}> 
            {department.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <EditIcon
                      onClick={() => {
                        setUpdateData(item)
                        setIsDrawerOpen(true)
                      }}
                    />
                    {/* <Button
                    // onClick={() => { updateClicked(item) }}
                    >
                    
                    </Button> */}
                    {/* <Button
                    // onClick={() => { setdeleteId(item._id) }}
                    >
                      <RiDeleteBin5Fill />
                    </Button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DepartmentTable;
