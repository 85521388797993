import React, { useContext, useEffect, useState } from 'react'
import { allOrganisation,updateStatus } from "../../Services/CommonServices";
import { AppContext } from '../../../AppContext';

function RejectedOrganisation() {
  const { organisations ,setUpdateTrigger} = useContext(AppContext);


  
    return (
  <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Organisation Name</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
                      </tr>
        </thead>
        <tbody>
          {organisations?.filter(organisation=>organisation?.status==="rejected")?. map((organisation, index) => (
            <tr key={organisation.id}>
              <th scope="row">{index + 1}</th>
              <td>{organisation.org_name}</td>
              <td>{organisation.user_details?.name}</td>
              <td>{organisation?.user_details?.email}</td>
              <td>{organisation?.status}</td>    
            </tr>
          ))}
        </tbody>
      </table>  )
  }
  



export default RejectedOrganisation