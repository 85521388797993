import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import "./Regularization.css"
import { TextField } from '@mui/material';
import { bulkApprove } from '../../../Services/CommonServices';
import { toastError, toastSuccess } from '../../ToastMsgs';
import { error } from 'ajv/dist/vocabularies/applicator/dependencies';

function BulkUploadModal({ isShowBulk, setShowBulk }) {
    const [isReason,setReason]=useState("")

    const bulkApproveHandler=()=>{
        const data =isReason
        bulkApprove(data).then((response)=>{
            toastSuccess(response.data.message)
        }).catch(error=>toastError(error.message))
    }
    const handleReasonChange=(e)=>{
        setReason(e.target.value)
    }
    

    return (
        <Modal
            open={isShowBulk}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 350,
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: '14px',
                p: 3,
            }}>
                <div className='d-flex flex-column' style={{rowGap:"30px"}}>
                    <div>

                    <p className='m-0 reason-heading'>Reason</p>
                    <div>
                        <TextField
                        placeholder="Enter here"
                            multiline
                            rows={6}
                            variant="outlined"
                            fullWidth
                            // value={}
                            onChange={handleReasonChange}
                            sx={{ mb: 2, borderRadius: "11px" }}
                            />

                    </div>
                      </div>
                    <div className='d-flex justify-content-end'>
                        <button className='btn-cancel' onClick={()=>setShowBulk(false)}>Cancel</button>
                        <button className='btn-approve' disabled={isReason.length==0} onClick={bulkApproveHandler}>Approve All</button>
                    </div>


                </div>
            </Box>
        </Modal>
    );
}

export default BulkUploadModal;
