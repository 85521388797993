// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
    position: fixed;
    /* top: 0; */
    right: 0;
    height: 100%;
    width: 30%; /* 1/4th of the page */
    background-color: #333;
    color: black;
    background-color: white;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform 0.8s ease-in-out;
  }
  
  .drawer.open {
    transform: translateX(0);
    height: 100%;
    position: fixed;
  }

  .component-container {
    padding: 0;
    display: flex;
    transition: margin-right 0.8s ease-in-out;
  }
  
  .component-container.shifted {
    margin-right: 35%; /* Shift the content to the left by 25% */
  }
  .drawer-footer {
    position: relative;
    bottom: 0px;
    right: -12px;
    width: calc(100% - 40px);
    display: flex;
    justify-content: flex-end;
  }`, "",{"version":3,"sources":["webpack://./src/components/Shared/PageComponent/PageComponent.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,YAAY;IACZ,UAAU,EAAE,sBAAsB;IAClC,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,2BAA2B;IAC3B,sCAAsC;EACxC;;EAEA;IACE,wBAAwB;IACxB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,yCAAyC;EAC3C;;EAEA;IACE,iBAAiB,EAAE,yCAAyC;EAC9D;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,aAAa;IACb,yBAAyB;EAC3B","sourcesContent":[".drawer {\n    position: fixed;\n    /* top: 0; */\n    right: 0;\n    height: 100%;\n    width: 30%; /* 1/4th of the page */\n    background-color: #333;\n    color: black;\n    background-color: white;\n    overflow: hidden;\n    transform: translateX(100%);\n    transition: transform 0.8s ease-in-out;\n  }\n  \n  .drawer.open {\n    transform: translateX(0);\n    height: 100%;\n    position: fixed;\n  }\n\n  .component-container {\n    padding: 0;\n    display: flex;\n    transition: margin-right 0.8s ease-in-out;\n  }\n  \n  .component-container.shifted {\n    margin-right: 35%; /* Shift the content to the left by 25% */\n  }\n  .drawer-footer {\n    position: relative;\n    bottom: 0px;\n    right: -12px;\n    width: calc(100% - 40px);\n    display: flex;\n    justify-content: flex-end;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
