
import { useState } from "react";
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";
import { Navbar, NavItem, NavLink, Nav, Container,
} from "reactstrap";
import "./Sidebar.css"

const Sidebar = (props) => {
  const {  routes,value } = props;
  const user = JSON.parse((localStorage.getItem("user_data") || "{}") == 'undefined' ? '{}' : (localStorage.getItem("user_data") || "{}"));
  const [collapseOpen, setCollapseOpen] = useState();
  const [showDropdown, setShowDropdown] = useState(false)
  const location=useLocation()



  const closeCollapse = (dropdown) => {
    setCollapseOpen(false);
    if (dropdown === true) {
      setShowDropdown(!showDropdown)
    }
    else {
      setShowDropdown(false)
    }
  };


  const createLinks = (routes) => {
    return routes.map((item, key) => {
      

     const data= item.allowedRoles.includes(value?.role?.name)
     if(data){
      return (
        <>
                  <NavItem key={key} className="sidebar-btn">
                    <NavLink
                      to={item?.hasDropdown ? null : item?.path}
                      tag={NavLinkRRD}
                      active={location.pathname===item.path}
                      onClick={() => closeCollapse(item.hasDropdown)}
                    >
                  
                      {(item?.name === "Manage User" && user?.role?.name === "Manager") ? "Reportees" : item?.name}
                      <i className={item?.dropdownArrow} />
                    </NavLink>
                  </NavItem>
                  {item.hasDropdown && showDropdown && (item.submenu.map((val, i) => {
                    return (
                      <NavItem key={i}>
                        <NavLink to={val?.titlePath} tag={NavLinkRRD} className="dropdown-box" active={location.pathname===i.path}>
                          <i className="ni ni-bold-right" />
                          {val?.title}
                        </NavLink>
                      </NavItem>
                    )
                  }))}
        </>
      )
    }
    });
  
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"

    >
      <Container fluid className="mt-5">
          <div className="sidebar-items-container">
          <Nav navbar>{createLinks(routes)}</Nav>
          </div>
      
      </Container>
    </Navbar>
  );
};

export default Sidebar;
