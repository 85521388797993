import React from 'react'
import ReporteCard from '../ReporteCard/ReporteCard'
import "../MangerFLow/MangerAttendanceFlow/MangerAttendanceFlow.css"
import {ReactComponent as ReporteeCardImg } from "../../../assets/img/CardImg/Circle.svg"
import {checkInStatus, checkOutStatus, formatTime} from "../../Services/UtilServices";



function ReporteeCard({reporteeHandler, attendeeData}) {

  return (<>
    <div className='row reporte-card-container align-items-center' onClick={() => reporteeHandler(attendeeData?.id)}>
        {attendeeData?.attendances?.map((item,index) => {
            return <div className='col-md-4'>
                <div className={'row ' + (index === 1 ? 'justify-content-around border-card-reportee' : (index === 2 ? 'justify-content-around' : ''))}>
                    {index === 0 && <div className='d-flex reporte-container'>
                        <ReporteeCardImg/>
                        <div >
                            <p className='m-0 reporte-container-heading'>{attendeeData?.name}</p>
                            <p className=' reporte-container-subheading'>{attendeeData?.department?.name} {attendeeData?.designation?.name} {attendeeData?.designation?.grade ? `-${attendeeData?.designation?.grade}-` : ''}{attendeeData?.empCode}</p>
                        </div>
                    </div>}
                    {index > 0 && <p className='m-0 reporte-card-text'>{index === 1 ? 'Yesterday' : 'Day Before'}</p>}
                    <div className={'d-flex justify-content-center w-50 ' + (index === 0 ? 'align-items-center border-card-reportee' : '')} style={{columnGap:"40px"}}>
                        <div className=''>
                            <p className='reporte-container-time m-0'>{attendeeData?.attendances[index]?.check_in_time ? formatTime(attendeeData?.attendances[index]?.check_in_time) : '-'}</p>
                            <p className='m-0 reporte-container-ontime'>{checkInStatus(attendeeData?.attendances[index])}</p>
                        </div>
                        <div className=''>
                            <p className='text-center m-0 reporte-container-time'>{attendeeData?.attendances[index]?.check_out_time ? formatTime(attendeeData?.attendances[index]?.check_out_time) : '-'}</p>
                            <p className='m-0 reporte-container-ontime'>{checkOutStatus(attendeeData?.attendances[index])}</p>
                        </div>
                    </div>
                </div>
            </div>
        })}

    </div>
  </>)
}

export default ReporteeCard
