import React, { useEffect, useState } from "react";
import { Card, Container, Row } from "reactstrap";
import Button from 'react-bootstrap/Button';
import { getTodayUserAttendance } from "../../../Services/CommonServices";
import { Table, Modal } from "reactstrap"
import { convertUTCtoISTConverter } from "../../../hooks/convertUTCtoISTConverter";

const TodayUserAttendanceInfo = (props)  => {
    const [attendanceData, setAttendanceData] = useState([]);

    useEffect(() => {
        getTodayUserAttendance().then(reply => {
            setAttendanceData(reply?.data?.data);
        }).catch(error => {
            console.log("errror", error);        
        })
    }, []);

    return (
        <>
        <Container fluid >
            <Row>
                <Card className="col-11 card table-responsive">
                    <Table className="align-items-center">
                        <thead className="thead-light">
                            <tr>
                                <th>Employee Name</th>
                                <th>Check In Time</th>
                                <th>Check Out Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceData?.map((items, index) => (
                                <tr key={index}>
                                    <td>{items.userName}</td>
                                    <td>{convertUTCtoISTConverter(items.firstCheckInTime)}</td>
                                    <td>{items?.lastCheckOutTime ? convertUTCtoISTConverter(items?.lastCheckOutTime) : "NA"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Row>
            </Container>
        </>
    )
}

export default React.memo(TodayUserAttendanceInfo);