import React, { useState, useContext, useRef, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { newOrganisation, emailVerify, sendOtp } from "../../Services/CommonServices";
import { AppContext } from "../../../AppContext";
import { MuiOtpInput } from 'mui-one-time-password-input';
import RegisterImg from "../../../assets/img/brand/reigisterImg.webp"
import "../Login/Login.css";
import "./RegisterOrganisation.css"
import { toastError, toastSuccess } from '../../Shared/ToastMsgs';
import Crousel from '../../Shared/Crousel';
import ResendOtpComponent from './ResendOtpComponent';
import LogoNavbar from "../Login/LogoNavbar"

function RegisterOrganisation() {
    const [formData, setFormData] = useState({
        organisationName: "",
        website: "",
        typeOfOrganisation: "",
        primaryAddress: {
            addressLine1: "",
            addressLine2: "",
        },
        userDetails: {
            name: "",
            email: "",
            phoneNumber: "",
        },
        logo: null,
        contactPerson: "",
        contactNumber: "",
        otp: ""
    });

   
    const [validationErrors, setValidationErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [isEmailVerify, setIsEmailVerify] = useState(false);
    const [otp, setOtp] = useState('');
    const [isVerified, setVerified] = useState(false);
    const { setAppContext } = useContext(AppContext);
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isTimeStart, setTimeStart] = useState(false)

    const handleInputChange = (e) => {
        
        const { name, value } = e.target;
        if (name === "logo") {
            const file = e.target.files[0]?.name;
            setFormData((prevFormData) => ({
                ...prevFormData,
                logo: file,
            }));
        } else if (name === "name" || name === "email" || name === "phoneNumber") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                userDetails: {
                    ...prevFormData.userDetails,
                    [name]: value,
                },
            }));
        } else if (name === "addressLine1" || name === "addressLine2") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                primaryAddress: {
                    ...prevFormData.primaryAddress,
                    [name]: value,
                },
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    
        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(value);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                userDetails: {
                    ...prevErrors.userDetails,
                    email: isValidEmail ? "" : "Invalid email format",
                },
            }));
        }
      
    
        if (name === "phoneNumber") {
            const phoneRegex = /^[0-9]{10}$/; 
            const isValidPhoneNumber = phoneRegex.test(value);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                userDetails: {
                    ...prevErrors.userDetails,
                    phoneNumber: isValidPhoneNumber ? "" : "Invalid phone number format",
                },
            }));
        }
    
        if (name === "organisationName") {
            const nameRegex = /^\S.*\S$/; 
            const isValidName = nameRegex.test(value);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                organisationName: isValidName ? "" : "Invalid name format",
            }));
        }

        if (name === "addressLine1" || name === "addressLine2") {
            const addressRegex = /^(\S+\s+\S+.*)|(\S+)$/; // At least one character before and after any spaces
            const isValidAddress = addressRegex.test(value);
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                primaryAddress: {
                    ...prevErrors.primaryAddress,
                    [name]: isValidAddress ? "" : "Invalid address format",
                },
            }));
        }
    };
    

    const handleOrganisationSubmit = (e) => {
        e.preventDefault();
        const errors = {};
        if (!formData?.organisationName) errors.organisationName = "Organisation Name is required";
        if (!formData?.userDetails?.name) errors.name = "Name is required";
        if (!formData?.userDetails?.email) errors.email = "Email is required";
        if (!formData?.primaryAddress?.addressLine1) errors.addressLine1 = "Address Line 1 is required";
        if (!formData?.userDetails?.phoneNumber) {
            errors.userDetails = errors.userDetails || {};
            errors.userDetails.phoneNumber = "Phone number is required";
        } else if (formData.userDetails.phoneNumber.length !== 10) {
            errors.userDetails = errors.userDetails || {};
            errors.userDetails.phoneNumber = "Phone number must be 10 digits";
        }
        if (!formData?.typeOfOrganisation) errors.typeOfOrganisation = "Type of Organisation is required";

        if (!formData?.otp) errors.otp = "OTP is required";

        setValidationErrors(errors);


        if (Object.keys(errors).length === 0) {
            const data = {
                user_details: {
                    name: formData?.userDetails?.name,
                    email: formData?.userDetails?.email,
                    phone_number: formData?.userDetails?.phoneNumber
                },
                org_name: formData?.organisationName,
                full_address: formData?.primaryAddress.addressLine1 + ' ' + formData?.primaryAddress.addressLine2,
                org_logo: formData?.logo,
                type_of_organisation: formData?.typeOfOrganisation,
                contact_person: formData?.contactPerson,
                contact_number: formData?.contactNumber,

            };

            newOrganisation(data)
                .then(response => {
                    if (response) {
                        toastSuccess(response?.data?.message);
                        setFormData({
                            organisationName: "",
                            website: "",
                            typeOfOrganisation: "",
                            primaryAddress: {
                                addressLine1: "",
                                addressLine2: "",
                            },
                            userDetails: {
                                name: "",
                                email: "",
                                phoneNumber: "",
                            },
                            logo: null, // Set logo to null
                            contactPerson: "",
                            contactNumber: "",
                            otp: ""
                        });
                        if (fileInputRef.current) {
                            fileInputRef.current.value = ""; 
                        }
                        setVerified(false);
                    } else {
                        toastError(response?.data?.message);
                    }
                })
                .catch(err => toastError(err?.response?.data?.message));
        }
    };
    const [isResendOtp, setResendOtp] = useState(false)

    const emailVerifyHandler = (e) => {
        
        e.preventDefault()
        const data = {
            email: formData?.userDetails?.email,
            org_name: formData?.organisationName
        };
        sendOtp(data)
            .then(response => {
                if (response?.status === 200) {
                    toastSuccess(response?.data?.message);
                    setIsEmailVerify(true);
                    setTimeStart(true);
                } else {
                    toastError("Failed to verify email.");
                    
                }
            })
            .catch(err => toastError(err?.response?.data?.message)).finally(() => setIsLoading(false));

    };
    

    const handleChange = (newOtp) => {
        setOtp(newOtp);
    };

    useEffect(() => {
        if (otp.length === 4) {
            const data = {
                email: formData.userDetails.email,
                otp: otp
            };


            emailVerify(data).then(response => {
                if (response) {
                    setOtp('');
                    toastSuccess("Otp Verified Successfully")
                    setIsEmailVerify(false);
                    setVerified(true);
                    setTimeStart(false)
                    // setDisable(true);

                } else {
                    console.log("email is already exist");
                }
            }).catch(error => {
                toastError("Verification failed. Please try again.");
                toastError("Verification failed. Please try again.");
            })
        }
    }, [otp]);

    return (
        <div>
            <div className='w-100 d-flex '>
                <div className='img-width'>
                    <Crousel />
                </div>
                <Container className='register-container'>
                    <div className="login-container">
                        <Form className="login-form">
                            <p className='org-reg-heading'>Organisation Registration</p>
                            <Form.Group controlId="organisationName">
                                <Form.Label className='org-reg-subheading'>Organisation <span>*</span></Form.Label>
                                <Form.Control
                                    placeholder='jarvis'
                                    type="text"
                                    name="organisationName"
                                    value={formData.organisationName}
                                    onChange={handleInputChange}
                                    className='org-reg-input'
                                    isInvalid={!!validationErrors.organisationName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.organisationName}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="website">
                                <Form.Label className='org-reg-subheading'>Website </Form.Label>
                                <Form.Control
                                    placeholder='Bszfdxch@.com'
                                    type="text"
                                    name="website"
                                    value={formData.website}
                                    onChange={handleInputChange}
                                    className='org-reg-input'
                                    isInvalid={!!validationErrors.website}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.website}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group >
                                <Form.Label className='org-reg-subheading'> Logo</Form.Label>
                                <Form.Control
                                    placeholder='Upload Logo'
                                    type="file"
                                    name="logo"
                                    //  style={{ display: 'none' }}
                                    className='cursor-pointer'
                                    ref={fileInputRef}
                                    onClick={handleInputChange}
                                />
                            </Form.Group>



                            <Form.Group controlId="typeOfOrganisation">
                                <Form.Label className='org-reg-subheading'>Type of Organisation <span>*</span></Form.Label>
                                <Form.Control
                                    placeholder='Organisation Type'
                                    type="text"
                                    name="typeOfOrganisation"
                                    value={formData.typeOfOrganisation}
                                    onChange={handleInputChange}
                                    className='org-reg-input'
                                    isInvalid={!!validationErrors.typeOfOrganisation}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.typeOfOrganisation}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="addressLine1">
                                <Form.Label className='org-reg-subheading'>Primary Address </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Address Line1'
                                    name="addressLine1"
                                    value={formData.primaryAddress.addressLine1}
                                    onChange={handleInputChange}
                                    className='org-reg-input'
                                    isInvalid={!!validationErrors.primaryAddress?.addressLine1}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.primaryAddress?.addressLine1}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="addressLine2">
                                <Form.Control
                                    placeholder='Address Line2'
                                    type="text"
                                    name="addressLine2"
                                    value={formData.primaryAddress.addressLine2}
                                    onChange={handleInputChange}
                                    className='org-reg-input'
                                    isInvalid={!!validationErrors.primaryAddress?.addressLine2}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.primaryAddress?.addressLine2}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="contactname">
                                <Form.Label className='org-reg-subheading'>Contact Name <span>*</span></Form.Label>
                                <Form.Control
                                    placeholder='John'
                                    type="text"
                                    name="name"
                                    value={formData.userDetails.name}
                                    onChange={handleInputChange}
                                    className='org-reg-input'
                                    isInvalid={!!validationErrors.userDetails?.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.userDetails?.name}
                                </Form.Control.Feedback>
                            </Form.Group>


                            <Form.Group controlId="phoneNumber">
                                <Form.Label className='org-reg-subheading'>Contact Number <span>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='9876543210'
                                    name="phoneNumber"
                                    value={formData.userDetails.phoneNumber}
                                    onChange={handleInputChange}
                                    className='org-reg-input'
                                    minLength={10}
                                    maxLength={10}
                                    isInvalid={!!validationErrors.userDetails?.phoneNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.userDetails?.phoneNumber}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="email position-relative">
                                <Form.Label className='org-reg-subheading'>Contact Email <span>*</span></Form.Label>
                                <div className='email-input-field'>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.userDetails.email}
                                        onChange={handleInputChange}
                                        className='org-reg-input border-0 email-input'
                                        isInvalid={!!validationErrors.userDetails?.email}
                                    />
                                    <button
                                        onClick={emailVerifyHandler}
                                        className={!isVerified ? "text-end send-otp-btn send-otp" : "text-green send-otp-btn send-otp "}
                                       
                                        disabled={validationErrors.userDetails?.email || formData.userDetails?.email === "" || isEmailVerify}
                                    >
                                        {isVerified ? "Verified" : isResendOtp ? "Resend OTP" : "Get OTP"}
                                    </button>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.userDetails?.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {isLoading && <Spinner />}
                            {isEmailVerify && <div className='otp'>
                                <MuiOtpInput className='otp-box' value={otp} length={4} onChange={handleChange} />
                                <ResendOtpComponent
                                    timeStart={isTimeStart}
                                    setResendOtp={setResendOtp}
                                    resendOtp={isResendOtp}
                                    emailVerifyHandler={emailVerifyHandler}
                                />
                            </div>}

                            <div className='d-flex flex-column'>
                                <div className='d-flex paperWork-container '>
                                    <input type="checkbox" name="" id="" />
                                    <p className='paperWork' >I agree to receive emails from AttendEase about relevant content, products and services. I understand I can manage my communication preferences or <span className='blue-color'>unsubscribe</span> at any time</p></div>
                                <div>
                                    <p className='paperWork paperWork-padding'>Please refer to our <span className='blue-color'> Privacy policy</span> or <span className='blue-color'>Contact Us</span> for more details.</p>
                                </div>
                            </div>
                            <Button onClick={handleOrganisationSubmit} className='register-btn' >
                                Register
                            </Button>
                        </Form>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default RegisterOrganisation;
