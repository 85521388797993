import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import "./EventCard.css";
// import logo from "assets/img/brand/logo.png";
// import EventCardImg from "assets/img/theme/EventCardImg.jpg"
import { BiLike, BiCommentDots } from "react-icons/bi"
import { Row } from "reactstrap";

function EventCard() {
    const [likesCount, setLikesCount] = useState(0)
    const [commentsCount, setCommenstCount] = useState(0)
    const [commentActive, setCommentActive] = useState(false)
    function handleComments() {
        setCommentActive(!commentActive);
    }
    return (
        <Row>
        <Card style={{ width: '40%' }} className="event-card col-5">
            <Card.Body>
                <div className="card-title-bar">
                    <Card.Title>
                        {/* <img src={logo} className="event-card-logo" /> */}
                        <h6 style={{ fontSize: '12px', color: "grey" }}>Group:Events</h6>
                    </Card.Title>
                    <h6 style={{ fontSize: '14px', color: "grey" }}>{new Date().toLocaleDateString()}</h6>
                </div>
                <div className="greeting-box">
                    {/* <img src={EventCardImg} className="EventCardImg" /> */}
                    <div>
                        <h6 style={{ fontSize: '14px', color: "grey" }}>Happy Birthday NAME, Have a great year ahead</h6>
                        <h6 style={{ fontSize: '16px' }}>HAPPY BIRTHDAY NAME</h6>
                    </div>
                </div>
                <div className="like-comment d-flex">
                    <div className="d-flex">
                        <BiLike className="event-card-icon"
                            onClick={() => setLikesCount(prevCount => prevCount + 1)} />
                        <h6 style={{ fontSize: '12px', color: "grey" }}>{`${likesCount} likes`}</h6>
                    </div>
                    <div className="d-flex">
                        <BiCommentDots className="event-card-icon" onClick={handleComments} />
                        <h6 style={{ fontSize: '12px', color: "grey" }}>{`${commentsCount} comments`}</h6>
                    </div>
                </div>
                {commentActive && <input type="text"
                    placeholder="Post your comment here..."
                    className="comment-box" />}
            </Card.Body>
        </Card>
        </Row>
    );
}

export default EventCard;