// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main-content{
    z-index:0;


}
.pg{
    position: absolute;
    width: 100%;
    top: 5rem;

}

/* .pg{
    position:absolute;
    top:40%;
    width:100%;
} */
body{
    overflow-x: hidden;
    /* background-color: #F7F7F7 !important ; */
}
.bg-gradient-info {
    background: linear-gradient(87deg, #F6A06B 0, #ef7d36 100%) !important;
  }
  .card{
    padding:0;
    display: flex;
    flex-wrap: wrap !important; 
    height:100%;
    margin-left: auto;
    margin-right: auto;

}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

@media (max-width: 600px) {
    .loader {
        top: 40%;
        left: 35%;
    }
}

.loader-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    z-index: 9998;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.clickable {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;IACI,SAAS;;;AAGb;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;;AAEb;;AAEA;;;;GAIG;AACH;IACI,kBAAkB;IAClB,2CAA2C;AAC/C;AACA;IACI,sEAAsE;EACxE;EACA;IACE,SAAS;IACT,aAAa;IACb,0BAA0B;IAC1B,WAAW;IACX,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,kCAAkC;IAClC,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI;QACI,QAAQ;QACR,SAAS;IACb;AACJ;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,kCAA0B;YAA1B,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\n.main-content{\n    z-index:0;\n\n\n}\n.pg{\n    position: absolute;\n    width: 100%;\n    top: 5rem;\n\n}\n\n/* .pg{\n    position:absolute;\n    top:40%;\n    width:100%;\n} */\nbody{\n    overflow-x: hidden;\n    /* background-color: #F7F7F7 !important ; */\n}\n.bg-gradient-info {\n    background: linear-gradient(87deg, #F6A06B 0, #ef7d36 100%) !important;\n  }\n  .card{\n    padding:0;\n    display: flex;\n    flex-wrap: wrap !important; \n    height:100%;\n    margin-left: auto;\n    margin-right: auto;\n\n}\n\n.loader {\n    border: 16px solid #f3f3f3;\n    border-top: 16px solid #3498db;\n    border-radius: 50%;\n    width: 120px;\n    height: 120px;\n    animation: spin 2s linear infinite;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 9999;\n}\n\n@media (max-width: 600px) {\n    .loader {\n        top: 40%;\n        left: 35%;\n    }\n}\n\n.loader-background {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(255, 255, 255, 0.8);\n    backdrop-filter: blur(5px);\n    z-index: 9998;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n\n.clickable {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
