import React from 'react';

export const convertUTCtoISTConverter = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    const istDateTimeString = utcDateTime.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    return istDateTimeString;
}

export const useConvertUTCtoISTTime = (utcDateTimeString) => {
  const convertTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    const istDateTimeString = utcDateTime.toLocaleTimeString("en-IN", {
      timeZone: "Asia/Kolkata",
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return istDateTimeString;
  };

  return convertTime(utcDateTimeString);
};