import React, { useContext, useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, Alert } from '@mui/material';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

import "./ManageUserEmpFlow.css"
import { getManagers, postManagers } from '../../Services/CommonServices';
import { AppContext } from '../../../AppContext';
import { toastError } from '../ToastMsgs';

const AssignManagerModal = ({ open, handleClose ,isAllDepartment }) => {
  const {value}=useContext(AppContext)
  const [departmentId, setDartmentId] = React.useState({});
  const [isDepartmentManager,setDepartmentManager]=useState([])
  const [isManagerName,setManagerName]=useState([])
  const [isReason,setReason]=useState("")

 const {managers} = JSON.parse(localStorage?.getItem("user_data")) 
  console.log("value?.managers?.[0]?.managerId" , managers)
  const   handleChange = (event) => {
    setDartmentId(event.target.value);
    setManagerName("")
  };

  function updateManagerLocally(managerData) {
    const localUserData = JSON.parse(localStorage.getItem("user_data"));
    localUserData.managers = [managerData];
    localStorage.setItem("user_data", JSON.stringify(localUserData));
  }

  const submitHandler=(id,reasons)=>{
    const data = {
      managerId:id ,
      oldManagerId: managers?.[0]?.managerId ?? "",
      reason:reasons
    }
  postManagers(data).then((response)=>{
    if(response){
      handleClose()
      updateManagerLocally(response?.data?.data)
    }
  }).catch(err=>toastError(err?.message))
}

const handleReasonChange = (event) => {
    setReason(event.target.value)
  };

  const handleChangeManger=(e)=>{
    setManagerName(e.target.value)
  }

  useEffect(()=>{
    const params = {departmentId}
  
    if(params){

      getManagers(params).then((response)=>{
        setDepartmentManager(response?.data?.data)
      })
    }
  },[departmentId])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          border: "none",
          boxShadow: 24,
          borderRadius: "12px",
          padding:"40px 32px",
        }}
      >
        <p className='assign-manager-text'> Assign New Manager </p>

        <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
          Fill the details. This will be sent for approval to the manager/HR and if approved by them, then a new manager will be assigned.
        </Alert>

        <div className='d-flex flex-column modal-container' style={{ rowGap: "27px" }}>
          <div>
            <p className='m-0 label-modal-text'>Department <span>*</span></p>
            <Box  className='modal-dropdown' sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <Select
             className='modal-dropdown' 
                  id="demo-simple-select"
                  value={departmentId}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem disabled={true} id="demo-simple-select" value="">
                    <em className='disable-text'>Select</em>
                  </MenuItem>
                  {  isAllDepartment?.map((item,index)=>{
                    return <MenuItem value={item.id}>{item?.name}</MenuItem>
                })}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div>

            <p className='m-0 label-modal-text'>Manager Name <span>*</span></p>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <Select
                className='modal-dropdown'
                  id="demo-simple-select"
                  value={isManagerName}
                  onChange={handleChangeManger}
                  displayEmpty
                >
                  <MenuItem disabled={true} id="demo-simple-select" value="">
                    <em className='disable-text'>Select</em>
                  </MenuItem>
                {  isDepartmentManager?.map((item,index)=>{
                    return <MenuItem value={item}>{item?.name}</MenuItem>
                })}
  
                </Select>
              </FormControl>
            </Box>
          </div>
          <TextField
            label="Reason"
            multiline   
            rows={4}
            variant="outlined"
            fullWidth
            // value={}
            onChange={handleReasonChange}
            sx={{ mb: 2, borderRadius:"11px" }}  
          />
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'center', columnGap:"18px"  }}>
          <button  className='btn-padding done-bg ' onClick={()=>submitHandler(isManagerName.id,isReason)}  >
            Done
          </button>
          <button className='btn-padding cancel-bg' onClick={handleClose}>
            Cancel
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignManagerModal;
