// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-text-mob{
    font-family: "Quicksand";
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: center;
color: #000000;
}

.blinking-icon {
    animation: blink-animation 1.2s infinite;
  }
  
  @keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .successScreen-container{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  
  .successScreen-btn{
font-family: "Roboto";
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.10000000149011612px;
text-align: center;
background-color: #7647EB;
color: white;
padding: 7px 15px;
cursor: pointer;
border-radius: 42px;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Shared/SuccessScreen/SuccessScreen.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,kBAAkB;AAClB,cAAc;AACd;;AAEA;IACI,wCAAwC;EAC1C;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,aAAa;EACf;;EAEA;AACF,qBAAqB;AACrB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,qCAAqC;AACrC,kBAAkB;AAClB,yBAAyB;AACzB,YAAY;AACZ,iBAAiB;AACjB,eAAe;AACf,mBAAmB;EACjB","sourcesContent":[".modal-text-mob{\n    font-family: \"Quicksand\";\nfont-size: 16px;\nfont-weight: 600;\nline-height: 24px;\ntext-align: center;\ncolor: #000000;\n}\n\n.blinking-icon {\n    animation: blink-animation 1.2s infinite;\n  }\n  \n  @keyframes blink-animation {\n    0% {\n      opacity: 1;\n    }\n    50% {\n      opacity: 0;\n    }\n    100% {\n      opacity: 1;\n    }\n  }\n  .successScreen-container{\n    display: flex;\n    flex-direction: column;\n    row-gap: 24px;\n  }\n  \n  .successScreen-btn{\nfont-family: \"Roboto\";\nfont-size: 14px;\nfont-weight: 500;\nline-height: 20px;\nletter-spacing: 0.10000000149011612px;\ntext-align: center;\nbackground-color: #7647EB;\ncolor: white;\npadding: 7px 15px;\ncursor: pointer;\nborder-radius: 42px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
