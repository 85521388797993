import React, { useEffect, useState } from "react";
import "./AttendanceInfo.css";
import { MdOutlineDateRange } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import { attendanceInfoFunction } from "../../../Services/CommonServices";
import { Card, Container, Row } from "reactstrap";
import { Table } from "reactstrap";
import { toastError, toastSuccess } from "../../../Shared/ToastMsgs";
import { DatePicker } from "react-rainbow-components";
import { ReactComponent as SearchIcon } from "../../../../assets/img/CardImg/searchButton.svg"
import DatePickerAntd from "./DatePickerAntd";
import TableComponent from "../../../Shared/TableComponent/TableComponent";
import AttendanceAdminHeader from "../../../Shared/AdminFlow/AttendanceAdminHeader";
import MangerAttendanceFlow from "../../../Shared/MangerFLow/MangerAttendanceFlow/MangerAttendanceFlow";

function AttendanceInfo() {
  const [startDate, setStartDate] = useState("2023-12-19T10:35:27.508Z");
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const start = startDate;
  const end = endDate;
  const user = JSON.parse(
    (localStorage.getItem("user_data") || "{}") == "undefined"
      ? "{}"
      : localStorage.getItem("user_data") || "{}"
  );
  const [info, setInfo] = useState([]);

  useEffect(() => {
    if (!user || !user?._id) {
      return;
    }
    getAttendanceInfoFunction();
  }, []);

  const getAttendanceInfoFunction = () => {
    attendanceInfoFunction(
      `?userId=${user?._id
      }&offset=${0}&limit=${10}&start_date=${start}&end_date=${end}`
    )
      .then((reply) => {
        if (reply?.status === 200) {
          toastSuccess();
          setInfo(reply.data.data);
        } else {
          toastError();
        }
      })
      .catch((err) => { });
  }

  // function to convert date time format "2023-11-20T12:12:30.242Z" to "Nov 27 2023 14:19:56 GMT+0530 (India Standard Time)"\
  const dateTimeFormat = (date_time) => {
    if (date_time) {
      const formattedTime = new Date(date_time).toLocaleTimeString();
      const formattedDate = new Date(date_time).toDateString();
      const formattedDateTime = formattedDate.concat(", " + formattedTime);
      return formattedDateTime;
    }
  };

  const dateFilter = () => {
    getAttendanceInfoFunction()
  }

  return (
    <>
      <Container fluid className="pt-3- Attendane-container ">
      {/* <AttendanceAdminHeader dateFilter={dateFilter}/> */}
        {/* <Row className="attendance-table-row">
          <TableComponent/>
        </Row>  */}
        <MangerAttendanceFlow/>
      </Container>
    </>
  );
}

export default AttendanceInfo;