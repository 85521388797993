
import React, { useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from "../../AppContext";

export const toastSuccess = (message) => toast.success(message||'successful');
export const toastError = (message) => toast.error(message||'error occurred');


export default function ToastMsgs() {
    const {isMobView}=useContext(AppContext)
    return (
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
    )
}
