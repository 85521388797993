import React from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
import LoginIcon from "../../../../assets/img/login_in.svg";
import LogoutIcon from "../../../../assets/img/login_out.svg";
import "./Regularization.css"
import {postApprovalRegularizationFunction, postRejectRegularizationFunction} from "../../../Services/CommonServices";

const RegularizationModal = ({  dataToBeVerified,setDataToBeVerified,title}) => {


    const handleApproval=()=>{
        postApprovalRegularizationFunction({regularizationId:dataToBeVerified?.id}).then(reply=>{
            console.log(reply)
        }).catch(error=>{
            console.log("error",error)
        })
    }
    const handleReject=()=>{}
        postRejectRegularizationFunction({regularizationId:dataToBeVerified?.id}).then(reply=>{
        console.log(reply)
    }).catch(error=>{
        console.log("error",error)
    })
  return (
    <Modal
      open={Boolean(dataToBeVerified)}
      onClose={()=>{setDataToBeVerified(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ onClick: () => setDataToBeVerified(false) }}
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius:"14px",
        p: 4,
      }}>
        <div className='modal-web-border' style={{padding:"10px 0px"}}> 
            <p className='applied-by m-0'>Applied By</p>
            <p className='applied-by-name' >{dataToBeVerified?.user?.name}</p>
        </div>
        <div className='modal-web-border' style={{padding:"10px 0px"}}> 
            <p className='applied-by m-0'>Applied Date(s)</p>
            <p className='applied-by-name' >{new Date(dataToBeVerified?.created_at).toLocaleDateString('en-US', {
                timeZone: 'UTC',
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            })}</p>
        </div>
                <div  >
                       <p style={{ fontSize: "10px", color: "#7647EB" }}> Regular shift 09:30am to 06:30pm{" "}</p>
                      </div>
                      <p className='m-0 reason-heading'>Captured Time</p>
        <div className='d-flex justify-content-between modal-web-border' style={{padding:"10px 0px"}}>
            <div className='d-flex align-items-start' style={{columnGap: "12px"}}>
                <img src={LoginIcon} alt="" />
             <div > 
                  <p className='applied-by-name orange-clr  m-0'>10:00 am</p>
                <p className='applied-by ' >Actual Time</p></div>
            </div>
            <div className='d-flex align-items-start' style={{columnGap: "12px"}}>
                <img src={LogoutIcon} alt="" />
                <div>
                <p className='applied-by-name orange-clr m-0'>06:30 pm</p>
                <p className='applied-by '>On Time</p>
                </div>
            </div>
            <div>
            </div>
        </div>
        <p className='m-0 reason-heading'>Requested Time</p>
        <div className='d-flex justify-content-between modal-web-border' style={{padding:"10px 0px"}}>

            <div className='d-flex align-items-start' style={{columnGap: "12px"}}>
                <img src={LoginIcon} alt="" />
             <div > 
                  <p className='applied-by-name m-0'>10:00 am</p>
                <p className='applied-by ' >Updated</p></div>
            </div>
            <div className='d-flex align-items-start' style={{columnGap: "12px"}}>
                <img src={LogoutIcon} alt="" />
                <div>
                <p className='applied-by-name m-0'>06:30 pm</p>
                <p className='applied-by '>On Time</p>
                </div>
            </div>
            <div>
            </div>
        </div>
      <div style={{padding:"10px 0px"}}>
          <p className='m-0 reason-heading'>Reason</p>
        <p className='reason-border-style'>{dataToBeVerified?.leaveReason?.name}</p>
               </div>
     
     <div className='d-flex  justify-content-end' >
         {title === "Reject" && <button className='reject-btn' sx={{mt: 2}} onClick={()=>{handleReject()}}>
             Reject
         </button>}

         {title === "Approve" &&<button className='approve-btn' sx={{mt: 2}}  onClick={()=>{handleApproval()}}>
             Approve
         </button>}

     </div>
      </Box>
    </Modal>
  );
};

export default RegularizationModal;
