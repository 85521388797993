// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-picker-suffix{
    display: none !important;
}
.ant-picker-input{
    width: 75px !important;
}
.ant-picker{
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Attendance/AttendanceInfo/DatePicker.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,YAAY;AAChB","sourcesContent":[".ant-picker-suffix{\n    display: none !important;\n}\n.ant-picker-input{\n    width: 75px !important;\n}\n.ant-picker{\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
