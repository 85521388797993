import React, { useEffect, useState } from "react";
import { Accordion, Modal, Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { getPermissionsFunction, userUpdateFunction } from "../../../Services/CommonServices";
import { toastError, toastSuccess } from "../../../Shared/ToastMsgs";
import "./UpdatePermissions.css";
import { Col } from "reactstrap";

export default function UpdatePermissions(props) {
    const { UpdatePermissions, setUpdatePermissions, user, getUser } = { ...props };
    const [permission, setPermission] = useState([]);
    const [permissionsIdArray, setPermissionsIdArray] = useState(user?.permissions || []);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPermissions();
    }, []);

    const getPermissions = () => {
        setLoading(true);
        getPermissionsFunction().then(reply => {
            setLoading(false);
            if (reply.status === 200) {
                setPermission(reply.data.data);
                toastSuccess();
            } else {
                toastError();
            }
        }).catch(error => {
            console.log(error)
            setLoading(false);
            toastError(error);
        });
    };

    const updateUser = () => {
        userUpdateFunction(`${user?._id}`, { permissions: permissionsIdArray }).then(reply => {
            if (reply.status === 200) {
                toastSuccess('Permissions updated successfully');
                getUser();
            } else {
                toastError('Failed to update permissions');
            }
        }).catch(error => {
            toastError('Failed to update permissions');
        });
    };

    const handleChange = (id, e) => {
        let updatedPermissionsIdArray;
        if (e.target.checked) {
            updatedPermissionsIdArray = [...permissionsIdArray, id];
        } else {
            updatedPermissionsIdArray = permissionsIdArray.filter(item => item !== id);
        }
        setPermissionsIdArray(updatedPermissionsIdArray);
        console.log('Updated permissions ID array:', updatedPermissionsIdArray);
    };

    const handleSubmit = () => {
        updateUser();
        setUpdatePermissions(false);
    };

    return (
        <Modal show={true} onHide={() => setUpdatePermissions(false)}>
            <Modal.Header>
                <Modal.Title>Update permissions</Modal.Title>
                <button type="button" className="ni ni-fat-remove close-Btn" onClick={() => setUpdatePermissions(false)} ></button>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="loader-container">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    </div>
                ) : (
                    <Col className="accordion-box col-11">
                        {permission?.map((item, index) => (
                            <Accordion key={index}>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header className="permission-accordion-button">
                                        <div className="d-flex">
                                            <i className="ni ni-books text-gray"></i>
                                            <h4 className="permission-name">{item._id}</h4>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {item?.documents?.map((permissionType, permissionIndex) => (
                                            <div className="row accordian-item-row" key={permissionIndex}>
                                                <div className="col accordian-content-col">
                                                    <h3>{permissionType.type} :</h3>
                                                    <label className="d-flex pl-2" htmlFor={permissionType._id}>
                                                        <span className="mr-2">{permissionType.action}</span>
                                                        <input
                                                            id={permissionType._id}
                                                            type="checkbox"
                                                            name={permissionType.action}
                                                            defaultChecked={permissionsIdArray.includes(permissionType._id)}
                                                            onChange={(e) => handleChange(permissionType._id, e)}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        ))}
                    </Col>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setUpdatePermissions(false)}>
                    Close
                </Button>
                <Button variant="secondary" onClick={handleSubmit}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
