
import { Link, useLocation, useNavigate } from "react-router-dom";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import routes from "../../../routes"
import { logOut } from "../../Services/CommonServices";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";

const AdminNavbar = () => {
  const user=JSON.parse((localStorage.getItem("user_data")|| "{}") == 'undefined' ? '{}' : (localStorage.getItem("user_data")|| "{}"));
  const location = useLocation();
  const history = useNavigate();
  const { value,setValue } = useContext(AppContext);

  let navbarTitle;
  routes.map((items) => {
    if (items.path === location.pathname) {
        return (
            navbarTitle = items.name
        )
    }
    items.submenu.map((j) => {
        if (j.titlePath === location.pathname) {
            return (
                navbarTitle = j.title
            )
        }
    })

    
})

const handleLogout = () => { 
    logOut().then(reply => {
      setValue(null);
      localStorage.clear();
      history("/");
    },err => {
      console.log("error while logging out", err);
    });
}
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {navbarTitle}
          </Link>
          
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <i  className="ni ni-circle-08"/>
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user?.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem  onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;

