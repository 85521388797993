
import React, { useEffect, useState } from 'react';
import './ResendPasswordPage.css';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailBoxImage from '../../../assets/img/icons8-gmail.svg';
import { forgetResendPassword } from '../../Services/CommonServices';


function ResendPasswordPage() {
    const location = useLocation();
    const email = location.state?.email;
    const [isResending, setIsResending] = useState(false);
    const [enableResend, setEnableResend] = useState(false);
    const [countdown, setCountdown] = useState(15);

    const handleResendEmail = async () => {
        setIsResending(true);
        try {
            await forgetResendPassword({ email });
            toast.success('Email resent successfully!');
        } catch (error) {
            console.error('Error resending email:', error);
            toast.success('An error occurred while resending the email.');
        } finally {
            setIsResending(false);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 1) {
                    clearInterval(timer);
                    setEnableResend(true);
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <>
         <div className="gmail-img"><img src={EmailBoxImage} alt="Email" className="email-icon" /></div>
        <div className="password-reset">
            <h1 className="reset-heading">Check Your Email</h1>
            <p className="reset-message">
                Please check the email address {email} for instructions to reset your password.
            </p>
            <p>Resend email in: {countdown} seconds</p>
            <button
                className="resend-btn"
                onClick={handleResendEmail}
                disabled={isResending || !enableResend}
            >
                {isResending ? 'Resending...' : 'Resend email'}
            </button>
        </div>
        </>
    );
}

export default ResendPasswordPage;
