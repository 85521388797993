import React, { useState, useEffect, useContext } from "react";
import "./Department.css";
import {
  deleteDepartmentFunction,
  getDepartmentFuntion,
  updateDepartmentFunction,
  createDepartmentFunction,
} from "../../Services/CommonServices";
import CreateUpdateDepartment from "./CreateUpdateDepartment/CreateUpdateDepartment";
import { toastError, toastSuccess } from "../../Shared/ToastMsgs";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import PageComponent from "../../Shared/PageComponent/PageComponent";
import DepartmentTable from "./DepartmentTable";

export default function Department() {
  const [deleteId, setdeleteId] = useState();
  const [updateData, setUpdateData] = useState();
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    getDepartmentData();
  }, []);

  function deleteDepartment() {
    deleteDepartmentFunction(`${deleteId}`).then((reply) => {
      if (reply?.status === 200) {
        toastSuccess();
        getDepartmentData();
        setdeleteId(null);
      } else {
        toastError();
      }
    });
  }

  const createUpdateData = (data) => {
    data && data._id ? updateDepartment(data) : addDepartment(data);
  };

  function updateDepartment(data) {
    updateDepartmentFunction(`${data._id}`, { name: data.name })
      .then((reply) => {
        if (reply?.status === 200) {
          toastSuccess();
          getDepartmentData();
        } else {
          toastError();
        }
      })
      .catch((error) => {});
  }

  function addDepartment(data) {
    createDepartmentFunction(data.name)
      .then((reply) => {
        getDepartmentData();
        toastSuccess("successfully added");
      })
      .catch((error) => {
        toastError(error);
      });
  }

  const getDepartmentData = () => {
    getDepartmentFuntion()
      .then((reply) => {
        if (reply?.status === 200) {
          setDepartment(reply.data.data);
          toastSuccess();
        } else {
          toastError();
        }
      })
      .catch((error) => {});
  };
  const handleDepartmentheadChange = () => {};
  const departmentTableProps = {
    department: department,
    setUpdateData: setUpdateData,
  };

  const departmentFormProps = {
    department: department,
    updateData: updateData,
    createUpdateData: createUpdateData,
  };
  return (
    <div>
      <PageHeader
        pageTitle="Departments"
        breadCrumbs={[
          {
            name: "Home",
            path: "/",
          },
          {
            name: "Departments",
            path: "/department",
          },
        ]}
      />
      <PageComponent
        headerTitle="Total Count"
        buttonText="Add Department"
        tableComponent={DepartmentTable}
        formComponent={CreateUpdateDepartment}
        tableProps={departmentTableProps}
        formProps={departmentFormProps}
      />

      {/* <div className=" d-flex heading-box">
        </div>
            {deleteId && <Modal show={deleteId} onHide={() => setdeleteId(null)}>
                <Modal.Header >
                    <Modal.Title>Delete Department</Modal.Title>
                    <button type="button" className="ni ni-fat-remove  close-Btn" onClick={()=>{setdeleteId(null)}} ></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        Are you sure you want to delete this department?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setdeleteId(null)}>
                        Close
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => deleteDepartment()}
                    >
                        delete
                    </Button>
                </Modal.Footer>
            </Modal>} */}
    </div>
  );
}
