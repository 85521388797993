import React, { useState, useEffect, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import {
  getDepartments,
  getManagers,
  postManagers,
} from "../../../Services/CommonServices";
import { AppContext } from "../../../../AppContext";

function DrawerBottom({
  handleCloseDrawer,
  drawerOpen,
  LeftArrow,
  Warn,
  recentManagerId,
}) {
  const { setOpenModal, setSuccessText } = useContext(AppContext);
  const [formValues, setFormValues] = useState({
    department: "",
    managerName: "",
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleClear = () => {
    setFormValues({
      department: "",
      managerName: "",
      reason: "",
    });
  };

  const handleSubmit = (e) => {
    const data = {
      managerId: parseInt(formValues?.managerName),
      reason: formValues?.reason,
      oldManagerId: recentManagerId,
    };
    e.preventDefault();
    postManagers(data)
      .then((res) => {
        setSuccessText(res?.data?.message);
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          handleCloseDrawer();
        }, 2000);
        updateManagerLocally(res.data.data);

        handleClear();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  function updateManagerLocally(managerData) {
    const localUserData = JSON.parse(localStorage.getItem("user_data"));
    localUserData.managers = [managerData];
    localStorage.setItem("user_data", JSON.stringify(localUserData));
  }

  const [departments, setDepartments] = useState([]);
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    if (drawerOpen) {
      getDepartments()
        .then((res) => {
          setDepartments(res?.data?.data);
        })
        .catch((err) => {
          console.log("err msg is ,", err);
        });
    }
  }, [drawerOpen]);

  useEffect(() => {
    const params = formValues?.department
      ? {
          departmentId: formValues?.department,
        }
      : {};
    // if (formValues?.department !== "") {
    getManagers(params)
      .then((res) => {
        setManagers(res?.data?.data);
        console.log("managers");
      })
      .catch((err) => {
        console.log("err is", err);
      });
    // }
  }, [formValues?.department]);

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          style: {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            padding: "16px",
            height: "520px",
          },
        }}
      >
        <div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div style={{ width: "20%", border: "2px solid #828282" }}></div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "2%",
              padding: "8px",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div type="button" onClick={handleCloseDrawer}>
              <img src={LeftArrow} alt="Left Arrow" />
            </div>
            <span
              style={{ fontSize: "18px", fontWeight: "500", color: "black" }}
            >
              Assign New Manager
            </span>
          </div>

          {/* <div className="warning-div">
            <div>
              <img src={Warn} style={{ paddingBottom: "8px" }} alt="Warning" />
            </div>
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#F89E3C" }}
            >
              Fill the details. This will be sent for approval to the manager/HR
              and if approved by them, then a new manager will be assigned.
            </span>
          </div> */}

          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              marginTop: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "50vh",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "40vh",
              }}
            >
              <div>
                <label htmlFor="department" className="profile-label">
                  Department*
                </label>
                <select
                  id="department"
                  name="department"
                  className="profile-input"
                  style={{ padding: "5px" }}
                  value={formValues.department}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {departments?.map((data) => (
                    <option value={data?.id}>{data?.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="managerName" className="profile-label">
                  Manager Name*
                </label>
                <select
                  id="managerName"
                  name="managerName"
                  className="profile-input"
                  style={{ padding: "5px" }}
                  value={formValues.managerName}
                  onChange={handleChange}
                  disabled={!managers || managers.length == 0}
                >
                  <option value="">Select</option>
                  {managers?.map((data) => (
                    <option value={data?.id}>{data?.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="reason" className="profile-label">
                  Reason
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  className="custom-textarea"
                  style={{ width: "100%", height: "100px", resize: "none" }}
                  value={formValues.reason}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <button
                type="button"
                onClick={handleClear}
                style={{
                  backgroundColor: "white",
                  height: "40px",
                  width: "95px",
                  color: "#7647EB",
                  borderRadius: "100px",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Clear
              </button>
              <button
                type="submit"
                disabled={formValues.managerName === ""}
                style={{
                  backgroundColor: "#7647EB",
                  height: "40px",
                  width: "95px",
                  color: "white",
                  borderRadius: "100px",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
}

export default DrawerBottom;
