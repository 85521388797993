// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-reset {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .reset-heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .reset-message {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .resend-btn {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #3f51b5;
    color: white;
    cursor: pointer;
  }
  
  .legal {
    margin-top: 2rem;
  }
  
  .legal-link {
    text-decoration: none;
    color: #3f51b5;
    margin-right: 1rem;
  }
  .gmail-img{
    display: flex;
    justify-content: center;
  }
  .resetpass-img{
    display: flex;
    justify-content: center;
    height: 100px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Pages/ResendPasswordPage/ResendPasswordPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,cAAc;IACd,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,uBAAuB;EACzB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,aAAa;EACf","sourcesContent":[".password-reset {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    padding: 2rem;\n    max-width: 400px;\n    margin: 0 auto;\n  }\n  \n  .reset-heading {\n    font-size: 1.5rem;\n    margin-bottom: 1rem;\n  }\n  \n  .reset-message {\n    font-size: 1rem;\n    margin-bottom: 1rem;\n  }\n  \n  .resend-btn {\n    padding: 0.8rem 1.5rem;\n    border: none;\n    border-radius: 4px;\n    background-color: #3f51b5;\n    color: white;\n    cursor: pointer;\n  }\n  \n  .legal {\n    margin-top: 2rem;\n  }\n  \n  .legal-link {\n    text-decoration: none;\n    color: #3f51b5;\n    margin-right: 1rem;\n  }\n  .gmail-img{\n    display: flex;\n    justify-content: center;\n  }\n  .resetpass-img{\n    display: flex;\n    justify-content: center;\n    height: 100px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
