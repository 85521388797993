// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.designation-body{
    display:flex;
}
.permisssions-table{
    width:70%;
}
.permissions-accordion{
    width:30%;
}
.create-btn{
    border:2px solid #FF6700;
    background-color: #f6d1b9;
    border-radius: 5px;
    color:#FF6700;
}
.create-btn:hover{
    background-color: #FF6700;
    color:white;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Permissions/Permissions.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,SAAS;AACb;AACA;IACI,SAAS;AACb;AACA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".designation-body{\n    display:flex;\n}\n.permisssions-table{\n    width:70%;\n}\n.permissions-accordion{\n    width:30%;\n}\n.create-btn{\n    border:2px solid #FF6700;\n    background-color: #f6d1b9;\n    border-radius: 5px;\n    color:#FF6700;\n}\n.create-btn:hover{\n    background-color: #FF6700;\n    color:white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
