import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal";
import { Table } from "reactstrap"
import Datepicker from "../AttendanceInfo/DatePicker";
import { attendanceInfoFunction } from "../../../Services/CommonServices";
import { toastError, toastSuccess } from "../../../Shared/ToastMsgs";
import { convertUTCtoISTConverter } from "../../../hooks/convertUTCtoISTConverter";

export default function AttendanceHistoryModal({ setShowUserAttendance, showUserAttendance, userId }) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const start = startDate.toLocaleDateString();
    const end = endDate.toLocaleDateString();
    const [info, setInfo] = useState([]);
    useEffect(() => {
        if (!userId) {
            return;
        }
       getAttendance()
    }, [userId])

    function getAttendance(){
        attendanceInfoFunction(`?userId=${userId}&offset=${0}&limit=${10}`).then(reply => {
            if (reply?.status === 200) {
                toastSuccess()
                setInfo(reply.data.data)
            }
            else {
                toastError()
            }
        }).catch(err => {
        })
    }
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    return (
        <Modal
            size="lg"
            show={showUserAttendance}
            onHide={() => setShowUserAttendance(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header >
                <Modal.Title id="example-modal-sizes-title-lg">
                    Attendance History
                </Modal.Title>
                <button type="button" className="ni ni-fat-remove  close-Btn" style={{color:"black"}} onClick={()=>{setShowUserAttendance(false)}} ></button>
            </Modal.Header>
            <Modal.Body>
            <Datepicker />
                <Table className="align-items-center" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">General Details</th>
                            <th colSpan={4} scope="col"></th>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <th>First In</th>
                            <th>First Out</th>
                            <th>Shift Time</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {info.map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td>{formatDate(items.firstCheckInTime)}</td>
                                    <td>{convertUTCtoISTConverter(items.firstCheckInTime)}</td>
                                    <td>{convertUTCtoISTConverter(items.lastCheckOutTime)}</td>
                                    <td>8AM - 8PM</td>
                                    <td>{items.firstCheckInTime && items.lastCheckOutTime ? "present" : "absent"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}