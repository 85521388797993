import React, { useContext, useEffect, useState } from 'react';
import './ManageUserEmpFlow.css'; 
import PageHeader from '../PageHeader/PageHeader';
import ManageUserEmpFlowCards from './ManageUserEmpFlowCards';
import { getAssignedManager, postManagers } from '../../Services/CommonServices';
import { AppContext } from '../../../AppContext';

const ManageUserEmpFlow = () => {
  const {value}=-useContext(AppContext)
  const [isAssignManger,setAssignManager]=useState([])
  const [modalOpen, setModalOpen] = useState(false);


    useEffect(()=>{
      getAssignedManager().then((response)=>{
        setAssignManager(response?.data?.data?.managers);
        
      }).catch(err=>console.log(err,"some error"))

    },[modalOpen])

 


    return (
    <div>
    <PageHeader
      pageTitle="Manage User"
      breadCrumbs={[
        {
          name: 'Home',
          path: "/home"
        },
        {
          name: "Manage User",
          path: "/user-details"
        }
      ]}
      btn={
        <div className="attendance-container">
         
        </div>
      }
    />
    <ManageUserEmpFlowCards isAssignManger={isAssignManger} modalOpen={modalOpen}  setModalOpen={setModalOpen}/>
    
   

  </div>
  );
};

export default ManageUserEmpFlow;
