import React from 'react'
import {ReactComponent as CardImg} from "../../../assets/img/CardImg/cardimg.svg"


function ManagerFlowDetailsCard({item,userDetails}) {
    const {profile,contact,reportees}=item
  return (
    <div className='d-flex card-style-emp '>
    <div className='bg-white w-25 d-flex flex-column justify-content-center card-radius' >
      <CardImg style={{height:"100px"}} className="card-img-style" />
      <div className="d-flex flex-column justify-content-center">
        <p className="admin-text">{profile?.name}</p>
        <p className="admin-sub-text">Admin- {profile?.grade} {profile?.empId}</p>
      </div>
    </div>
    <div className='bg-white w-75 d-flex flex-column card-radius' style={{padding:"18px"}}>
      <div className='d-flex'>
        <p className='profile'>Profiles</p>
      </div>
      <table className='w-100'>
        <tbody>
          <tr>
            <td>
              <p className='emp-subheading'>Department</p>
              <p className='emp-heading'>{contact?.departmentName}</p>
            </td>
           {!userDetails && <td>
              <p className='emp-subheading'>Reportees</p>
              <p className='emp-reporte-no'>{contact?.reportees?.length}</p>
            </td>}
            <td>
              <p className='emp-subheading'> Reporting To</p>
              <p className='emp-heading'>Neeraj Kumar</p>
            </td>
            <td>
              <p className='emp-subheading'>Email</p>
              <p className='emp-heading'>{contact?.email}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className='emp-subheading'>Mobile Number</p>
              <p className='emp-heading'>{contact?.phoneNumber}</p>
            </td>
            <td>
              <p className='emp-subheading'>Shift</p>
              <p className='emp-heading'>{contact?.shift?.name}</p>
            </td>
            <td>
              <p className='emp-subheading'>Office</p>
              <p className='emp-heading'>{contact?.office}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
  </div>
  )
}

export default ManagerFlowDetailsCard