import React, { useEffect, useState } from "react";
// import {  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Tabs, Tab } from '@mui/material';
import { Table } from "react-bootstrap";
import { IoCheckmarkSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import "./Regularization.css";
import { Tabs, Tab } from "react-bootstrap";
import { Button } from "reactstrap";
import { getPendingRegularizationFunction } from "../../../Services/CommonServices";
import RegularizationModal from "./RegularizationModal";
import BulkUploadModal from "./BulkUploadModal";

const RegularizationWeb = () => {
  const [data, setData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [isPending, setIsPending] = useState(true);
  const [pendingData, setPendingData] = useState();
  const [dataToBeVerified, setDataToBeVerified] = useState();
  const [title, setTitle] = useState("");
  const [isShowBulk,setShowBulk]=useState(false)

  useEffect(() => {
    getpendingList();
  }, [dataToBeVerified]);
  const getpendingList = (d) => {
    getPendingRegularizationFunction(d)
      .then((reply) => {
        setPendingData(reply?.data?.data);
        setData(reply?.data?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

   
  return (
    <>
    <BulkUploadModal isShowBulk={isShowBulk} setShowBulk={setShowBulk}/>

      {dataToBeVerified && (
        <RegularizationModal
          dataToBeVerified={dataToBeVerified}
          setDataToBeVerified={setDataToBeVerified}
          title={title}
        />
      )}
      <div className="d-flex justify-content-between">
        <div className="d-flex" style={{ marginLeft: "10px" }}>
          <Button
            className="regularization-tab"
            active={isPending}
            onClick={() => {
              getpendingList();
              setIsPending(true);
              setData(pendingData);
            }}
          >
            Pending
          </Button>
          <Button
            className="regularization-tab"
            active={!isPending}
            onClick={() => {
              getpendingList({ status: "History" });
              setIsPending(false);
              setData(pendingData);
            }}
          >
            History
          </Button>

        </div>
          <button onClick={()=>setShowBulk(true)} disabled={data?.length ==0} className="approve-bulk mr-2">
            Bulk Approve
          </button>
      </div>
      {data.length === 0 ? (
        <div className="d-flex justify-content-center">
          <h3 className="mt-5">No Data Available</h3>
        </div>
      ) : (
        <div className="m-4">
          <div className="table-component-div">
            <Table className="align-items-center m-0">
              <thead className="thead-light">
                <tr>
                  <th>S. no</th>
                  <th>Applied By</th>
                  <th>Applied Date</th>
                  <th>Shift Time</th>
                  {/*<th>Actual Time</th>*/}
                  <th>Updated Time</th>
                  <th>Remarks</th>
                  <th>Reasons</th>
                  {isPending && <th>Action</th>}
                </tr>
              </thead>
              <tbody style={{ background: "white" }}>
                {data?.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{index + 1}</td>
                    <td>{row?.user?.name}</td>
                    <td>
                      {new Date(row?.created_at).toLocaleDateString("en-US", {
                        timeZone: "UTC",
                      })}
                    </td>
                    <td>
                      <span>
                        {new Date(
                          row?.user?.office?.shifts?.[0]?.shift?.start_time
                        ).toLocaleTimeString("en-US", { timeZone: "UTC" })}
                      </span>
                      -
                      <span>
                        {new Date(
                          row?.user?.office?.shifts?.[0]?.shift?.end_time
                        ).toLocaleTimeString("en-US", { timeZone: "UTC" })}
                      </span>
                    </td>
                    {/*<td> <span>{new Date(row?.user.attendances.check_in_time).toLocaleTimeString('en-US', { timeZone: 'UTC' })}</span>-<span>{new Date(row?.user.attendances.check_out_time).toLocaleTimeString('en-US', { timeZone: 'UTC' })}</span></td>*/}
                    <td>
                      <span>
                        {new Date(row?.start_date).toLocaleTimeString("en-US", {
                          timeZone: "UTC",
                        })}
                      </span>
                      -
                      <span>
                        {new Date(row?.end_date).toLocaleTimeString("en-US", {
                          timeZone: "UTC",
                        })}
                      </span>
                    </td>
                    <td>{row?.remarks}</td>
                    <td>{row?.leaveReason?.name}</td>
                    {isPending && (
                      <td>
                        <button
                          className="regularization-action-btn"
                          style={{ marginRight: "20px" }}
                          onClick={() => {
                            setTitle("Approve");
                            setDataToBeVerified(row);
                          }}
                        >
                          <IoCheckmarkSharp style={{ color: "#7647EB" }} />
                        </button>
                        <button
                          className="regularization-action-btn"
                          onClick={() => {
                            setTitle("Reject");
                            setDataToBeVerified(row);
                          }}
                        >
                          <RxCross2 style={{ color: "#7647EB" }} />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default RegularizationWeb;
