export const formatTime = (dateString) => {
  console.log("date string", dateString);
  const date = new Date(dateString);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };
  return date.toLocaleTimeString("en-US", options);
};

export const formatDate = (dateString) => {
  if (!dateString) {
    return;
  }
  const date = new Date(dateString.toString());
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const formatter = new Intl.DateTimeFormat("en-GB", options);
  const parts = formatter.formatToParts(date);

  const day = parts.find((part) => part.type === "day").value;
  const month = parts.find((part) => part.type === "month").value;
  const year = parts.find((part) => part.type === "year").value;

  return `${day}/${month}/${year}`;
};

export const convertIntoHoursMinute = (minutes) => {
  if (minutes && minutes.toString().split(" ")[0]) {
    const finalMinutes = parseInt(minutes.toString().split(" ")[0]);
    if (finalMinutes > 60) {
      return (
        parseInt(finalMinutes / 60) +
        " Hrs " +
        parseInt(finalMinutes % 60) +
        " min"
      );
    } else {
      return finalMinutes + " min";
    }
  }
}

export const getUserSubtitle = (homeData) => {
  const storedUserData = JSON.parse(localStorage.getItem('user_data'));
  return `${storedUserData?.department?.name} ${storedUserData?.designation?.name}${
    storedUserData?.designation?.grade ? `-${storedUserData.designation.grade}-` : " "
  }${storedUserData?.empCode}`;
};

export const checkInStatus = (attendanceData) => {
  return !attendanceData?.is_early_check_in && !attendanceData?.is_late_check_in
    ? "On Time"
    : attendanceData?.is_late_check_in
    ? "Late Check In"
    : "Early Check In";
};

export const checkOutStatus = (attendanceData) => {
  return !attendanceData?.is_early_check_out &&
    !attendanceData?.is_late_check_out
    ? "On Time"
    : attendanceData?.is_late_check_out
    ? "Late Check Out"
    : "Early Check Out";
};
