import React from 'react'
import {ReactComponent as RightArrow} from "../../../assets/img/CardImg/ArrowRight.svg"
import './ReviewCard.css'
import {useNavigate} from "react-router-dom";

function ReviewCard({data}) {

  const navigate = useNavigate();

  const navigateTo = () => {
    navigate("/user-details");
  }

  return (
    <>
      <div className='review-Card-container'>
        <p className='  review-Card-container-p1'>{data?.leave_type_count}</p>
       <div className='d-flex align-items-start justify-content-between'>
       <div className='d-flex flex-column'>
        <p className=' review-Card-container-p2'>Pending {data?.leave_type_name} Request</p>
          {/* <p className=' review-Card-container-p3'>Click to take action</p> */}
        </div>
        <div className='d-flex align-items-center justify-content-between clickable' onClick={navigateTo}>
          <RightArrow />
        </div>
       </div>
      </div>
    </>
  )
}

export default ReviewCard
