import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import {ReactComponent as CalenderImg} from "../../../assets/img/CardImg/icon.svg"
import "../MangerFLow/MangerAttendanceFlow/MangerAttendanceFlow.css"


const App = ({heading}) => (

  <div className='d-flex justify-content-between align-items-center' style={{padding:"10px 15px"}}>
  <p className='m-0 log-text'>{heading}</p>
      <div className='d-flex align-items-center'>
        {/*<CalenderImg/>*/}
      <div className='d-flex align-items-center'>
  {/*    <Dropdown*/}
  {/*  menu={{*/}
  {/*    items,*/}
  {/*  }}*/}
  {/*  trigger={['click']}*/}
  {/*  >*/}
  {/*  <a onClick={(e) => e.preventDefault()}>*/}
  {/*    <Space className='attendance-header'>*/}
  {/*      Sort By*/}
  {/*      <DownOutlined />*/}
  {/*    </Space>*/}
  {/*  </a>*/}
  {/*</Dropdown>*/}
  <CalenderImg/>
      </div>
      </div>
  </div>


);
export default App;
