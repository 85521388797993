import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  userListFunction,
  userforDepartmentHeadFunction,
} from "../../../Services/CommonServices";
import { toastError, toastSuccess } from "../../../Shared/ToastMsgs";
import { Autocomplete, TextField } from "@mui/material";

const CreateUpdateDepartment = ({
  updateData,
  createUpdateData,
}) => {
  const [updateCreateData, setUpdateCreateData] = useState({
    name:"",
    departmentHead:"",
  });
  const [departmentHeadList, setDepartmentHeadList] = useState([]);

  useEffect(() => {
    if (updateData && updateData._id) {
      setUpdateCreateData(updateData);
    }
    getUsersforDepartmentHead();
  }, [updateData]);


  const getUsersforDepartmentHead = () => {
    userforDepartmentHeadFunction()
      .then((reply) => {
        if (reply?.status === 200) {
          console.log("reply", reply);
          setDepartmentHeadList(reply?.data?.data);
          toastSuccess();
        } else {
          toastError();
        }
      })
      .catch((error) => {});
  };

  const handleDepartmentheadChange=(e,val)=>{
    setUpdateCreateData((prevState) => ({
      ...prevState,
      departmenthead:  val, 
  }));
  }

  return (
    <div className="p-4">
      <h3>Add Department</h3>
      <Form.Group className="mt-2">
        <Form.Label>Department Name*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Department Name"
          name="name"
          value={updateCreateData?.name}
          onChange={(e) =>
            setUpdateCreateData({ ...updateCreateData, name: e.target.value })
          }
        />
      </Form.Group>

      <Form.Group className="mt-2">
        <Form.Label>Department Head</Form.Label>
        <Autocomplete
        className="department-head-input"
          value={updateCreateData.departmenthead || null}
          onChange={(event, newValue) => {
            handleDepartmentheadChange(event, newValue);
          }}
          options={departmentHeadList}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Department head" />
          )}
        />
      </Form.Group>
      <Button
        className="submit-btn"
        variant="secondary"
        onClick={() => createUpdateData(updateCreateData)}
      >
        Submit
      </Button>
    </div>
  );
};

export default CreateUpdateDepartment;
