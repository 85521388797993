import React from "react";
import { Navigate } from "react-router-dom";
import ROUTES_DATA from "./routes";

export default function PrivateRoute({ children, redirectPath, name}) {
  const user = JSON.parse((localStorage.getItem("user_data") || "{}") === 'undefined' ? '{}' : (localStorage.getItem("user_data") || "{}"));
  const roleName = user?.role?.name;
  const isAllowed = ROUTES_DATA.find(item => item?.name == name)?.allowedRoles?.includes(roleName);



  
  if (user.firstTimeUser) {
    return <Navigate to="/update-password" replace />;
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}
