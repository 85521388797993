import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../../assets/img/brand/logo.png"
import { Form, Button } from "react-bootstrap";
import "./Register.css";
import { useNavigate } from "react-router-dom";
import { signupFunction } from "../../Services/CommonServices";

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    // orgId: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    // orgId: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit =(e) => {
    e.preventDefault();

    const { name, email, phone, password } = formData;
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^[0-9]{10}$/;

    const errors = {
      name: "",
      email: "",
      phone: "",
      password: "",
    };

    if (!name) {
      errors.name = "name is required.";
    }

    if (!emailRegex.test(email)) {
      errors.email = "Email format is invalid.";
    }

    if (!phoneRegex.test(phone)) {
      errors.phone = "Phone format is invalid (e.g., 1234567890).";
    }

    if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long.";
    }
    if (
      errors.name ||
      errors.email ||
      errors.phone ||
      errors.password 
    ) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
     
    }

  signupFunction(formData).then(reply =>{
    if(reply){
      navigate('/')
    }
  }).catch(err=>{
  
  })    
  };
  return (
      <div className="bg-white"
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Container style={{ flex: 1 }}>
          <Navbar expand="lg">
            <Navbar.Brand >
              {/* <img src={Logo}></img> */}
            </Navbar.Brand>
          </Navbar>
          <div className="signup-container">
            <Form onSubmit={handleSubmit} className="signup-form">
              <h1>Register!</h1>
              <Form.Group controlId="name">
                <Form.Label>name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <div className="text-danger">{validationErrors.name}</div>
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <div className="text-danger">{validationErrors.email}</div>
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <div className="text-danger">{validationErrors.phone}</div>
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <div className="text-danger">{validationErrors.password}</div>
              </Form.Group>
              <Button variant="primary" type="submit" className="btn-signup">
                Sign Up
              </Button>
            </Form>
          </div>
        </Container>
      </div>
  );
  };
  

export default Register;
