import React, { useState, useEffect } from "react"
// import Button from 'react-bootstrap/Button';
import { RiDeleteBin5Fill } from "react-icons/ri"
import { BsArrowRightSquareFill } from "react-icons/bs"
import Modal from 'react-bootstrap/Modal';
import { Container, Table } from "reactstrap"
import { Button } from "reactstrap"
import Accordion from 'react-bootstrap/Accordion';
import "./Permissions.css"

import { getPermissionsFunction } from "../../Services/CommonServices";
import { Row, Card, Col } from "react-bootstrap"
import { toastError, toastSuccess } from "../../Shared/ToastMsgs";

export default function Permissions() {
    const [createUpdateModal, setCreateUpdateModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [showAccordion, setShowAccordion] = useState(false)
    const [permissions, setPermissions] = useState([])
    
    useEffect(() => {
        getPermissions();
    }, [])
    const getPermissions = () => {
        getPermissionsFunction(true).then(reply => {
            if (reply.status === 200) {
                setPermissions(reply.data.data);
                toastSuccess();
            }
            else {
                toastError();
            }
        }).catch(error => {
        })
    }

    return (

        <Container fluid>
            <div className="heading-box d-flex ">
                <h2>Permissions</h2>
                <Button variant="outline-primary" onClick={() => { setCreateUpdateModal(true) }}>Create Permission</Button>
            </div>
            <Row>
                <Col className="d-flex">
                    <Card className="col-9">
                        <Table className="align-items-center  permissions-table">
                            <thead className="thead-light">
                                <tr>
                                    <th>Name</th>
                                    <th>type</th>
                                    <th>Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {permissions.map((items, index) => {
                                    return (
                                        <tr >
                                            <td>{items.name}</td>
                                            <td>{items.type}</td>
                                            <td>{items.action}</td>
                                            {/* <td>
                                        <button className="crud-btn" onClick={() => setShowAccordion(!showAccordion)}><BsArrowRightSquareFill /></button>
                                        <button className="crud-btn" onClick={() => { setDeleteModal(true) }}><RiDeleteBin5Fill /></button>
                                    </td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Card>

                    {showAccordion &&
                        <Card className="col-3">
                            <Card.Header>Featured</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    With supporting text below as a natural lead-in to additional content.
                                </Card.Text>
                            </Card.Body>
                        </Card>}
                </Col>
            </Row>

            
            {deleteModal && <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Permission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        Are you sure you want to delete this permission?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={() => setDeleteModal(false)}
                    >
                        Close
                    </Button>
                    <Button
                        variant="secondary"
                    // onClick={() => deleteDesignation()}
                    >
                        delete
                    </Button>
                </Modal.Footer>
            </Modal>}

            <div className="designation-footer"></div>
        </Container>

    )
}

