
import React, { useEffect, useState } from 'react';
import './ResetPasswordPage.css'; 
import { forgetResendPassword } from '../../Services/CommonServices';
import ToastMsgs from '../../Shared/ToastMsgs';
import { toast } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";
import ResetPassword from "../../../assets/img/resetpass.svg"

function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null); 
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); 
  
    try {
      const response = await forgetResendPassword({ email }); 
      setMessage('Success! Password reset instructions sent to your email.');
      setIsResetSuccessful(true);
    } catch (error) {
      if (error.response && error.response.data) {
        const { message } = error.response.data;
        toast.error(message); 
      } else {
        console.error('Error sending email:', error);
        toast.error('An error occurred while resetting your password.');
      }
    } 
  };
  
  useEffect(() => {

    if (isResetSuccessful) {
      navigate('/resend-password', { state: { email } });
    }
  }, [isResetSuccessful]); 
   

  return (
    <div className="reset-password-container">
         <div className="resetpass-img"><img src={ResetPassword} alt="Reset" className="Reset-icon" /></div>
      <h1>Reset your password</h1>
      <p>Enter your email address and we will send you instructions to reset your password.</p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email address</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit">Continue</button>
      </form>

      {message && <p className="message">{message}</p>}
      <a href="/">Back to Login Page</a>
    </div>
  );
}

export default ResetPasswordPage;
