import React from "react";
import { ReactComponent as RightArrow } from "../../../assets/img/CardImg/ArrowRight.svg";

function HolidayCard({ index, holidays }) {
  const currentDate = new Date();
  const formatDate = (dateStr) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateStr).toLocaleDateString("en-GB", options);
  };

  const getDayName = (dateStr, locale) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: "long" });
  };
 
  return (
    <div className="col-md-3" index={index} style={{ padding: "0px 12px" }}>
                            <div className="bg-white d-flex flex-column holiday-container h-100">
                                <div className="row align-items-center justify-content-between pl-4 pr-4" style={{ padding: "4px" }}>
                                    <p className="m-0 holiday-card-p">Holiday Calendar</p>
                                    <RightArrow />
                                </div>
                                <div className="d-flex flex-column holiday-cards">
                                    <div className="row  holiday-row" style={{rowGap:"10px" ,justifyContent:"space-evenly"}}>
                                    {holidays?.length === 0 ? (
              <div>No Holiday Found</div>
            ) : (
              holidays?.map((item, index) => (
                <div key={index} className={`holiday-card-padding card-bg-${index + 1}`}>
                  <p className="m-0 holiday-date">{formatDate(item?.startDate)}</p>
                  <p className={`m-0 holiday-name card-text-1 holiday-card-${index}`}>{item?.name}</p>
                  <p className="m-0 holiday-day">{getDayName(item?.startDate, "en-US")}</p>
                </div>
              ))
            )}
                                       
                                    </div>
                               
                                </div>
                            </div>
                        </div>
  )
}

export default HolidayCard;
