import React, { useEffect } from 'react'
import { HOME_CARD_DATA } from "../CardData/CardData"
import ReviewCard from '../ReviewCard/ReviewCard';
import { useNavigate } from 'react-router-dom';
import { getPendingList } from '../../Services/CommonServices';

function HomeSmallCard({ item, index, homeData }) {

  const navigate = useNavigate()


  const renderView = () => {
    if (item.type == 'empty') {
      return <div className="col-md-3  card-radius " style={{ padding: " 0px 14px" }}>
        <div className=' bg-white  h-100 card-radius d-flex flex-column' style={{ padding: "16px 17px 8px 17px", rowGap: "13px" }}>
          <p className="review">{item.heading}</p>
          <div className='overflow-container'>
            {homeData?.reviewData === null ? (
              homeData.reviewData.map(dataItem => (
                <ReviewCard key={dataItem.id} data={dataItem} />
              ))
            ) : (
              <div className='d-flex flex-column text-center' style={{rowGap:"29px"}}>
                <div className='d-flex justify-content-center'>
                  {item?.emptyImg}
                </div>
                <p className='empty-message-text'>
                  {item?.emptyMsg}
                </p>
              </div>
            )
            }
          </div>

        </div>

      </div>


    } else {
      return <div key={index} className="col-md-3">
        <div className='bg-white h-100 card-padding card-radius'>
          {item?.img}
          <p className="review">{item.heading}</p>
          <p className="leave-text">
            {item?.desc}
          </p>
          <div className="d-flex justify-content-end cursor-pointer" onClick={() => { navigate(item?.navigate) }}>
            {item.navigateImg}
          </div>

        </div>
      </div>;
    }
  }

  return renderView();
}

export default HomeSmallCard;
