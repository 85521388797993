import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { getDepartmentFuntion, userforDepartmentHeadFunction } from "../../../Services/CommonServices"
import { toastError, toastSuccess } from "../../../Shared/ToastMsgs";
import { Autocomplete, TextField } from "@mui/material";

const CreateUpdateDesignation = ({ updateData,createUpdateData, }) => {
    const [departmentHeadList, setDepartmentHeadList] = useState([]);
    const [updateCreateData, setUpdateCreateData] = useState({
        name: "",
        department: "",
        departmenthead:"",
    });
    const [department, setDepartment] = useState([]);


    useEffect(() => {
        getDepartmentData();
    }, []);

    useEffect(()=>{
        getUsersforDepartmentHead();
    },[updateData])

    useEffect(() => {
        if (updateData && updateData._id) {
            setUpdateCreateData(updateData);
        } else {
            // setUpdateCreateData({
            //     name: "",
            //     department: {
            //         _id: department.length ? department[0]._id : "",
            //     },
            // });
        }
    }, [updateData, department]);

    const getUsersforDepartmentHead = () => {
        userforDepartmentHeadFunction()
          .then((reply) => {
            if (reply?.status === 200) {
              setDepartmentHeadList(reply?.data?.data);
              toastSuccess();
            } else {
              toastError();
            }
          })
          .catch((error) => {});
      };

    const getDepartmentData = () => {
        getDepartmentFuntion().then(reply => {
            if (reply?.status === 200) {
                setDepartment(reply.data.data);
                toastSuccess();
            } else {
                toastError();
            }
        }).catch(error => {
            toastError();
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateCreateData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDepartmentChange = (e,val) => {
        setUpdateCreateData((prevState) => ({
            ...prevState,
            department:  val, 
        }));
    };
    const handleDepartmentheadChange=(e,val)=>{
        setUpdateCreateData((prevState) => ({
            ...prevState,
            departmenthead:  val, 
        }));
    }

    return (
        <div className="p-4">
        <h3>Add Designation</h3>
        <Form.Group className="mt-2">
          <Form.Label>Department Name</Form.Label>
          <Autocomplete
          className="department-head-input"
            value={updateCreateData.department || null}
            onChange={(event, newValue) => {
              handleDepartmentChange(event, newValue);
            }}
            options={department}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Department Name" />
            )}
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label>Designation Name*</Form.Label>
          <Form.Control
            type="text"
            placeholder="Desination Name"
            name="name"
            value={updateCreateData?.name}
            onChange={(e) =>
              setUpdateCreateData({ ...updateCreateData, name: e.target.value })
            }
          />
        </Form.Group>
  
        <Form.Group className="mt-2">
          <Form.Label>Department Head</Form.Label>
          <Autocomplete
          className="department-head-input"
            value={updateCreateData.departmenthead || null}
            onChange={(event, newValue) => {
              handleDepartmentheadChange(event, newValue);
            }}
            options={departmentHeadList}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Department head" />
            )}
          />
        </Form.Group>
  
        <Button
          className="submit-btn"
          variant="secondary"
          onClick={() => createUpdateData(updateCreateData)}
        >
          Submit
        </Button>
      </div>
    );
};

export default CreateUpdateDesignation;
