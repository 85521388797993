// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* th:first-child, td:first-child {
    border-right: 1px solid #000;
} */


.dateBox {
  display: flex;
}

.Attendane-container {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.attendance-searchbar-date {
  column-gap: 3%;
}

.searchbar-container {
  width: 33% !important;
}

.searchbar {
  border-radius: 10px;
  background-color: white;
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 9px;

}

.attendance-searchbar {
  width: 75%;
  border: none;
  height: 40px;

}

.search-text {
  font-family: "Barlow";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #000000;

}

.ant-picker-input>input {
  color: white !important;
}

.searchbar-search-btn {
  margin-bottom: 0;
  border-radius: 30px;
  margin-top: 19px;
  background: #7849EC;
  color: white;
  padding: 10px 28px;
  font-family: "M PLUS 1";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;


}
.attendance-table-row{
  border-radius: 12px;
  margin-right: 12%;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Attendance/AttendanceInfo/AttendanceInfo.css"],"names":[],"mappings":"AAAA;;GAEG;;;AAGH;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;;AAElB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,YAAY;;AAEd;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;;AAEhB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qCAAqC;EACrC,kBAAkB;;;AAGpB;AACA;EACE,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":["/* th:first-child, td:first-child {\n    border-right: 1px solid #000;\n} */\n\n\n.dateBox {\n  display: flex;\n}\n\n.Attendane-container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 3rem;\n}\n\n.attendance-searchbar-date {\n  column-gap: 3%;\n}\n\n.searchbar-container {\n  width: 33% !important;\n}\n\n.searchbar {\n  border-radius: 10px;\n  background-color: white;\n  border: 1px solid;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 2px 9px;\n\n}\n\n.attendance-searchbar {\n  width: 75%;\n  border: none;\n  height: 40px;\n\n}\n\n.search-text {\n  font-family: \"Barlow\";\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 19.2px;\n  text-align: left;\n  color: #000000;\n\n}\n\n.ant-picker-input>input {\n  color: white !important;\n}\n\n.searchbar-search-btn {\n  margin-bottom: 0;\n  border-radius: 30px;\n  margin-top: 19px;\n  background: #7849EC;\n  color: white;\n  padding: 10px 28px;\n  font-family: \"M PLUS 1\";\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 20px;\n  letter-spacing: 0.10000000149011612px;\n  text-align: center;\n\n\n}\n.attendance-table-row{\n  border-radius: 12px;\n  margin-right: 12%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
