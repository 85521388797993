import React from "react";

function HolidayCard({ homeData, formatDate }) {
  return (
    <div>
      {homeData?.holidays?.slice(0, 4) && (
        <div className="hc_cont">
          <div>
            <span className="HolidayCardText">Holiday Calendar</span>
          </div>
          <div className="holiday_container">
            {homeData?.holidays?.slice(0, 4)?.map((data, index) => (
              <div className={`holiday_card-${index}`} key={index}>
                <div className="holidayCardDateContt">
                  {formatDate(data?.startDate)}
                </div>
                <div className={`holiday-card-${index}`}>{data?.name}</div>
                <div
                  style={{
                    color: "#828282",
                    fontWeight: "500",
                    fontSize: "12px",
                  }}
                >
                  {data?.startDay}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default HolidayCard;
