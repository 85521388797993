import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AttendanceInfo from "./AttendanceInfo/AttendanceInfo";
import UserAttendanceInfo from "./UserAttendanceInfo/UserAttendanceInfo";
import { Container } from "reactstrap";
import routes from "../../../routes";
import TodayUserAttendanceInfo from "./UserAttendanceInfo/TodayUserAttendanceInfo";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import { AppContext } from "../../../AppContext";
import { getUserAttendance } from "../../Services/CommonServices";
import MangerAttendanceFlow from "../../Shared/MangerFLow/MangerAttendanceFlow/MangerAttendanceFlow";

export default function Attendance() {
  const {value}=useContext(AppContext)
  const [hasAdminView, setHasAdminView] = useState(false);
  const [hasReporteeView, setHasReporteeView] = useState(false);
  const [activeTab, setActiveTab] = useState("History");
  const user = JSON.parse(localStorage.getItem("user_data") || "{}");
  
  const employee =value?.role?.name ==="employee"

  useEffect(() => {
    const adminPermission = user?.permissions?.find(item => item.name == "Attendance" && item.type == "admin" && item.action == "all_user");
    const reporteePermission = user?.permissions?.find(item => item.name == "Attendance" && item.type == "self" && item.action == "view_reportee");
    setHasAdminView(adminPermission ? true : false);
    setHasReporteeView(reporteePermission ? true : false);
  }, [user, user?.permissions]);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  

  return (
    <div>
      <PageHeader
        pageTitle="Attendance"
        breadCrumbs={[
          {
            name: 'Home',
            path: "/home"
          },
          {
            name: "Attendance",
            path: "/attendance"
          }
        ]}
        // btn={!employee &&
        //   <div className="attendance-container">
        //     <div className="attendance-btn" onClick={() => handleTabChange("History")}>Attendance</div>
        //     <div className="attendance-btn" onClick={() => handleTabChange("User-History")}>All User Attendance</div>
        //     <div className="attendance-btn" onClick={() => handleTabChange("Today-User-History")}>Today's Attendance</div>
        //   </div>
        // }
      />
      {/*<Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="fill-tab-example" className="mb-3 tab-active">*/}
      {/*  <Tab eventKey="History">*/}
      {/*    /!*<AttendanceInfo />*!/*/}
      {/*      <MangerAttendanceFlow />*/}
      {/*  </Tab>*/}
      {/*  {hasAdminView && (*/}
      {/*    <Tab eventKey="User-History" >*/}
      {/*      <UserAttendanceInfo />*/}
      {/*    </Tab>*/}
      {/*  )}*/}
      {/*  {(hasAdminView || hasReporteeView) && (*/}
      {/*    <Tab eventKey="Today-User-History" >*/}
      {/*      <TodayUserAttendanceInfo />*/}
      {/*    </Tab>*/}
      {/*  )}*/}
      {/*</Tabs>*/}
        <MangerAttendanceFlow />
    </div>
  );
}
