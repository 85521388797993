import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const ResendOtpComponent = ({ timeStart, setResendOtp, resendOtp, emailVerifyHandler }) => {
    const [timer, setTimer] = useState(10);

    useEffect(() => {
        if (timeStart) {
            const countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(countdown);
                        setResendOtp(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
    }, [timeStart]);

    return (
        <div className="resend-otp">
            <div>
                {resendOtp ? (
                <></>
                ) : (
                    <span>Resend OTP in {timer}s</span>
                )}
            </div>
        </div>
    );
};

export default ResendOtpComponent;
