import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import UserManage from './UserManage/UserManage';
import UserUpdate from './UserUpdate/UserUpdate';
import ManageReportee from './ManageReportee/ManageReportee';
import { userListFunction } from "../../Services/CommonServices";
import { Card, Container, Row } from "reactstrap";
import { Table } from "reactstrap";
import CsvUploadUsers from '../CsvUploadUser/CsvUploadUser';
import UpdatePermissions from './UpdatePermissions/UpdatePermissions';
import routes from '../../../routes';
import { Spinner } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';
import { fetchReporteefromManager } from '../../Services/CommonServices';
import PageHeader from '../../Shared/PageHeader/PageHeader';
import SortByDropDown from "../../Shared/MangerFLow/SortByDropDown";
import { Tabs } from 'antd';
import ManagerReporteeCard from '../../Shared/MangerFLow/MangerReporteeCard';
import MangerFlowAllReportee from '../../Shared/MangerFLow/MangerFlowAllReportee';
import PastReportee from '../../Shared/MangerFLow/PastReportee';
import { Tab } from 'react-rainbow-components';
import ManageUserEmpFlow from '../../Shared/EmployeFlow/ManageUserEmpFlow';
import "./ManageReportee/ManageReportee.css"
import RegularizationWeb from "../../Shared/MangerFLow/Regularization/ReguralrizationWeb";

function UserDetails() {
  const [activeTab, setActiveTab] = useState("current");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const renderTabStyle = (key) => {
    return activeTab === key ? { textDecoration: 'underline',  } : {};
  };
  const Manager = JSON.parse(localStorage.getItem('user_data'))?.role?.name == "Manager";

  return (
    <><div fluid className='m-0 p-0 padding-user' style={{padding:"0px !important"}}>
         {Manager?  <div>
              <PageHeader
                pageTitle="Reportees"
                breadCrumbs={[
                  {
                    name: 'Home',
                    path: "/home"
                  },
                  {
                    name: Manager?"Reportees":"Manage User",
                    path: "/user-details"
                  }
                ]}
                btn={
                  <div className="attendance-container">
                    <div className="attendance-btn" onClick={() => handleTabChange("current")}  style={renderTabStyle("current")} >Current Reportees</div>
                    <div className="attendance-btn" onClick={() => handleTabChange("past")} style={renderTabStyle("past")}>Past Reportees</div>
                    <div className="attendance-btn" onClick={() => handleTabChange("regularisation")} style={renderTabStyle("regularisation")}>Regularisation</div>
                  </div>
                }
              />
              <Tabs activeKey={activeTab}>
               <Tab  key="current" >
                  <SortByDropDown heading={"Reportees"} />
                  <MangerFlowAllReportee type={activeTab} />
               </Tab>
               <Tab key="past">
                 <MangerFlowAllReportee type={activeTab} />
               </Tab>
               <Tab  key="regularisation">
                 <RegularizationWeb/>
               </Tab>
       
              </Tabs>
            </div>
         :   <ManageUserEmpFlow/>}
          </div>
    
    </>
  );
}

export default UserDetails;
