import React, { useEffect, useState } from "react"
import "./PunchIn.css"
import PunchInModal from "./PunchInModal";
import Clock from "./Clock/Clock";
import { Row, Card, Container, } from "reactstrap"
import { Button } from "reactstrap"
import { convertUTCtoISTConverter } from "../../../hooks/convertUTCtoISTConverter";
// import { getLastAttendance } from "../../../Services/CommonServices";



function PunchIn({ userData, attendanceData , refreshAttendanceData }) {
    const [checkIn, setCheckIn] = useState()
    const [checkOut, setCheckOut] = useState()
    const [showModal, setShowModal] = useState(false);
    const date = new Date();
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const time = date.toLocaleTimeString()
    let day = weekday[date.getDay()];
    
    
    function updateTime() {
        let time = new Date().toLocaleTimeString()
        setCurrentTime(time)
    }
    setInterval(updateTime, 1000)
    const [currentTime, setCurrentTime] = useState(time);

    function displayModal() {
        setShowModal(true) 
    }
    

    return (
        
            <Row >
                <Card className="col">
                    <div className="d-flex punchIn-card" >
                        <div className="clock-container">
                            <Clock />
                        </div>
                        <div className='time'>
                            <h6 className="day">{`${day} |`}</h6>
                            <h6 className="shift">8:00 AM to 8:00 PM shift</h6>
                            <h6 className="date">{date.toLocaleDateString()}</h6>
                            <Button onClick={displayModal}>Mark Attendance</Button>
                        </div>
                        {showModal && <PunchInModal
                            showModal={showModal}
                            setShowModal={setShowModal}
                            checkIn = {checkIn}
                            setCheckIn = {setCheckIn}
                            checkOut = {checkOut}
                            setCheckOut = {setCheckOut}
                            userAttendance = {attendanceData}
                            refreshAttendanceData = {refreshAttendanceData}
                            
                        />}
                    </div>
                </Card>
            </Row>
        
    )
}

export default PunchIn



//"https://ipgeolocation.abstractapi.com/v1"
//https://geolocation-db.com/json/
//https://api.ipify.org?format=json
