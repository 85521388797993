import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import "./ProfileDetails.css";
import LeftArrow from "../../../assets/img/arrow_left_icon .svg";
import Warn from "../../../assets/img/warning_icon.svg";
import DrawerBottom from "./Drawer/DrawerBottom";

import { useNavigate } from "react-router-dom";

function ProfileDetails() {
  const { name, email, contact, department, designation, managers, empId } =
    JSON.parse(localStorage.getItem("user_data")) ?? {};

  const [formState, setFormState] = useState({
    email,
    mobile: contact,
    department: department?.name,
    designation: designation?.name,
    reportingManager: managers?.[0]?.manager?.name,
    office: "Jhandewalan",
  });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formState);
  };

  const handleClear = () => {
    setFormState({
      email: "",
      mobile: "",
      department: "",
      designation: "",
      reportingManager: "",
      office: "",
    });
  };

  const handleChangeManager = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    const { email, contact, department, designation, managers } = JSON.parse(
      localStorage.getItem("user_data")
    );
    setFormState({
      email: email,
      mobile: contact,
      department: department?.name,
      designation: designation?.name,
      reportingManager: managers?.[0]?.manager?.name,
      office: "Jhandewalan",
    });
    setDrawerOpen(false);
  };

  return (
    <div>
      <div className="ProfileDetailsContt">
        <div type="button" onClick={() => navigate("/home")}>
          <img src={LeftArrow} />
        </div>
        <span className="PdText">Profile Details</span>
      </div>
      <div className="pro_sub_container">
        <Avatar className="PdAvtar">{name?.charAt(0).toUpperCase()}</Avatar>

        <div>
          <span className="PdHeaderName">{name}</span>
          <div className="PdHeaderEmpId">{empId}</div>
        </div>
      </div>

      <div>
        <form onSubmit={handleSubmit} className="PdForm">
          <div>
            <label className="profile-label">Email</label>
            <input
              type="text"
              name="email"
              value={formState.email}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>

          <div>
            <label className="profile-label">Mobile no.</label>
            <input
              type="text"
              name="mobile"
              value={formState.mobile}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>
          <div>
            <label className="profile-label">Department</label>
            <input
              type="text"
              name="department"
              value={formState.department}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>
          <div>
            <label className="profile-label">Designation</label>
            <input
              type="text"
              name="designation"
              value={formState.designation}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>
          <div>
            <label className="profile-label">Reporting Manager</label>
            <input
              type="text"
              name="reportingManager"
              value={formState.reportingManager}
              onChange={handleChange}
              className="profile-input"
              disabled
            />

            <div onClick={handleChangeManager} className="PdSubmitBtn">
              Do you want to change Manager?
            </div>
          </div>
          <div>
            <label className="profile-label">Office</label>
            <input
              type="text"
              name="office"
              value={formState.office}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>
        </form>
      </div>

      <DrawerBottom
        handleCloseDrawer={handleCloseDrawer}
        drawerOpen={drawerOpen}
        LeftArrow={LeftArrow}
        Warn={Warn}
        recentManagerId={managers?.[0]?.manager?.id}
      />
    </div>
  );
}

export default ProfileDetails;
