import React from 'react'
import { ReactComponent as CardImg } from "../../../assets/img/CardImg/User.svg"

import  "./ReporteCard.css"
import {checkInStatus, checkOutStatus, formatTime, getUserSubtitle} from "../../Services/UtilServices";


function reporteCard({data}) {
  return (
    <div className='row report-card-border' style={{padding:"0px 17px"}} >
        <div className='d-flex reporte-container'>
            <CardImg/>
           <div>
           <p className='m-0 reporte-container-heading'>{data?.name}</p>
           <p className=' reporte-container-subheading'>{getUserSubtitle(data)}</p>
           </div>
        </div>
        <div className='d-flex justify-content-center w-50' style={{columnGap:"40px"}}>
            <div className=''>
            <p className='reporte-container-time m-0'>{formatTime(data?.attendances[0]?.check_in_time)}</p>
            <p className='m-0 reporte-container-ontime'>{checkInStatus(data?.attendances[0])}</p>
            </div>
            <div className=''>
                <p className='text-center m-0 reporte-container-time'>{formatTime(data?.attendances[0]?.check_out_time)}</p>
                <p className='m-0 reporte-container-ontime'>{checkOutStatus(data?.attendances[0])}</p>
            </div>
        </div>
    </div>
  )
}

export default reporteCard
