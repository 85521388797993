
import React from "react";
import { createRoot } from 'react-dom/client';
import { ReactDOM } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./assets/plugins/nucleo/css/nucleo.css"
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss"
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from "../src/App";
import axios from "axios";
import Header from "./components/Shared/Headers/Header";
import { AppContextProvider } from "./AppContext";
import ErrorHandler from "./components/Services/ErrorHandler";

axios.interceptors.request.use(
  config => {
    const token_data=JSON.parse((localStorage.getItem("user_data")|| "{}") == 'undefined' ? '{}' : (localStorage.getItem("user_data")|| "{}"));
    if (token_data?.activeToken) {
      config.headers['Authorization'] = `${token_data?.activeToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <>
  <AppContextProvider>
  <BrowserRouter>
  <ErrorHandler>
    <App />
      <div id="loader-background" className="loader-background" style={{ display: 'none' }}></div>
      <span id="loader" className="loader" style={{ display: 'none' }}></span>
    </ErrorHandler>
  </BrowserRouter>
  </AppContextProvider>
  </>
);
