import React from 'react'
import { Container } from 'reactstrap'

function HolidayCalendar() {
  return (
    <Container fluid>
      main content
    </Container>
  )
}

export default HolidayCalendar
