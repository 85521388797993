import React, { lazy, useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ROUTES_DATA from "./routes";
import "./App.css";
import Home from "./components/Pages/Home/Home";
import Leave from "./components/Pages/Leave/Leave/Leave";
import LeaveBalance from "./components/Pages/Leave/LeaveBalance/LeaveBalance";
import HolidayCalendar from "./components/Pages/Leave/HolidayCalender/HolidayCalender";
import UserDetails from "./components/Pages/UserDetails/UserDetails";
import Department from "./components/Pages/Department/Department";
import Designation from "./components/Pages/Designation/Designation";
import Permissions from "./components/Pages/Permissions/Permissions";
import Register from "./components/Pages/Register/Register";
import Sidebar from "./components/Shared/Sidebar/Sidebar";
import LeavePending from "./components/Pages/Leave/LeavePending/LeavePending";
import LeaveHistory from "./components/Pages/Leave/LeaveHistory/LeaveHistory";
import Feeds from "./components/Pages/Feeds/Feeds";
import Header from "./components/Shared/Headers/Header";
import Attendance from "./components/Pages/Attendance/Attendance";
import PrivateRoute from "./PrivateRoute";
import ToastMsgs, {
  toastError,
  toastSuccess,
} from "./components/Shared/ToastMsgs";
import Networks from "./components/Pages/Networks/Network";
import { AppContext } from "./AppContext";
import ResetPasswordPage from "./components/Pages/ResetPasswordPage/ResetPasswordPage";
import ResendPasswordPage from "./components/Pages/ResendPasswordPage/ResendPasswordPage";
import NewPasswordPage from "./components/Pages/NewPasswordPage/NewPasswordPage";
import UpdatePassword from "./components/Pages/UpdatePassword/UpdatePassword.jsx";
import Organisation from "./components/Pages/Organisation/Organisation.jsx";
import AttedanceLog from "./components/Pages/Attendance/Attendancemobile/AttedanceLog.jsx";
import AttendancePunch from "./components/Pages/AttendancePunch/AttendancePunch.jsx";
import PageHeader from "./components/Shared/PageHeader/PageHeader.jsx";
import RegisterOrganisation from "./components/Pages/RegisterOrganisation/RegisterOrganisation.jsx";
import LogoNavbar from "./components/Pages/Login/LogoNavbar.jsx";
import Shift from "./components/Pages/Shift/Shift.jsx";
import ReporteeDetails from "./components/Shared/MangerFLow/ReporteeDetails.jsx";
import ProfileDetails from "./components/Pages/profile/ProfileDetails.jsx";
import { logOut } from "./components/Services/CommonServices.jsx";
import { error } from "ajv/dist/vocabularies/applicator/dependencies.js";
import RegularizationMobile from "./components/Shared/MangerFLow/Regularization/RegularizationMobile.jsx";
import SuccessScreen from "./components/Shared/SuccessScreen/SuccessScreen.jsx";

const Login = lazy(() => import("./components/Pages/Login/Login"));

export default function App(props) {
  const { value, isMobView, setValue, openModal, successText } =
    useContext(AppContext);
  const userAuth = value?.activeToken;
  console.log(userAuth,"value")
  const firstTimeUser = value?.firstTimeUser;
  const navigate = useNavigate();

  const logoutHandler = () => {
    logOut()
      .then((response) => {
        toastSuccess("Logout successful");
        localStorage.clear("user_data");
        setValue("");
        navigate("/");
      })
      .catch((error) => toastError(error.message));
  };
  return (
    <>
      <SuccessScreen openModal={openModal} successText={successText} />
      {!isMobView && <LogoNavbar logoutHandler={logoutHandler} />}

      {userAuth && !isMobView && (
        <Sidebar
          value={value}
          {...props}
          routes={ROUTES_DATA}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("./assets/img/brand/logo.png"),
            imgAlt: "...",
          }}
        />
      )}
      <div className="main-content h-100 bg-white">
        <div className={isMobView ? "" : "pg"}>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route
              path="/home"
              element={
                <PrivateRoute redirectPath="/" name="Home">
                  <Home logoutHandler={logoutHandler} />
                </PrivateRoute>
              }
            />
            <Route
              path="/attendance"
              element={
                <PrivateRoute redirectPath="/home" name="Attendance">
                  <Attendance />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave"
              element={
                <PrivateRoute redirectPath="/home" name="Leave">
                  <Leave />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave/balance"
              element={
                <PrivateRoute redirectPath="/home">
                  <LeaveBalance />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave/pending"
              element={
                <PrivateRoute
                  redirectPath="/home"
                  name="designations"
                  type="admin"
                  action="all"
                >
                  <LeavePending />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave/history"
              element={
                <PrivateRoute
                  redirectPath="/home"
                  name="designations"
                  type="admin"
                  action="all"
                >
                  <LeaveHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/leave/calender"
              element={
                <PrivateRoute
                  redirectPath="/home"
                  name="designations"
                  type="admin"
                  action="all"
                >
                  <HolidayCalendar />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-details"
              element={
                <PrivateRoute redirectPath="/home" name="Manage User">
                  <UserDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/designation"
              element={
                <PrivateRoute redirectPath="/home" name="Designation">
                  <Designation />
                </PrivateRoute>
              }
            />
            <Route
              path="/department"
              element={
                // <PrivateRoute redirectPath="/home" name="Department">
                <Department />
                // </PrivateRoute>
              }
            />
            <Route
              path="/shift"
              element={
                // <PrivateRoute redirectPath="/home" name="Shift" >
                <Shift />
                // </PrivateRoute>
              }
            />
            <Route
              path="/permissions"
              element={
                <PrivateRoute redirectPath="/home" name="Permission">
                  <Permissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/network"
              element={
                <PrivateRoute redirectPath="/home" name="Networks">
                  <Networks />
                </PrivateRoute>
              }
            />
            <Route path="/attendance-log" element={<AttedanceLog />} />
            <Route path="/punch" element={<AttendancePunch />} />
            <Route path="/profile-details" element={<ProfileDetails />} />
            <Route path="/regularization" element={<RegularizationMobile />} />

            <Route
              path="/organisation"
              element={
                <PrivateRoute redirectPath="/home" name="Organisation">
                  <Organisation />
                </PrivateRoute>
              }
            />
            <Route
              path="/reportee-details/:id"
              element={
                // <PrivateRoute redirectPath="/home" name="Organisation" >
                <ReporteeDetails />
                /* </PrivateRoute> */
              }
            />

            <Route
              path="/register"
              element={userAuth ? <Navigate to="/home" /> : <Register />}
            />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/register-organisation"
              element={<RegisterOrganisation />}
            />
            <Route path="/resend-password" element={<ResendPasswordPage />} />
            <Route path="/reset-password-page" element={<NewPasswordPage />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </div>
      </div>
      <ToastMsgs />
    </>
  );
}
