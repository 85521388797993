import React from 'react'
import { Container, Tab, Tabs } from 'react-bootstrap'
import AllOrganisation from './AllOrganisation'
import './Organisation.css'
import ApprovedOrganisation from './ApprovedOrganisation'
import PendingOrganisation from './PendingOrganisation'
import RejectedOrganisation from './RejectedOrganisation'

function Organisation() {
    return (
        <Container fluid className='organisation-container'>
            <div className='bg-white organisation-container' >
            <Container fluid>
      <Tabs defaultActiveKey="AllOrganisation" id="fill-tab-example" className="mb-3">
        <Tab eventKey="AllOrganisation" title="All Organisation">
          <AllOrganisation />
        </Tab>
        <Tab eventKey="Approved" title="Approved">
          <ApprovedOrganisation />
        </Tab>
        <Tab eventKey="Pending" title="Pending">
          <PendingOrganisation />
        </Tab>
        <Tab eventKey="Rejected" title="Rejected">
          <RejectedOrganisation  />
        </Tab>
      </Tabs>
    </Container>
            </div>
        </Container>
    )
}

export default Organisation