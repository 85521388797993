// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-close-btn {
    color: black !important;
  }
  .Manage-reportee-modal-btn{
    background-color: transparent !important;
    padding: 0 !important;
    color: gray !important;
    margin: 0 !important;
  }

  .padding-user{
    padding: 0px !important;
  }
 `, "",{"version":3,"sources":["webpack://./src/components/Pages/UserDetails/ManageReportee/ManageReportee.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;EACzB;EACA;IACE,wCAAwC;IACxC,qBAAqB;IACrB,sBAAsB;IACtB,oBAAoB;EACtB;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":[".modal-close-btn {\n    color: black !important;\n  }\n  .Manage-reportee-modal-btn{\n    background-color: transparent !important;\n    padding: 0 !important;\n    color: gray !important;\n    margin: 0 !important;\n  }\n\n  .padding-user{\n    padding: 0px !important;\n  }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
