import React, {useContext, useEffect, useState} from 'react'
import ManagerReporteeCard from "./MangerReporteeCard"
import { useNavigate } from 'react-router-dom'
import {getReportees} from "../../Services/CommonServices";
import { AppContext } from '../../../AppContext';


function MangerFlowAllReportee({type}) {
  const {reporteeName,
    setReporteeName}=useContext(AppContext)
  const  navigate = useNavigate();
  const [attendees, setAttendees] = useState([]);
  const reporteeHandler=(id)=>{
    navigate("/reportee-details/" + id);
  }

  useEffect(() => {
    if (type) {
      getReportees(type).then(res => {
        setReporteeName(res.data.data)
        setAttendees(res.data.data);
      }).catch(err => {
        console.log("error while getting attendance list", err);
      })
    } else {
      setAttendees([]);
    }
  }, [type]);


  return (
    <div>
      {(!attendees || attendees.length == 0) && <h3 style={{textAlign: "center"}}>No {type} reportees found</h3>}
      {attendees.map(attendeeData => {
        return <ManagerReporteeCard  reporteeHandler={(id) => reporteeHandler(id)} attendeeData={attendeeData} />
      })}
    </div>
  )
}

export default MangerFlowAllReportee
