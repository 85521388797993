// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reporte-container{
    width: 50%;
    column-gap: 20px;
    /* justify-content: spac; */
}
.reporte-container-heading {
    font-family: "Kumbh Sans";
font-size: 16px;
font-weight: 500;
line-height: 19.84px;
text-align: left;
color: #212121;
}
.reporte-container-subheading{
    font-family: "Kumbh Sans";
font-size: 12px;
font-weight: 400;
line-height: 14.91px;
text-align: left;
color: #2f2f2f;
}
.reporte-container-time{
    font-family: "Kumbh Sans";
font-size: 14px;
font-weight: 600;
line-height: 17.36px;
text-align: left;
color: #2f2f2f;

}
.reporte-container-ontime{
    font-family: "Kumbh Sans";
font-size: 10px;
font-weight: 400;
line-height: 12.4px;
text-align: center;
color: #828282;

}
.report-card-border{
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(172, 175, 181, 0.1);

}`, "",{"version":3,"sources":["webpack://./src/components/Shared/ReporteCard/ReporteCard.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,2BAA2B;AAC/B;AACA;IACI,yBAAyB;AAC7B,eAAe;AACf,gBAAgB;AAChB,oBAAoB;AACpB,gBAAgB;AAChB,cAAc;AACd;AACA;IACI,yBAAyB;AAC7B,eAAe;AACf,gBAAgB;AAChB,oBAAoB;AACpB,gBAAgB;AAChB,cAAc;AACd;AACA;IACI,yBAAyB;AAC7B,eAAe;AACf,gBAAgB;AAChB,oBAAoB;AACpB,gBAAgB;AAChB,cAAc;;AAEd;AACA;IACI,yBAAyB;AAC7B,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,kBAAkB;AAClB,cAAc;;AAEd;AACA;IACI,mBAAmB;IACnB,iDAAiD;;AAErD","sourcesContent":[".reporte-container{\n    width: 50%;\n    column-gap: 20px;\n    /* justify-content: spac; */\n}\n.reporte-container-heading {\n    font-family: \"Kumbh Sans\";\nfont-size: 16px;\nfont-weight: 500;\nline-height: 19.84px;\ntext-align: left;\ncolor: #212121;\n}\n.reporte-container-subheading{\n    font-family: \"Kumbh Sans\";\nfont-size: 12px;\nfont-weight: 400;\nline-height: 14.91px;\ntext-align: left;\ncolor: #2f2f2f;\n}\n.reporte-container-time{\n    font-family: \"Kumbh Sans\";\nfont-size: 14px;\nfont-weight: 600;\nline-height: 17.36px;\ntext-align: left;\ncolor: #2f2f2f;\n\n}\n.reporte-container-ontime{\n    font-family: \"Kumbh Sans\";\nfont-size: 10px;\nfont-weight: 400;\nline-height: 12.4px;\ntext-align: center;\ncolor: #828282;\n\n}\n.report-card-border{\n    margin-bottom: 10px;\n    border-bottom: 1px solid rgba(172, 175, 181, 0.1);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
