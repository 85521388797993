import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../assets/img/brand/logo.png";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { updatePasswordFunction } from "../../Services/CommonServices"; 
import { AppContext } from "../../../AppContext";
import './UpdatePassword.css'

function UpdatePassword() {
  const { value, setValue } = useContext(AppContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { oldPassword, newPassword } = formData;

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const errors = {
      oldPassword: "",
      newPassword: "",
    };

    if (!oldPassword) {
      errors.oldPassword = "New password is required.";
    }

    if (oldPassword != newPassword) {
      errors.newPassword = "Password did not match.";
    }

    // if (!passwordRegex.test(newPassword)) {
    //   errors.newPassword = "New password format is invalid.";
    // }

    if (errors.oldPassword || errors.newPassword) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      updatePasswordFunction({ userId: value._id, ...formData }).then((reply) => {
        if (reply?.status === 200) {
          const updatedUser = { ...value, firstTimeUser: false };
          setValue(updatedUser);
          localStorage.setItem("user_data", JSON.stringify(updatedUser));
          navigate("/home");
        }
      }).catch((err) => {
        console.error("Update password error: ", err);
      });
    }
  };

  return (
    <Container fluid>
      <Navbar expand="lg">
        <Navbar.Brand href="https://jarvis.consulting/">
          <img src={logo} alt="Logo"></img>
        </Navbar.Brand>
      </Navbar>
      <div className="update-password-container  mt-5">
        <Form onSubmit={handleSubmit} className="update-password-form">
          <h1>Update Password</h1>
          <Form.Group controlId="oldPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              name="oldPassword"
              value={formData.oldPassword}
              onChange={handleInputChange}
            />
            <div className="text-danger">{validationErrors.oldPassword}</div>
          </Form.Group>

          <Form.Group controlId="newPassword">
            <Form.Label> Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleInputChange}
            />
            <div className="text-danger">{validationErrors.newPassword}</div>
          </Form.Group>
          <br />

          <Button variant="primary" type="submit" className="btn-update-password">
            Submit
          </Button>
          <p className="text-muted">
            For your safety, please ensure that your new password is unique and not used elsewhere.
          </p>
        </Form>
      </div>
    </Container>
  );
}

export default UpdatePassword;
