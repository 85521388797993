import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { getDepartmentFuntion,userUpdateFunction,getDesignationByDepartmentFunction  , getReportingTo } from "../../../Services/CommonServices"
import { toastError, toastSuccess } from "../../../Shared/ToastMsgs";
export default function UserUpdate(props) {
    const { update, setUdpate, user,getUser } = { ...props };
    console.log("my props", props);
    const [departments, setDepartments] = useState([]);
    const [designation, setDesignation] = useState([])
    const [manager , setManager] = useState([]);
    const [data, setData] = useState({
        username: user?.name,
        userID: user?._id,
        designation:  user?.designation?._id || null,
        department: user?.department || null,
        manager: user?.managerId || null
    });
    useEffect(() => {
        getDepartmentData();
        getManagerData();
    }, [])
    useEffect(() => {
        if (data?.department) {
            getDesignationData(data.department)
        }
    }, [data])
    const getDepartmentData = () => {
        getDepartmentFuntion().then(reply => {
            if (reply?.status === 200) {
                setDepartments(reply.data.data);
                toastSuccess();
            }
            else {
                toastError()
            }
        }).catch(error => {
            console.log(error)
        })
    }
    const getDesignationData = (id) => {
        getDesignationByDepartmentFunction(id).then(reply => {
            setDesignation(reply.data.data)
        }).catch(error => [
        ])
    }
    const getManagerData = () => {
        getReportingTo().then(reply => {
            setManager(reply.data.data)
        }).catch(error => [
        ])
    }
    const handleUserChange=(e)=> {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };
    const updateUser=()=>{
        userUpdateFunction(`${data.userID}`,{
            name:data.username,
            designation:data.designation,
            department: data.department,
            managerId: data.manager
        }).then(reply=>{
            getUser();
        }).catch(error=>{
        })
    }
    const handleSubmit=()=>{
        updateUser();
        setUdpate(false);
    }
return (
    <Modal show={true}
        onHide={() => setUdpate(false)}
    >
        <Modal.Header >
            <Modal.Title>Update User</Modal.Title>
            <button type="button" className="ni ni-fat-remove  close-Btn" style={{color: 'black'}} onClick={()=>{setUdpate(false)}} ></button>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-12">
                    <label>User Name</label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="name"
                        name="username"
                        value={data.username}
                        onChange={handleUserChange}
                    />
                    <label>Department Name</label>
                    <select className="form-control" value={data.department} onChange={(e) => setData({ ...data, department: e.target.value })}>
                        <option>select department</option>
                        {departments?.map(item =>
                            <option key={item._id} value={item._id} className="form-control">
                                {item.name}
                            </option>
                        )}
                    </select>
                </div>
                <br />
                <div className="col-12">
                    <label>Designation Name</label>
                    <select className="form-control" value={data.designation} onChange={(e) => setData({ ...data, designation: e.target.value })} >
                        <option>select option</option>
                        {designation?.map(item => {
                            return <option key={item._id} value={item._id} className="form-control">
                                {item.name}
                            </option>
                        })}
                    </select>
                </div>
                <br />
                <div className="col-12">
                    <label>Reporting To</label>
                    <select className="form-control" value={data.manager} onChange={(e) => setData({ ...data, manager: e.target.value })} >
                        <option>select option</option>
                        {manager?.map(item => {
                            return <option key={item._id} value={item._id} className="form-control">
                                {item.name}
                            </option>
                        })}
                    </select>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary"
                onClick={() => setUdpate(false)}
            >
                Close
            </Button>
            <Button
                variant="secondary"
                onClick={handleSubmit}
            >
                Update
            </Button>
        </Modal.Footer>
    </Modal>
)
}