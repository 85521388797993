import React from "react";
import LoginIcon from "../../../../assets/img/login_in.svg";
import LogoutIcon from "../../../../assets/img/login_out.svg";
import {
  checkOutStatus,
  convertIntoHoursMinute,
  formatDate,
  formatTime,
} from "../../../Services/UtilServices";
function AttendanceDetail({ attendanceData }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "150px",
        width: "100%",
        padding: "10px",
        borderRadius: "12px",
        backgroundColor: "#FFFFFF",
        marginTop: "10px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "30%",
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "600",
            color: "black",
            display: "flex",
            alignItems: "center",
          }}
        >
          {formatDate(attendanceData?.created_at)}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#30BEB60D",
            color: "#30BEB6",
            borderRadius: "8px",
            fontSize: "13px",
            padding: "5px",
          }}
        >
          Total Time {convertIntoHoursMinute(attendanceData?.totalHours)}
        </div>
      </div>
      <div style={{ borderBottom: "1px solid #ACAFB51A" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "35%",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "31%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img src={LoginIcon} />
          </div>
          <div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "black",
                display: "flex",
                alignItems: "center",
              }}
            >
              {formatTime(attendanceData?.check_in_time)}
            </div>
            {attendanceData?.is_late_check_in && (
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #FB7247, #FE511B)",
                  color: "white",
                  borderRadius: "12px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                {attendanceData.late_check_in_time} late
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "39%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img src={LogoutIcon} />
          </div>
          <div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "black",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!attendanceData?.check_out_time
                ? "--:--"
                : formatTime(attendanceData?.check_out_time)}
            </div>
            <div style={{ fontSize: "13px" }}>
              {attendanceData?.check_out_time && checkOutStatus(attendanceData)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceDetail;
