import React from "react";

function OverViewCard({ icon, statusText, textPart, status }) {
  return (
    <div className="overview_card">
      <div className="OverViewCardOne">
        <img src={icon} height="20px" />
        <span className="OverViewCardOneText">{statusText}</span>
      </div>
      <div>
        <div className="AttendanceCheckInOutCont">{textPart}</div>
        <div className="AttendanceStatus">{status}</div>
      </div>
    </div>
  );
}

export default OverViewCard;
