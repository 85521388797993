import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import React from "react"
import AdminNavbar from "../Navbars/AdminNavbar";

const Header = () => {
  return (
    <>
      <div className="greeting-header">
        <Container fluid>

        </Container>
      </div>
    </>
  );
};

export default Header;