// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-button{
    width:100%;
    background-color: white;
    border:1px solid rgb(174, 173, 173);
    padding-top:5px;
    padding-left:10px;
}
.accordion-per{
    border:1px solid rgb(174, 173, 173);
}
.permission-name{
    margin-left: 10px;
}
.accordian-content-col{
    display: flex;
}
.close-Btn{
    background-color: transparent;
    border:none;
    font-size: 20px;
   
}
.loader-container{
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/UserDetails/UpdatePermissions/UpdatePermissions.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,uBAAuB;IACvB,mCAAmC;IACnC,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,mCAAmC;AACvC;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;AACjB;AACA;IACI,6BAA6B;IAC7B,WAAW;IACX,eAAe;;AAEnB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".accordion-button{\n    width:100%;\n    background-color: white;\n    border:1px solid rgb(174, 173, 173);\n    padding-top:5px;\n    padding-left:10px;\n}\n.accordion-per{\n    border:1px solid rgb(174, 173, 173);\n}\n.permission-name{\n    margin-left: 10px;\n}\n.accordian-content-col{\n    display: flex;\n}\n.close-Btn{\n    background-color: transparent;\n    border:none;\n    font-size: 20px;\n   \n}\n.loader-container{\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
