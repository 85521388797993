// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.punchIn-card{
    justify-content: space-between;
    width:inherit;
    background: aliceblue;
}
.day{
  
    font-size:medium;
}
.date{
    color: rgba(0, 0, 0, 0.6);
    font-size:medium;
}
.shift{
    font-size: medium;
}
.clock-container{
    margin-left:10px;
    margin-top:35px;
}
.time{
    
    text-align: right;
    margin-bottom: 25px;
    margin-right: 20px;
    margin-top: 25px;
}
.punch-in-details{
    background-color: antiquewhite;
    text-align: center;
    margin-top: -44px;
}
.location-info {
    cursor: pointer;
    position: relative;
}

.location-info .fas.fa-sync-alt {
    transition: transform 0.3s ease;
}

.location-info.clicked .fas.fa-sync-alt {
    animation: moveAround 1s infinite alternate ease-in-out;
}

@keyframes moveAround {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

`, "",{"version":3,"sources":["webpack://./src/components/Pages/Attendance/PunchIn/PunchIn.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;IAC9B,aAAa;IACb,qBAAqB;AACzB;AACA;;IAEI,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;;IAEI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,uDAAuD;AAC3D;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":["\n.punchIn-card{\n    justify-content: space-between;\n    width:inherit;\n    background: aliceblue;\n}\n.day{\n  \n    font-size:medium;\n}\n.date{\n    color: rgba(0, 0, 0, 0.6);\n    font-size:medium;\n}\n.shift{\n    font-size: medium;\n}\n.clock-container{\n    margin-left:10px;\n    margin-top:35px;\n}\n.time{\n    \n    text-align: right;\n    margin-bottom: 25px;\n    margin-right: 20px;\n    margin-top: 25px;\n}\n.punch-in-details{\n    background-color: antiquewhite;\n    text-align: center;\n    margin-top: -44px;\n}\n.location-info {\n    cursor: pointer;\n    position: relative;\n}\n\n.location-info .fas.fa-sync-alt {\n    transition: transform 0.3s ease;\n}\n\n.location-info.clicked .fas.fa-sync-alt {\n    animation: moveAround 1s infinite alternate ease-in-out;\n}\n\n@keyframes moveAround {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
