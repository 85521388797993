import React, { useContext } from "react";
import ApexCharts from "react-apexcharts";
import Chart from "react-apexcharts";
import { AppContext } from "../../../../../AppContext";

function AttendanceReport({attendacePerformance}) {
  const {isMobView}=useContext(AppContext)
  const options_1 = {
    series: [attendacePerformance?.regular_hours, attendacePerformance?.overtime_hours],
    chart: {
      height: 390,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "58%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
        barLabels: {
          enabled: true,
          useSeriesColors: false,
          margin: 8,
          fontSize: "12px",
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
        },
      },
    },
    colors: ["#7647EB", "#38DD2A"],
    labels: ["Regular Hrs", "Overtime Hrs"],
    responsive: [
      {
        breakpoint: 480,
        options_1: {
          legend: {
            show: true,
          },
        },
      },
    ],
  };
  return (
    <div
      id="chart"
      style={{
        backgroundColor: "white",
        paddingTop: "10px",
        position: "relative",
        borderRadius: "12px",
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        marginTop: isMobView?"20px":0,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        height: isMobView? "350px" :"100%",
      
      }}
    >
      <span
        style={{
          fontWeight: "600",
          fontSize: "16px",
          color: "black",
          padding: "15px",
          paddingTop: "25px",
        }}
      >
        Performance Summary
      </span>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Total Hrs</div>
        <div
          style={{
            fontSize: "30px",
            fontWeight: "700",
            color: "black",
          }}
        >
            {attendacePerformance?.total_hours}
        </div>
      </div>
      <ApexCharts
        options={options_1}
        series={options_1.series}
        type="radialBar"
        height={390}
      />
    </div>
  );
}

export default AttendanceReport;
