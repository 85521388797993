// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.signup-form{
    height: 70%;
    width: 50%;
    border: none;
    border-radius: 5px;
    padding: 25px;
    background-color: white !important;
}

.signup-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    /* background-color:  rgb(239, 237, 237) !important; */

}

.btn-signup{
    background-color: rgb(247, 136, 95) !important;
    border: none !important;
    margin-top: 5px;

}

.btn-signup:hover{
    background-color:rgb(247, 120, 74) !important ;
    border: none !important;
}


h1{
    color: rgb(247, 136, 95) !important;
    
}

@media (max-width: 600px) {
    .signup-container {
        padding: 10px;
    }

    .signup-form {
        height: 70%;
        width: 95%;
    }
}

/* .LoginSignup-container{
    background-color: rgb(239, 237, 237);
} */

.link-tag{
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Register/Register.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,sDAAsD;;AAE1D;;AAEA;IACI,8CAA8C;IAC9C,uBAAuB;IACvB,eAAe;;AAEnB;;AAEA;IACI,8CAA8C;IAC9C,uBAAuB;AAC3B;;;AAGA;IACI,mCAAmC;;AAEvC;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,UAAU;IACd;AACJ;;AAEA;;GAEG;;AAEH;IACI,qBAAqB;AACzB","sourcesContent":["\n.signup-form{\n    height: 70%;\n    width: 50%;\n    border: none;\n    border-radius: 5px;\n    padding: 25px;\n    background-color: white !important;\n}\n\n.signup-container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    flex-wrap: wrap;\n    /* background-color:  rgb(239, 237, 237) !important; */\n\n}\n\n.btn-signup{\n    background-color: rgb(247, 136, 95) !important;\n    border: none !important;\n    margin-top: 5px;\n\n}\n\n.btn-signup:hover{\n    background-color:rgb(247, 120, 74) !important ;\n    border: none !important;\n}\n\n\nh1{\n    color: rgb(247, 136, 95) !important;\n    \n}\n\n@media (max-width: 600px) {\n    .signup-container {\n        padding: 10px;\n    }\n\n    .signup-form {\n        height: 70%;\n        width: 95%;\n    }\n}\n\n/* .LoginSignup-container{\n    background-color: rgb(239, 237, 237);\n} */\n\n.link-tag{\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
