import React, { useState, useEffect } from 'react';
import { Modal, Spinner, Button, Badge } from "react-bootstrap";
import { fetchReporteefromManager, removeManagerId } from '../../../Services/CommonServices';
import "./ManageReportee.css";

const ManageReportee = ({ manageAttendee, setManageAttendee, user }) => {
    const [loading, setLoading] = useState(false);
    const [reportees, setReportees] = useState([]);
    const [error, setError] = useState(null);
    const [removedReporteeIds, setRemovedReporteeIds] = useState([]);

    useEffect(() => {
        if (user) {
            setLoading(true);
            fetchReporteefromManager({ userId: user._id })
                .then(response => {
                    setReportees(response.data.data);
                    setLoading(false);
                    setError(null);
                })
                .catch(error => {
                    console.error("Error fetching reportees:", error);
                    setLoading(false);
                    setError("Error fetching reportees. Please try again later.");
                });
        }
    }, [user]);

    function handleClose() {
        setReportees([]);
        setError(null);
        setManageAttendee(false);
    }

    function handleRemoveReportee(reporteeId) {
        const updatedReportees = reportees.filter(reportee => reportee._id !== reporteeId);
        setReportees(updatedReportees);
        setRemovedReporteeIds(prev => [...prev, reporteeId]);
        console.log("Removed reportee ", reporteeId);
    }

    function handleSubmit() {
        if (removedReporteeIds.length > 0) {
            setLoading(true);
            removeManagerId({ userId: user._id, reporteeIds: removedReporteeIds })
                .then(response => {
                    console.log("Successfully removed reportees:", response.data);
                    setRemovedReporteeIds([]);
                    setLoading(false);
                    handleClose();
                })
                .catch(error => {
                    console.error("Error removing reportees:", error);
                    setLoading(false);
                    setError("Error removing reportees. Please try again later.");
                });
        } else {
            handleClose();
        }
    }

    return (
        <Modal show={manageAttendee} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Manage Reportees</Modal.Title>
                <button className='Manage-reportee-modal-btn' onClick={handleClose}>
                    <i className="fas fa-times"></i>
                </button>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="loader-container">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    </div>
                ) : error ? (
                    <div className="error-message">{error}</div>
                ) : (
                    <div>
                        {reportees.length === 0 ? (
                            <h2 className='text-danger'>Reportee Not Found</h2>
                        ) : (
                            reportees.map(reportee => (
                                <Badge key={reportee._id} bg="light" pill style={{ marginRight: '5px' }}>
                                    {reportee.name}
                                    <span
                                        className="badge-close"
                                        onClick={() => handleRemoveReportee(reportee._id)}
                                        style={{ marginLeft: '5px', cursor: 'pointer', color: 'black' }}
                                    >
                                        &times;
                                    </span>
                                </Badge>
                            ))
                        )}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading || removedReporteeIds.length === 0}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ManageReportee;
