import React, { useState } from "react";
import { Button, Card, Container, FormGroup, Input, Label, Row , Spinner } from "reactstrap";
import { csvUpload } from "../../Services/CommonServices";
import { toastError, toastSuccess } from "../../Shared/ToastMsgs";

export default function CsvUploadUsers({getUser}) {
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (file) {
            const formData = new FormData();
            formData.append("file", file); 
            setLoading(true);
            try {
                const response = await csvUpload(formData);
                if(response?.data?.status=== 200){
                    toastSuccess(response?.data?.msg);
                    getUser();
                    setFile(null);
                    document.getElementById('csvFileInput').value = "";
                }else{
                    toastError(response?.data?.msg);
                }
            } catch (error) {
                toastError(error.data.msg);
            }
            finally {
                setLoading(false); 
            }
        } else {
            toastError("No file selected");
        }
    };
    
    const downloadSampleCSV = () => {
        const csvContent = "name,number,email,org_id\n"; 
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sample.csv'; 
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    return (
        <Container>
            {loading && (
                <div style={{
                    position: 'fixed', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    zIndex: 9999
                }}>
                    <Spinner color="primary" />
                </div>
            )}
            <Row>
                <Card className="col-8 cvsform">
                    <form>
                        <FormGroup>
                            <Label for="exampleFile" className="form-control">
                                File
                            </Label>
                            <Input
                                id={"csvFileInput"}
                                name="file"
                                type="file"
                                className="form-control csvinput"
                                accept={".csv"}
                                onChange={handleOnChange}
                            />
                            <Button onClick={(e) => handleOnSubmit(e)}>UPLOAD CSV FILE</Button>
                        </FormGroup>
                    </form>
                </Card>
            </Row>
            <Row>
                <Card className="col-8 cvsform">
                    <Button onClick={downloadSampleCSV}>Download Sample CSV</Button>
                </Card>
            </Row>
        </Container>
    );
}
