import React from 'react';
import { Card, Table } from 'reactstrap';
import './TableComponent.css';

function TableComponent() {
  return (
    <Card className="col-11 card table-responsive table-radius">

      <Table className="align-items-center table-custom  table-radius" >
        <thead>
          <tr >
            <th className='tr-th'>S. no</th>
            <th className='header-style'>Date</th>
            <th>Sign In</th>
            <th>Sign Out</th>
            <th>Shift Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>16/06/24</td>
            <td>09:32 AM</td>
            <td>06:32 PM</td>
            <td>09:32 AM - 06:30 PM</td>
            <td className="status-on-time-custom">
              <span className="status-indicator-custom"></span>On Time
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>15/06/24</td>
            <td>11:32 AM</td>
            <td>07:12 PM</td>
            <td>09:32 AM - 06:30 PM</td>
            <td className="status-late-custom">
              <span className="status-indicator-custom"></span>Late
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>15/06/24</td>
            <td>10:00 AM</td>
            <td>07:02 PM</td>
            <td>09:32 AM - 06:30 PM</td>
            <td className="status-late-custom">
              <span className="status-indicator-custom"></span>Late
            </td>
          </tr>
        </tbody>
      </Table>
     </Card>
  );
}

export default TableComponent;
