// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-nav .sidebar-btn .nav-link{
    font-size: larger;
}

.navbar-vertical .navbar-nav .nav-link.active:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.25rem;
    bottom: 0.25rem;
    border-left: 5px solid #7647EB;
    border-radius: 30px;
}

.sidebar-items-container{
    margin-left:10px;
}
.navbar-vertical{
    /* -ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none; */ 
  overflow: hidden !important;
  margin-top: 34px;
}

@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
        top: 0.25rem;
        bottom: 0.25rem;
        left: 0;
        right: auto;
        border-left: 5px solid #7647EB;
        border-bottom: 0;
        border-radius: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,YAAY;IACZ,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,8CAA8C;EAChD,2BAA2B;EAC3B,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;IACI;QACI,YAAY;QACZ,eAAe;QACf,OAAO;QACP,WAAW;QACX,8BAA8B;QAC9B,gBAAgB;QAChB,mBAAmB;IACvB;AACJ","sourcesContent":[".navbar-nav .sidebar-btn .nav-link{\n    font-size: larger;\n}\n\n.navbar-vertical .navbar-nav .nav-link.active:before {\n    content: \"\";\n    position: absolute;\n    left: 0;\n    top: 0.25rem;\n    bottom: 0.25rem;\n    border-left: 5px solid #7647EB;\n    border-radius: 30px;\n}\n\n.sidebar-items-container{\n    margin-left:10px;\n}\n.navbar-vertical{\n    /* -ms-overflow-style: none;  /* IE and Edge */\n  /* scrollbar-width: none; */ \n  overflow: hidden !important;\n  margin-top: 34px;\n}\n\n@media (min-width: 768px) {\n    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {\n        top: 0.25rem;\n        bottom: 0.25rem;\n        left: 0;\n        right: auto;\n        border-left: 5px solid #7647EB;\n        border-bottom: 0;\n        border-radius: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
