import React from 'react'
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as SearchIcon } from "../../../assets/img/CardImg/searchButton.svg"
// import TableComponent from "../../../Shared/TableComponent/TableComponent";
import DatePickerAntd from '../../Pages/Attendance/AttendanceInfo/DatePickerAntd';

function AttendanceAdminHeader({dateFilter}) {
  return (
    <div><div className="d-flex align-items-center attendance-searchbar-date">
    <div className="searchbar-container" >
      <p className="m-0 search-text">Search</p>
      <div className="searchbar">
        <input type="search" className="attendance-searchbar" placeholder="Name, Email, Employee ID" name="" id="" />
        <SearchIcon className="cursor-pointer" />
      </div>
    </div>
    <div className="dateBox d-flex flex-column">
      <p className="m-0 search-text">Date Range</p>
      <DatePickerAntd/>
      {/* <DatePicker style={{width: "50%"}}
      id="datePicker-1"
      value={startDate}
      onChange={(e)=>{setStartDate(new Date(e).toISOString())}}
      formatStyle="large"
      />
    <DatePicker style={{width: "50%"}}
      id="datePicker-1"
      value={endDate}
      onChange={(e)=>{setEndDate(new Date(e).toISOString())}}
      formatStyle="large"
      /> */}
    </div>
    <button
     onClick={dateFilter}
      className="searchbar-search-btn">Search</button>
    </div></div>
  )
}

export default AttendanceAdminHeader