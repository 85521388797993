import React, { useContext, useEffect } from "react";
import Chart from "react-apexcharts";
import { AppContext } from "../../../../../AppContext";

function AttendanceSummary({ attendaceSummary }) {
  const { value, isMobView } = useContext(AppContext);

  useEffect(() => {
    // console.log("summary", attendaceSummary);
  }, [attendaceSummary]);

  const options = {
    chart: {
      type: "donut",
      height: "100%",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "85%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#7647EB", "#FE5520", "#38DD2A", "#FFD233"],
    labels: ["Present", "Late", "Early Leave", "Absent"],
    legend: {
      position: "bottom",
      formatter: function (seriesName, opts) {
        return `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = [
    attendaceSummary?.day_present?.value,
    attendaceSummary?.days_late?.value,
    attendaceSummary?.day_left_early?.value,
    attendaceSummary?.days_absent?.value,
    attendaceSummary?.days_overtime?.value,
  ];
  return (
    <div
      id="chart"
      style={{
        backgroundColor: "white",
        paddingTop: "10px",
        position: "relative",
        marginTop: "20px",
        borderRadius: "12px",
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        height: isMobView ? "340px" : "100%",
      }}
    >
      <span
        style={{
          fontWeight: "600",
          fontSize: "16px",
          color: "black",
          padding: "15px",
          paddingTop: "25px",
        }}
      >
        Attendance Summary
      </span>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Total Days</div>
        <div
          style={{
            fontSize: "30px",
            fontWeight: "700",
            color: "black",
          }}
        >
          {attendaceSummary?.total_Attendance?.value}
        </div>
      </div>
      <Chart options={options} series={series} type="donut" height="100%" />
    </div>
  );
}

export default AttendanceSummary;
