import React, { useState, useEffect } from "react";
import "./Shift.css";
import { toastError, toastSuccess } from "../../Shared/ToastMsgs";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import PageComponent from "../../Shared/PageComponent/PageComponent";

const Shift=()=> {
  return (
    <div>
      <PageHeader
        pageTitle="Departments"
        breadCrumbs={[
          {
            name: "Home",
            path: "/",
          },
          {
            name: "Departments",
            path: "/department",
          },
        ]}
      />
      <PageComponent
        headerTitle="Shifts"
        buttonText="Add Shifts"
        // tableComponent={DesignationTable}
        // formComponent={CreateUpdateDesignation}
        // tableProps={designationTableProps}
        // formProps={designationFormProps}
      />
    </div>
  );
}

export default Shift;
