import React, { useState, useEffect } from "react"
import { Button } from "reactstrap"
import { BiSolidEdit } from "react-icons/bi"
import { RiDeleteBin5Fill } from "react-icons/ri"
import { Table } from "reactstrap"
import Modal from 'react-bootstrap/Modal';
import {  getNetworkFunction, getNetworkStatusFunction, deleteNetworkFunction, createNetworkFunction, createNetworkStatusFunction } from "../../Services/CommonServices"
import { Card, Container, Row } from "reactstrap"
import { toastError, toastSuccess } from "../../Shared/ToastMsgs"
import UpdateNetworkModal from "./UpdateNetworkModal/UpdateNetworkModal"
import "./Network.css"



export default function Networks() {
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [network, setNetwork] = useState([])
    const [deleteID,setDeleteID]=useState()
    const[checked,setChecked]=useState(false)
    
    const [networkID,setNetworkID]=useState('')
    // const [data,setData]=useState({
    //     id:"",
    //     address:"",
    //     status:"",
    // })
    let data=[{
        id:"",
        address:"",
        status:""
    }]
    
    useEffect(() => {
        const tdElement = document.getElementById('iDDD');
        if (tdElement) {
          const tdValue = tdElement.innerText;
          setNetworkID(tdValue);
        }

        getNetwork();
        getNetworkStatus()
      }, []); 
    

    function deleteNetwork() {
        deleteNetworkFunction(`${deleteID}`).then(reply => {
            if (reply?.status === 200) {
                toastSuccess();
                setDeleteID(null)
                getNetwork();
            } else {
                toastError();
            }
        })
    }

    const getNetworkStatus = () => {
        getNetworkStatusFunction(`${networkID}`).then(reply => {
            if (reply?.status === 200) {
                toastSuccess();
            }
            else {
                toastError()
            }
        }).catch(error => {
            
        })
    }

    const getNetwork = () => {
        getNetworkFunction().then(reply => {
           
            if (reply?.status === 200) {
                setNetwork(reply.data.data)
                toastSuccess();
                setShowUpdateModal(false);
            }
            else {
                toastError()
            }
        }).catch(error => {
            
        })
    }
    function createNetworkStatus(){
        createNetworkStatusFunction({id:data.id,address:data.address,status:data.status}).then(reply=>{
            if(reply?.status===200){
                toastSuccess()
                
            }
            else{
                toastError()
            }
        }).catch(error=>{
           
        })
    }

    const handleChange=(e,id,address)=>{
        setChecked(!checked)
            data.id=id;
            data.address=address;
            data.status=e.target.value
            createNetworkStatus();
    }
    

    return (
        <Container fluid>
            {showUpdateModal && <UpdateNetworkModal showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} getNetwork={getNetwork} />}
            <div className=" d-flex heading-box">
                <h2>Networks</h2>
                <Button onClick={() => { setShowUpdateModal(true) }}>Create Network</Button>
            </div>
            <Row>
                <Card className="col-10 card table-responsive">
                    <Table className="align-items-center ">
                        <thead className="thead-light">
                            <tr>
                                <th>Network Address</th>
                                <th>id</th>
                                <th>toggle</th>
                                <th>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {network.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item?.address}</td>
                                        <td  id="iDDD">{item?._id}</td>
                                        <td><input 
                                        type="checkbox" 
                                        id={item?._id}
                                        name={item?.address}
                                        defaultChecked={item?.status===true?true:false}
                                        // className="network-input" 
                                        value={item?.status===true?!checked:checked}
                                        onChange={(e)=>{handleChange(e,item?._id,item?.address,item?.status)}}
                                        />
                                        <label htmlFor={item?._id} className="network-label">Toggle</label>
                                        </td>
                                        <td><Button  onClick={() => { setDeleteID(item?._id) }}><RiDeleteBin5Fill /></Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card>
            </Row>
            {deleteID && <Modal show={true} onHide={() => setDeleteID(null)}>
                    <Modal.Header >
                        <Modal.Title>Delete Department</Modal.Title>
                        <button type="button" className="ni ni-fat-remove  close-Btn" onClick={()=>{setDeleteID(null)}} ></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-between">
                            Are you sure you want to delete this department?
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setDeleteID(null)}>
                            Close
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => deleteNetwork()}
                        >
                            delete
                        </Button>
                    </Modal.Footer>
                </Modal>}
        </Container>

    )
}