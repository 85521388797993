import React, { useEffect, useState } from 'react'
import "./ManageUserEmpFlow.css"
import { ReactComponent as CardImg } from "../../../assets/img/CardImg/cardimg.svg"
import AssignManagerModal from './AssignManagerModal'
import { getDepartments } from '../../Services/CommonServices'
import ManagerFlowDetailsCard from '../MangerFLow/ManagerFlowDetailsCard'

function ManageUserEmpFlowCards({isAssignManger,submitHandler,modalOpen,setModalOpen}) {
  const [isAllDepartment,setAllDepartment]=useState([])
  // const [modalOpen, setModalOpen] = useState(false);
  
  
    useEffect(()=>{
      getDepartments().then((response)=>{
       setAllDepartment(response?.data?.data) 
      }).catch(err=>console.log(err.message,"err"))

    },[])
  const handleClose=()=>{
    setModalOpen(false) 
  }
  return (
<>
{isAssignManger && isAssignManger.length==0 && <p className='emp-change-manager' onClick={()=>setModalOpen(true)}>Do you want to change Manager ?</p>}
<AssignManagerModal open={modalOpen}  isAllDepartment={isAllDepartment} handleClose={handleClose}/>
{isAssignManger?.map((item,index)=>{

  return<div>
    <ManagerFlowDetailsCard CardImg={CardImg} item={item}/>
  <div>
  <p className='emp-change-manager' onClick={()=>setModalOpen(true)}>Do you want to change Manager ?</p>
</div>
</div>
})}
  </>
  )
}

export default ManageUserEmpFlowCards
