import React, {useContext} from "react"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card"
import { Container} from "reactstrap";
import "./PageHeader.css";
import Tooltip from '@mui/material/Tooltip';
import PageHeaderImg from "../../../assets/img/header-img.png"
// import {ApiContext} from "../../ApiContextProvider/ApiContext";
import {useLocation} from "react-router-dom";
import Attendance from "../../Pages/Attendance/Attendance";
const PageHeader =({pageTitle,breadCrumbs,name,btn})=>{
    const event=JSON.parse(localStorage.getItem("event_data"))
    // const {isMobile} = useContext(ApiContext)
    const {pathname}=useLocation()
    return(
        <div className="page-header-container">
        
                <div className="d-flex justify-content-between">
                   
                    <div>
                    {pathname!== "/home" && <Breadcrumb>
                        {breadCrumbs.map((item,index)=>{
                            return(
                                <Breadcrumb.Item active={pathname===item.path} className="pages-breadcrumb " href={item.path} key={index}>{item.name}</Breadcrumb.Item>
                            )
                        })}
                    </Breadcrumb>}
                        <div className={pathname=== "/home" ? "mt-5" : "mt-3"}>
                            {/* <h1 className="page-header-title">Good Morning,</h1> */}
                            <h1 className="page-header-title text-left font-style">{pageTitle}</h1>
                            {pathname=== "/home" && <p style={{color:"white"}}>Let's be productive today !  🚀</p>}
                        </div>
                            
                        <div>
                            {btn}
                        </div>
                    </div>
                    {/* <PageHeaderImg /> */}
                    <div><img src={require("../../../assets/img/header-img.png")}  alt="headerImage" style={{width:"450px"}}/></div>
                </div>
                
        </div>
    )
}

export default PageHeader;