import React, { useContext, useEffect, useState } from "react";
import { getHomeData, getLastAttendance } from "../../Services/CommonServices";
import "./Home.css";
import { AppContext } from "../../../AppContext";
import HomeMobile from "./HomeMobile";
import HomeDesktop from "./HomeDesktop";

function Home({ logoutHandler }) {
  const { value, isMobView } = useContext(AppContext);
  const [homeData, setHomeData] = useState([]);
  const admin = false;
  const manager = value?.role?.name === "Manager";
  const employee = value?.role?.name === "employee";

  useEffect(() => {
    getHomeData()
      .then((response) => {
        setHomeData(response?.data);
      })
      .catch((err) => console.log(err, "err"));
  }, []);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Morning";
    } else if (currentHour < 16) {
      return "Afternoon";
    } else {
      return "Evening";
    }
  };

  return (
    <>
      {isMobView ? (
        <HomeMobile
          homeData={homeData}
          logoutHandler={logoutHandler}
          getGreeting={getGreeting}
        />
      ) : (
        <HomeDesktop
          getGreeting={getGreeting}
          homeData={homeData}
          employee={employee}
          manager={manager}
          admin={admin}
        />
      )}
    </>
  );
}

export default Home;
