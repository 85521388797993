import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { createNetworkFunction, getDepartmentFuntion } from "../../../Services/CommonServices"
import { toastError, toastSuccess } from "../../../Shared/ToastMsgs";


const UpdateNetworkModal = (props) => {
    const { showUpdateModal, setShowUpdateModal, getNetwork} = { ...props };
    const [data, setData] = useState({
        address: ""
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    }

    function createNetwork() {
        createNetworkFunction({ address: data.address }).then(reply => {
            if(reply.status===200){
                toastSuccess();
                getNetwork();
            }
            else{
                toastError();
            }
        }).catch(error => {
            
        })
    }
    return <Modal show={true} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header >
            <Modal.Title>create Network</Modal.Title>
            <button type="button" className="ni ni-fat-remove  close-Btn" onClick={()=>{setShowUpdateModal(false)}} ></button>
        </Modal.Header>
        <Modal.Body>
            <label >Network address</label>
            <input
                className="form-control"
                placeholder=" network address"
                name="address"
                value={data.address}
                onChange={handleChange}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
                Close
            </Button>
            <Button
                variant="secondary"
                onClick={createNetwork}
            >
                create
            </Button>
        </Modal.Footer>
    </Modal>
}

export default UpdateNetworkModal;