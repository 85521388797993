import { Carousel } from 'antd';
import React from 'react';
import Register1 from "../../assets/img/brand/register1.webp"
import Register2 from "../../assets/img/brand/register2.webp"
import Register3 from "../../assets/img/brand/register3.webp"



function Crousel() {
  return (
    <Carousel autoplay autoplaySpeed={1000}> {/* 1000 milliseconds = 1 second */}
    <div>
      <img src={Register1} className="slider-img" />
    {/* <img className='slider-img' src={Register1} alt="" srcset="" /> */}
    </div>
    <div>
      <img src={Register2} className="slider-img" />
    {/* <img className='slider-img' src={Register2} alt="" srcset="" /> */}
    </div>
    <div>
      <img src={Register3} className="slider-img" />
   {/* <img className='slider-img' src={Register3} alt="" srcset="" /> */}
    </div>
  
  </Carousel>

  )
}

export default Crousel
