import React, { useState, useEffect } from "react";
import "./Designation.css";
import {
  deleteDesignationFunction,
  createDesignationFunction,
  updateDesignationFunction,
  getDesignationFunction,
} from "../../Services/CommonServices";
import CreateUpdateDesignation from "./CreateUpdateDesignation/CreateUpdateDesignation";
import { toastError, toastSuccess } from "../../Shared/ToastMsgs";
import PageHeader from "../../Shared/PageHeader/PageHeader";
import PageComponent from "../../Shared/PageComponent/PageComponent";
import DesignationTable from "./DesignationTable";

export default function Designation() {
  const [deleteId, setdeleteId] = useState(null);
  const [designation, setDesignation] = useState([]);
  const [updateData, setUpdateData] = useState(null);

  useEffect(() => {
    getDesignationData();
  }, []);

  const createUpdateData = (data) => {
    if (data && data._id) {
      updateDesignation(data);
    } else {
      addDesignation(data);
    }
  };

  const deleteDesignation = () => {
    deleteDesignationFunction(`${deleteId}`)
      .then((reply) => {
        if (reply?.status === 200) {
          toastSuccess();
          getDesignationData();
          setdeleteId(null);
        } else {
          toastError();
        }
      })
      .catch((error) => {
        toastError();
      });
  };

  const updateDesignation = (data) => {
    updateDesignationFunction(`${data._id}`, {
      name: data.name,
      department: data.department._id,
    })
      .then((reply) => {
        if (reply?.status === 200) {
          toastSuccess();
          getDesignationData();
        } else {
          toastError();
        }
      })
      .catch((error) => {
        toastError();
      });
  };

  const addDesignation = (data) => {
    createDesignationFunction({
      name: data.name,
      department: data.department._id,
    })
      .then((reply) => {
        if (reply?.status === 201) {
          console.log("success");
          toastSuccess();
          getDesignationData();
        } else {
          toastError();
        }
      })
      .catch((error) => {
        toastError();
      });
  };

  const getDesignationData = () => {
    getDesignationFunction()
      .then((reply) => {
        if (reply?.status === 200) {
          setDesignation(reply.data.data);
          toastSuccess();
        } else {
          toastError();
        }
      })
      .catch((error) => {
        toastError();
      });
  };

  const designationTableProps = {
    designation: designation,
    setUpdateData: setUpdateData,
  };

  const designationFormProps = {
    designation: designation,
    updateData: updateData,
    createUpdateData: createUpdateData,
  };

  return (
    <div>
      <PageHeader
        pageTitle="Departments"
        breadCrumbs={[
          {
            name: "Home",
            path: "/",
          },
          {
            name: "Departments",
            path: "/department",
          },
        ]}
      />
      <PageComponent
        headerTitle="Total Count"
        buttonText="Add Designation"
        tableComponent={DesignationTable}
        formComponent={CreateUpdateDesignation}
        tableProps={designationTableProps}
        formProps={designationFormProps}
      />
      {/* {deleteId && <Modal show={!!deleteId} onHide={() => setdeleteId(null)}>
                <Modal.Header>
                    <Modal.Title>Delete Designation</Modal.Title>
                    <button type="button" className="ni ni-fat-remove close-Btn" onClick={() => setdeleteId(null)}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        Are you sure you want to delete this designation?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setdeleteId(null)}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={deleteDesignation}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>} */}
    </div>
  );
}
