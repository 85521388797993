// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.heading-box{
    justify-content: space-between;
    padding: 20px;
}
.card{
    margin-left: auto;
    margin-right: auto;
}
.table-component-div{
    border-radius: 12px;
        border: 1px solid #D1D1D1;
}
.thead-light{
    background-color: #F8FAFC !important;
}

 thead th{
    border-right: 1px solid  #D1D1D1;
 }
 tbody td{
    border-right: 1px solid  #D1D1D1;
 }

 .table .thead-light th{
    color:black !important;
 }
 .submit-btn{
    float: right;
    margin-top: 20px;
    color: white;
    background: #7647EB;
    border-radius: 100px;
    padding: 10px 20px;
 }
 .MuiAutocomplete-hasPopupIcon.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root{
    font-size:small !important;
    padding: 5px !important;
 }
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Department/Department.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;IAC9B,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,mBAAmB;QACf,yBAAyB;AACjC;AACA;IACI,oCAAoC;AACxC;;CAEC;IACG,gCAAgC;CACnC;CACA;IACG,gCAAgC;CACnC;;CAEA;IACG,sBAAsB;CACzB;CACA;IACG,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;CACrB;CACA;IACG,0BAA0B;IAC1B,uBAAuB;CAC1B","sourcesContent":["\n.heading-box{\n    justify-content: space-between;\n    padding: 20px;\n}\n.card{\n    margin-left: auto;\n    margin-right: auto;\n}\n.table-component-div{\n    border-radius: 12px;\n        border: 1px solid #D1D1D1;\n}\n.thead-light{\n    background-color: #F8FAFC !important;\n}\n\n thead th{\n    border-right: 1px solid  #D1D1D1;\n }\n tbody td{\n    border-right: 1px solid  #D1D1D1;\n }\n\n .table .thead-light th{\n    color:black !important;\n }\n .submit-btn{\n    float: right;\n    margin-top: 20px;\n    color: white;\n    background: #7647EB;\n    border-radius: 100px;\n    padding: 10px 20px;\n }\n .MuiAutocomplete-hasPopupIcon.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root{\n    font-size:small !important;\n    padding: 5px !important;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
