import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../../assets/img/si_Arrow_left.svg";
import "../Regularization/Regularization.css";
import {
  getPendingRegularizationFunction,
  getRegularizationReasons,
  getUserAttendanceRegularize,
  submitRegularization,
} from "../../../Services/CommonServices";
import AttendanceDetail from "../../../Pages/Attendance/shared/AttendanceDetail";
import LoginIcon from "../../../../assets/img/login_in.svg";
import LogoutIcon from "../../../../assets/img/login_out.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { toast } from "react-toastify";
import {
  convertIntoHoursMinute,
  formatDate,
  formatTime,
} from "../../../Services/UtilServices";
import { AppContext } from "../../../../AppContext";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const RegularizationMobile = () => {
  const [value, setValue] = useState(0);
  const [attendanceList, setAttendanceList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [remark, setRemark] = useState("");
  const [reason, setReason] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [updatedStartTime, setUpdatedStartTime] = useState(null);
  const [updatedEndTime, setUpdatedEndTime] = useState(null);
  const [currentAttendance, setCurrentAttendance] = useState([]);
  const [pendingHistoryData, setPendingHistoryData] = useState([]);
  const { setOpenModal, setSuccessText } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    getUserAttendanceRegularize()
      .then((res) => {
        setAttendanceList(res.data.data);
      })
      .catch((err) => {
        console.log("error in fetching attendance", err);
      });
  }, []);

  useEffect(() => {
    if (value) {
      const queryObj = { type: "own" };
      if (value > 1) {
        queryObj["status"] = "History";
      }
      getPendingRegularizationFunction(queryObj)
        .then((res) => {
          setPendingHistoryData(res?.data?.data || []);
        })
        .catch((err) => {
          console.log("error while getting record of pending history", err);
        });
    }
  }, [value]);


  const getReasons = () => {
    getRegularizationReasons()
      .then((res) => {
        setReason(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getReasons();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDraw = (data) => {
    if (data.manager && data.manager?.id) {
      setDrawerOpen(true);
      setCurrentAttendance(data);
    } else {
      toast.error("You need to select your manager first.");
      navigate("/profile-details");
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleInputChange = (e) => {
    setRemark(e.target.value);
  };

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleSubmit = () => {
    const applyingDate = new Date(currentAttendance.check_in_time)
      .toUTCString()
      .split(" ");
    const startTime = new Date(updatedStartTime?.$d)
      .toUTCString()
      .split(" ")[4];
    const endTime = new Date(updatedEndTime?.$d).toUTCString().split(" ")[4];
    const finalStartTime = [...applyingDate];
    finalStartTime[4] = startTime;
    const finalEndTime = [...applyingDate];
    finalEndTime[4] = endTime;
    const applyData = {
      reasonId: selectedReason,
      remarks: remark,
      applyStartTime: finalStartTime.join(" "),
      applyEndTime: finalEndTime.join(" "),
      attendanceId: currentAttendance.id,
      managerId: currentAttendance?.manager?.id,
    };
    submitRegularization(applyData)
      .then((res) => {
        setSuccessText(res?.data?.message);
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          navigate("/home");
        }, 2000);
      })
      .catch((err) => {
        console.log("error in applying regularization", err);
      });
  };

  const captureEndTime = (newValue) => {
    setUpdatedEndTime(newValue);
  };

  console.log("pendingHistoryData", pendingHistoryData);

  return (
    <div>
      <div style={{ height: "55px", display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "51%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Link to={"/home"}>
            <img src={ArrowLeft} />
          </Link>
          <span style={{ fontSize: "18px", fontWeight: "600", color: "black" }}>
            Regularization
          </span>
        </div>
      </div>

      <Box
        sx={{
          width: "100%",
          padding: "10px",
          height: "100vh",
          backgroundColor: "#F6F8FA",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Apply" {...a11yProps(0)} />
            <Tab label="Pending" {...a11yProps(1)} />
            <Tab label="History" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {(!attendanceList || attendanceList.length === 0) && (
            <h4>Hurray! No Pending Regularisation to Apply.</h4>
          )}
          {attendanceList?.map((attendanceData, index) => (
            <div key={index} onClick={() => handleDraw(attendanceData)}>
              <AttendanceDetail attendanceData={attendanceData} />
            </div>
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {(!pendingHistoryData || pendingHistoryData.length === 0) && (
            <h4>Hurray! No Pending Regularisation.</h4>
          )}
          {pendingHistoryData.map((regularData) => {
            return (
              <div className="container-pending-div">
                <div
                  className="modal-web-border"
                  style={{ padding: "10px 0px" }}
                >
                  <p className="applied-by m-0">Applied To</p>
                  <p className="applied-by-name">
                    {regularData?.applyTo?.name} (
                    {regularData?.applyTo?.empCode})
                  </p>
                </div>
                <div
                  className="modal-web-border"
                  style={{ padding: "10px 0px" }}
                >
                  <p className="applied-by m-0">Applied Date(s)</p>
                  <p className="applied-by-name">
                    {formatDate(regularData?.start_date)}
                  </p>
                </div>
                <div style={{ fontSize: "10px", color: "#7647EB" }}>
                  Regular shift {regularData?.user?.office?.shifts?.shift?.name}
                </div>
                <label
                  htmlFor="department"
                  className="profile-label"
                  style={{ marginTop: "5px" }}
                >
                  Captured Time
                </label>
                <div
                  className="d-flex justify-content-between modal-web-border"
                  //   style={{ padding: "10px 0px" }}
                >
                  <div
                    className="d-flex align-items-start"
                    style={{ columnGap: "12px" }}
                  >
                    <img src={LoginIcon} alt="" />
                    <div>
                      <p
                        className="applied-by-name m-0"
                        style={{ color: "red" }}
                      >
                        {formatTime(
                          regularData?.user?.attendances?.[0]?.check_in_time
                        )}
                      </p>
                      <p className="applied-by ">Actual Time</p>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-start"
                    style={{ columnGap: "12px" }}
                  >
                    <img src={LogoutIcon} alt="" />
                    <div>
                      <p className="applied-by-name m-0">
                        {formatTime(
                          regularData?.user?.attendances?.[0]?.check_out_time
                        )}
                      </p>
                      <p className="applied-by ">On Time</p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <label
                  htmlFor="department"
                  className="profile-label"
                  style={{ marginTop: "5px" }}
                >
                  Requested Time
                </label>
                <div
                  className="d-flex justify-content-between modal-web-border"
                  //   style={{ padding: "10px 0px" }}
                >
                  <div
                    className="d-flex align-items-start"
                    style={{ columnGap: "12px" }}
                  >
                    <img src={LoginIcon} alt="" />
                    <div>
                      <p className="applied-by-name m-0">
                        {formatTime(regularData?.start_date)}
                      </p>
                      <p className="applied-by ">Updated</p>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-start"
                    style={{ columnGap: "12px" }}
                  >
                    <img src={LogoutIcon} alt="" />
                    <div>
                      <p className="applied-by-name m-0">
                        {formatTime(regularData?.end_date)}
                      </p>
                      <p className="applied-by ">On Time</p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div style={{ padding: "10px 0px" }}>
                  <p className="m-0 reason-heading">Reason</p>
                  <p className="reason-border-style">
                    {regularData?.leaveReason?.name}
                  </p>
                </div>

                {/*<div className="d-flex  justify-content-end">*/}
                {/*    {*/}
                {/*        <button*/}
                {/*            className="reject-btn"*/}
                {/*            sx={{ mt: 2 }}*/}
                {/*            onClick={() => {*/}
                {/*                handleReject();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Reject*/}
                {/*        </button>*/}
                {/*    }*/}

                {/*    {*/}
                {/*        <button*/}
                {/*            className="approve-btn"*/}
                {/*            sx={{ mt: 2 }}*/}
                {/*            onClick={() => {*/}
                {/*                handleApproval();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Approve*/}
                {/*        </button>*/}
                {/*    }*/}
                {/*</div>*/}
              </div>
            );
          })}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {(!pendingHistoryData || pendingHistoryData.length === 0) && (
            <h4>No Regularisation History.</h4>
          )}
          {pendingHistoryData.map((historyData) => {
            return (
              <Typography>
                <div
                  style={{
                    height: "33vh",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "15px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#828282",
                        }}
                      >
                        Regularised By
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "black",
                          paddingTop: "5px",
                        }}
                      >
                        {historyData?.applyTo?.name} (
                        {historyData?.applyTo?.empCode})
                      </div>
                    </div>
                    <div>
                      <span
                        style={{
                          backgroundColor: "#7647EB",
                          color: "white",
                          borderRadius: "20px",
                          fontSize: "10px",
                          padding: "5px",
                        }}
                      >
                        {historyData?.status}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottom: ".5px solid #ACAFB51A",
                      marginTop: "15px",
                    }}
                  ></div>

                  <div style={{ marginTop: "25px" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#828282",
                      }}
                    >
                      Applied Date(s)
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                        paddingTop: "5px",
                      }}
                    >
                      {formatDate(historyData?.start_date)}
                    </div>
                  </div>

                  <div
                    style={{
                      borderBottom: ".5px solid #ACAFB51A",
                      marginTop: "15px",
                    }}
                  ></div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "25px",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#828282",
                          }}
                        >
                          Regularised on
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "black",
                            paddingTop: "5px",
                          }}
                        >
                          {formatDate(historyData?.updated_at)}
                        </div>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "#7647EB",
                            fontSize: "10px",
                          }}
                        >
                          01 Day
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Typography>
            );
          })}
        </CustomTabPanel>
      </Box>

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={closeDrawer}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            padding: 2,
            height: "550px",
          },
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Typography
            variant="h6"
            component="div"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div>
              <div
                style={{
                  height: "35px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "51%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div typeof="button" onClick={() => setDrawerOpen(false)}>
                    <img src={ArrowLeft} />
                  </div>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Regularization
                  </span>
                </div>
                <div style={{ fontSize: "10px", color: "#7647EB" }}>
                  Regular shift 09:30am to 06:30pm{" "}
                </div>
              </div>

              <div
                style={{
                  height: "40px",
                  marginTop: "25px",
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#828282",
                  }}
                >
                  Applying To
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  {currentAttendance?.manager?.name} (
                  {currentAttendance?.manager?.empCode})
                </div>
              </div>

              <div style={{ marginTop: "25px" }}>
                <label
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#828282",
                  }}
                >
                  Remark
                </label>
                <input
                  type="text"
                  placeholder="Write here"
                  onChange={handleInputChange}
                  value={remark}
                  className="reason-input"
                />
              </div>

              <div
                style={{
                  borderBottom: "1px dashed #828282",
                  marginTop: "25px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "25px",
                }}
              >
                <div>
                  <div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#828282",
                      }}
                    >
                      Date
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      {formatDate(currentAttendance?.check_in_time)}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="newTimeSelection">
                      <img src={LoginIcon} />
                    </div>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="__:__"
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                            }}
                            value={updatedStartTime}
                            onChange={(newValue) =>
                              setUpdatedStartTime(newValue)
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#828282",
                      }}
                    >
                      Check-in/check-out time
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      {formatTime(currentAttendance?.check_in_time)} -{" "}
                      {formatTime(currentAttendance?.check_out_time)}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="newTimeSelection">
                      <img src={LogoutIcon} />
                    </div>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="__:__"
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            value={updatedEndTime}
                            onChange={(newValue) => captureEndTime(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "25px" }}>
                <label htmlFor="department" className="profile-label">
                  Reason
                </label>
                <select
                  id="department"
                  name="department"
                  className="reason-input"
                  style={{ padding: "5px", backgroundColor: "white" }}
                  value={selectedReason}
                  onChange={handleReasonChange}
                >
                  <option value="">Select</option>
                  {reason?.map((data) => (
                    <option key={data?.id} value={data?.id}>
                      {data?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  backgroundColor: "white",
                  height: "40px",
                  width: "95px",
                  color: "#7647EB",
                  borderRadius: "100px",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Clear
              </button>
              <button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#7647EB",
                  height: "40px",
                  width: "95px",
                  color: "white",
                  borderRadius: "100px",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Submit
              </button>
            </div>
          </Typography>
          {/* Add any additional content or components for the drawer here */}
        </Box>
      </Drawer>
    </div>
  );
};

export default RegularizationMobile;
