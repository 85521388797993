import React, { createContext, useEffect, useState } from "react";
import { allOrganisation } from "../src/components/Services/CommonServices";

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [organisations, setOrganisations] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const user = JSON.parse(
    (localStorage.getItem("user_data") || "{}") == "undefined"
      ? "{}"
      : localStorage.getItem("user_data") || "{}"
  );
  const [value, setValue] = useState(user);
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobView, setIsMobView] = useState(window.innerWidth < 767);
  const [isPunch, setIsPunch] = useState(true);
  const [reporteeName, setReporteeName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [successText, setSuccessText] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobView(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AppContext.Provider
      value={{
        value,
        setValue,
        organisations,
        setUpdateTrigger,
        setOrganisations,
        updateTrigger,
        isMobView,
        setIsMobView,
        isDrawerOpen,
        setIsDrawerOpen,
        isPunch,
        setIsPunch,
        reporteeName,
        setReporteeName,
        openModal,
        setOpenModal,
        successText,
        setSuccessText,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
