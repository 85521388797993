// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f0f0;
    padding: 2rem;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #888;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  input[type="email"] {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* margin-bottom: 1rem; */
  }
  
  button[type="submit"] {
    padding: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    margin-right: 1rem;
  }
  
  a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Pages/ResetPasswordPage/ResetPasswordPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".reset-password-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;\n    background-color: #f0f0f0;\n    padding: 2rem;\n  }\n  \n  h1 {\n    font-size: 2rem;\n    margin-bottom: 1rem;\n  }\n  \n  p {\n    font-size: 1rem;\n    margin-bottom: 1rem;\n    color: #888;\n  }\n  \n  form {\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n  }\n  \n  label {\n    margin-bottom: 0.5rem;\n    font-weight: bold;\n  }\n  \n  input[type=\"email\"] {\n    padding: 1rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    /* margin-bottom: 1rem; */\n  }\n  \n  button[type=\"submit\"] {\n    padding: 1rem;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  a {\n    color: #007bff;\n    text-decoration: none;\n    margin-right: 1rem;\n  }\n  \n  a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
