// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.clock {
    width: 120px;
    height: 120px;
    background-color: white;
    border-radius: 50%;
    position: relative;
}

.clock::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: red;
    display: block;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-41%, -41%);
    z-index: 1;
    border-radius: 50%;
}

.clock__face {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.clock__face-mark {
    position: absolute;
    left: 50%;
    right: 50%;
    top: 0;
    bottom: 0;
}

.clock__hand {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
}

.clock__hand-body {
    background-color: black;
    width: 4px;
    top: 12%;
    bottom: 40%;
    position: absolute;
    transform: translate(-4px);
}

.clock__hand-body--hour {
    top: 22%;
}

.clock__hand-body--seconds {
    width: 4px;
    background-color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Attendance/PunchIn/Clock/Clock.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,WAAW;IACX,qBAAqB;IACrB,cAAc;IACd,QAAQ;IACR,kBAAkB;IAClB,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,MAAM;IACN,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,SAAS;IACT,UAAU;AACd;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,QAAQ;IACR,WAAW;IACX,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,UAAU;IACV,qBAAqB;AACzB","sourcesContent":["\n\n.clock {\n    width: 120px;\n    height: 120px;\n    background-color: white;\n    border-radius: 50%;\n    position: relative;\n}\n\n.clock::before {\n    content: \"\";\n    width: 8px;\n    height: 8px;\n    background-color: red;\n    display: block;\n    top: 50%;\n    position: absolute;\n    left: 50%;\n    transform: translate(-41%, -41%);\n    z-index: 1;\n    border-radius: 50%;\n}\n\n.clock__face {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n}\n\n.clock__face-mark {\n    position: absolute;\n    left: 50%;\n    right: 50%;\n    top: 0;\n    bottom: 0;\n}\n\n.clock__hand {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 50%;\n    right: 50%;\n}\n\n.clock__hand-body {\n    background-color: black;\n    width: 4px;\n    top: 12%;\n    bottom: 40%;\n    position: absolute;\n    transform: translate(-4px);\n}\n\n.clock__hand-body--hour {\n    top: 22%;\n}\n\n.clock__hand-body--seconds {\n    width: 4px;\n    background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
