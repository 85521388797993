import React, {useContext, useEffect, useState} from 'react'
import "./MangerAttendanceFlow.css"
import SortByDropDown from '../SortByDropDown'
// import {ReactComponent as CalenderImg} from "../../../../assets/img/CardImg/Calender.svg"
import ManagerFlowCards from '../ManagerFlowCards';
import AttendanceDetail from '../../../Pages/Attendance/shared/AttendanceDetail';
import AttendanceReport from '../../../Pages/Attendance/Attendancemobile/AttendancePieChart/AttendanceReport';
import AttendanceSummary from '../../../Pages/Attendance/Attendancemobile/AttendancePieChart/AttendanceSummary';
import {
    getUserAttendance,
    getUserAttendancePerformance,
    getUserAttendanceSummary
} from "../../../Services/CommonServices";
import {useParams} from "react-router-dom";
import { AppContext } from '../../../../AppContext';
import ManageUserEmpFlowCards from '../../EmployeFlow/ManageUserEmpFlowCards';
import ManagerFlowDetailsCard from '../ManagerFlowDetailsCard';

function MangerAttendanceFlow() {
    const{isReporteeName,setReporteeName}=useContext(AppContext)

    const [attendaceList, setAttendanceList] = useState([]);
    const [attendaceSummary, setAttendaceSummary] = useState(null);
    const [attendacePerformance, setAttendacePerformance] = useState(null);
    const params = useParams();

    useEffect(() => {
        if (params?.id) {
            // clearData();
            getData(params.id);
        } else {
            getData();
        }
    }, [params]);

    
    const clearData = () => {
        setAttendanceList([]);
        setAttendaceSummary(null);
        setAttendacePerformance(null);
    }

    const getData = (userId = null) => {
        getUserAttendance(userId).then(res => {
            setAttendanceList(res.data.data);
        }).catch(err => {
            console.log("error in fetching attendance", err);
        });

        getUserAttendanceSummary(userId).then(res => {
            setAttendaceSummary(res.data.data);
        }).catch(err => {
            console.log("error in fetching attendance summary", err);
        });

        getUserAttendancePerformance(userId).then(res => {
            setAttendacePerformance(res.data.data);
        }).catch(err => {
            console.log("error in fetching attendance summary", err);
        });
    }
 console.log(attendaceList?.user?.user_details,"list")
  return (
    <div style={{padding:"20px"}}>
      <div >
      <SortByDropDown heading={"Log from 1 June- 30 June, 2024"}/>
      </div>
      <div>
        {attendaceList?.user?.user_details && <ManagerFlowDetailsCard  item={attendaceList?.user?.user_details}/>}
        </div>
      <div>
     <ManagerFlowCards attendaceSummary={attendaceSummary} userData={attendaceList?.user} />
      
     <div className='row'>
        <div className='col-md-4'>
            {attendaceList?.user?.attendance?.map(attendanceData => {
                return <AttendanceDetail attendanceData={attendanceData}/>
            })}
        </div>
        <div className='col-md-4'>
            {attendaceSummary && <AttendanceSummary attendaceSummary={attendaceSummary} />}
        </div>
        <div className='col-md-4'>
            {attendacePerformance && <AttendanceReport attendacePerformance={attendacePerformance}    />}
        </div>
     </div>

      </div>
    </div>
  )
}

export default MangerAttendanceFlow
