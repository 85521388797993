import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import LoginIcon from "../../../assets/img/login_in.svg";
import LogoutIcon from "../../../assets/img/login_out.svg";
import Averagecon from "../../../assets/img/average.svg";
import CalendarIcon from "../../../assets/img/calendar.svg";
import AttPerson from "../../../assets/img/att_person.webp";
import LeavePerson from "../../../assets/img/leave_person.webp";
import ArrowRight from "../../../assets/img/arrow_right.svg";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import HomeBlack from "../../../assets/img/home_black.svg";
import HomeWhite from "../../../assets/img/home_white.svg";
import ProfileBlack from "../../../assets/img/profile_black.svg";
import ProfileWhite from "../../../assets/img/profile_white.svg";
import { toast } from "react-toastify";
import "./Home.css";
import OverViewCard from "./OverViewCard";
import ActionComp from "./ActionComp";
import HolidayCard from "./HolidayCard";
import HomeFooter from "./HomeFooter";

function HomeMobile({ homeData, logoutHandler, getGreeting }) {
  const { setIsPunch } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const name = getGreeting();
  const navigate = useNavigate();

  useEffect(() => {
    setIsPunch(homeData?.attendance?.check_in_time === "Yet to sign in");
  }, [homeData]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  function doPunchAction() {
    if (
      homeData?.attendance?.check_in_time != "Yet to sign in" &&
      homeData?.attendance?.check_out_time != "Yet to sign out"
    ) {
      return toast.warning(
        "Your attendacce has been already capture for today."
      );
    }
    navigate("/punch");
  }

  return (
    <div>
      <div style={{ backgroundColor: "#F6F8FA" }}>
        {selectedTab === 1 && (
          <div>
            {" "}
            <div className="mob-signin-div">
              <div className="MobSignInContainerOne">
                <div>
                  <span className="MobHeadingText">
                    {name}, {homeData?.user?.name?.split(" ")[0]}
                  </span>
                  <div className="MobHeadingTextTwo">
                    Let's be productive today!
                  </div>
                </div>
                <Avatar
                  className="AvtarImg"
                  type="button"
                  onClick={() => setSelectedTab(2)}
                >
                  {homeData?.user?.name?.charAt(0)}
                </Avatar>
              </div>
              <div className="wave">
                <button
                  className={
                    homeData?.attendance?.check_in_time === "Yet to sign in"
                      ? "sign-in-btn"
                      : homeData?.attendance?.check_out_time ===
                        "Yet to sign out"
                      ? "sign-out-btn"
                      : "sign-in-btn"
                  }
                  onClick={() => doPunchAction()}
                ></button>
              </div>
            </div>
            <div>
              <div className="TodaysOverViewText">Today's Overview</div>
              <div className="oc_container">
                <div className="overview_card_container_one">
                  <OverViewCard
                    icon={LoginIcon}
                    statusText="Sign In"
                    textPart={homeData?.attendance?.check_in_time}
                    status="On Time"
                  />
                  <OverViewCard
                    icon={LogoutIcon}
                    statusText="Sign Out"
                    textPart={homeData?.attendance?.check_out_time}
                    status="On Time"
                  />
                </div>
                <div className="overview_card_container_two">
                  <OverViewCard
                    icon={Averagecon}
                    statusText="Total Hrs"
                    textPart={homeData?.allAttendance?.total_time}
                    status="This Month"
                  />
                  <OverViewCard
                    icon={CalendarIcon}
                    statusText="Total Days"
                    textPart={homeData?.allAttendance?.total_days}
                    status="Working Days"
                  />
                </div>
              </div>
              <div className="MarginDivMob"></div>
              <div className="att_cont_div">
                <ActionComp
                  navigateTo="attendance-log"
                  attText=" Attendance Logs"
                  atttextSub="All time Attendence log with predictive analytics"
                  icon={ArrowRight}
                  iconPerson={AttPerson}
                />
                <ActionComp
                  navigateTo="leave"
                  attText=" Leaves"
                  atttextSub="Seamlessly integrate the leave & update attendance"
                  icon={ArrowRight}
                  iconPerson={LeavePerson}
                />
              </div>
              <HolidayCard homeData={homeData} formatDate={formatDate} />
            </div>
            <div className="HeightDiv"></div>
          </div>
        )}
        <HomeFooter
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          HomeBlack={HomeBlack}
          HomeWhite={HomeWhite}
          ProfileBlack={ProfileBlack}
          ProfileWhite={ProfileWhite}
          homeData={homeData}
          logoutHandler={logoutHandler}
        />
      </div>
    </div>
  );
}

export default HomeMobile;
