import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { leaveFunction } from "../../../Services/CommonServices"
import { leaveBalanceFunction } from "../../../Services/CommonServices"
import { Container } from "reactstrap";

function LeaveBalance() {
  const navigate = useNavigate();
  const user = JSON.parse((localStorage.getItem("user_data")|| "{}") == 'undefined' ? '{}' : (localStorage.getItem("user_data")|| "{}"));
  const [leaveType, setLeaveType] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  
  useEffect(() => {
    leaveFunction()?.then(reply => {
      setLeaveType(reply.data.data);
      getUserLeaveBalance();
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const getUserLeaveBalance = () => {
    leaveBalanceFunction(`?userId=${user?._id}`).then(reply => {
      const result = reply.data.data;
      const data = result.map((item)=> ({
        leave_type: item.leave_type, 
        user_id: item.user,
        leave_Count: item.leave_count
      }))
      setLeaveData(data)
    }).catch(error => {
      
    })
  }
 
  const applyHandeler = () => navigate("/leave");

  return (
    <Container fluid>
      <div className="bal-nav">
        <Button variant="primary" onClick={applyHandeler}>Apply</Button>{' '}
        <Form.Select className="form-select">
          <option>Select Year</option>
          <option value="2025">2025</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
        </Form.Select>
      </div>
      <div className="card-container">
      {leaveType.map((item, index) => {
      const matchingLeaveData = leaveData.find((d) => d.leave_type === item._id);
      return (
        <Card className="card-full" style={{ width: "15rem", height: "15rem" }} key={index}>
          <Card.Body>
            <div className="card-head">
              <h4>{item.name}</h4>
            </div>
            <div className="card-main">
              <h6>Granted: 0</h6>
              <h6>Balance: {matchingLeaveData ? matchingLeaveData.leave_Count : 0}</h6>
            </div>
          </Card.Body>
        </Card>
      );
    })}
      </div>
    </Container>
  );
}

export default LeaveBalance;
