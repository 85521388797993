import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from "react-router-dom";
import { Form,} from "react-bootstrap";
import {Row,Card,Col, Container} from"reactstrap"
import {Button} from "reactstrap"
import { leaveFunction } from "../../../Services/CommonServices"
import { applyFunction } from "../../../Services/CommonServices"

function LeaveApply() {

  const [leaveType, setLeaveType] = useState([]);
  const minDate = new Date().toLocaleDateString().split("/").reverse().join("-")

  const navigate = useNavigate();

  useEffect(() => {
    leaveFunction().then(res => {
      setLeaveType(res.data.data);
    }).catch(err => {
      console.log(err)
    })

  }, []);

  const handleKeyPress = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
    }
  };

  const [formData, setFormData] = useState({
    leave_type: "",
    start_date: "",
    end_date: "",
    reason: "",
    applying_to: "",
    contact_details: ""
  });

  const [validationErrors, setValidationErrors] = useState({
    leave_type: "",
    start_date: "",
    end_date: "",
    reason: "",
    applying_to: "",
    contact_details: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};
    if (!formData.leave_type) {
      errors.leaveType = "Leave Type is required.";
    }
    if (!formData.start_date) {
      errors.start_date = "From Date is required.";
    }
    if (!formData.end_date) {
      errors.end_date = "To Date is required.";
    }
    if (!formData.reason) {
      errors.reason = "Reason is required.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }


    applyFunction(formData).then(reply => {
      alert("Successfully Applied!")
      setFormData({leave_type: "",start_date: "",end_date: "",reason: "",applying_to: "",contact_details: ""})
    }).catch(err => {
    });
    
  };
  return (
    <Container fluid>
    <Row>
      <Card className='col-9 card ' >
    <div className="main">
      <div className="form-leave">
        <h3>Apply for Leave</h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="leave_type">
            <Form.Label>Leave Type</Form.Label>
            <Form.Control
              as="select"
              defaultValue=""
              name="leave_type"
              value={formData.leave_type}
              onChange={handleInputChange}
              className={validationErrors.leave_type ? "is-invalid" : ""}
            >
              {leaveType.map((data, index) => (
                <option key={data._id} value={data._id}>
                  {data.name}
                </option>
              ))}
            </Form.Control>

            <div className="invalid-feedback">
              {validationErrors.leave_type}
            </div>
          </Form.Group>



          <Row>
            <Col>
              <Form.Group controlId="start_date">
                <Form.Label>From Date</Form.Label>
                <Form.Control
                  type="date"
                  name="start_date"
                  min= {minDate}
                  value={formData.start_date}
                  onChange={handleInputChange}
                  className={
                    validationErrors.start_date ? "is-invalid" : ""
                  }
                />
                <div className="invalid-feedback">
                  {validationErrors.start_date}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="end_date">
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={formData.end_date}
                  min= {minDate}
                  onChange={handleInputChange}
                  className={
                    validationErrors.end_date ? "is-invalid" : ""
                  }
                />
                <div className="invalid-feedback">
                  {validationErrors.end_date}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="applying_to">
            <Form.Label>Applying To</Form.Label>
            <Form.Control
              type="text"
              name="applying_to"
              value={formData.applying_to}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback">
              {validationErrors.applying_to}
            </div>
          </Form.Group>

          <Form.Group controlId="contact_details">
            <Form.Label>Contact Details</Form.Label>
            <Form.Control
              type="text"
              name="contact_details"
              value={formData.contact_details}
              maxLength = {10}
              onKeyPress={handleKeyPress}
              onPaste={(e) => e.preventDefault()}
              onChange={handleInputChange}
            />
            <div className="invalid-feedback">
              {validationErrors.contact_details}
            </div>
          </Form.Group>

          <Form.Group controlId="reason">
            <Form.Label>Reason</Form.Label>
            <Form.Control
              type="text"
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
              className={validationErrors.reason ? "is-invalid" : ""}
            />
            <div className="invalid-feedback">
              {validationErrors.reason}
            </div>
          </Form.Group>

          <div className="btn-leave">
            <Button
              variant="secondary"
              className="mr-2"
              onClick={() => alert("Canceled")}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
    </Card>
    </Row>
    </Container>
  )
}

export default LeaveApply
